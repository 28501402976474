import React, { useMemo } from "react";
import { Box, Grid, Heading, Main, Menu, Select } from "grommet";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectInstance, selectUser, setInstance } from "../../../app/store/application";
import { useWindowDimensions } from "../hooks";
import { Menu as MenuIcon } from "grommet-icons";
import { push } from "redux-first-history";
import { UserRole } from "../../../__generated__/graphql";

export const RegoMain: React.FC<{ height: number; children: React.ReactNode; }> = (props) => {
	const { size } = useWindowDimensions();
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const instance = useAppSelector(selectInstance);
	const isAdmin = useMemo(() => user?.role === UserRole.Admin, [user]);

	return (
		<Main background="default" flex style={{ minHeight: `${props.height}px` }}>
			{(isAdmin && size !== "small") && (
				<Box background="dark-1" direction="row" pad="medium">
					<Grid columns={{ count: 3, size: "auto" }} fill="horizontal">
						<Box width="medium" align="start" justify="center">
							<Select
								color="white"
								value={instance}
								dropProps={{ background: "white" }}
								options={["ADMIN", "DONATION_CENTER", "HAULER", "RESIDENT", "RESIDENT_MANAGER"]}
								onChange={({ option }) => {
									dispatch(setInstance(option));
								}}
							/>
						</Box>
						<Box align="center" justify="center">
							<Heading level="3" margin="none">Admin Controls</Heading>
						</Box>
						<Box align="end" justify="center">
							<Menu
								a11yTitle="Navigation Menu"
								dropProps={{ align: { top: 'bottom', right: 'right' }, stretch: true }}
								icon={<MenuIcon color="brand" />}
								items={[
									{ label: "Home", onClick: () => dispatch(push("/")) },
								]}
							/>
						</Box>
					</Grid>
				</Box>
			)}
			{props.children}
		</Main>
	);
};