import { Box, Button, Layer } from "grommet";
import { FormNext, FormPrevious, FormClose } from "grommet-icons";
import React, { useState, useMemo, useEffect } from "react";
import { useWindowDimensions } from "../hooks";

interface RightExpandableSidebarModalProps {
	onClose(): void;
	children: React.ReactNode;
	blockExpand?: boolean;
}

export const RightSidebarModalContext = React.createContext(false);

export const RightExpandableSidebarModal: React.FC<RightExpandableSidebarModalProps> = (props) => {
	const { size } = useWindowDimensions();
	const [isExpanded, setIsExpanded] = useState(false);
	const isSmallScreen = useMemo(() => size === "small", [size]);

	useEffect(() => {
		if(size === "small") {
			setIsExpanded(true);
		}
	}, [size]);

	const toggleExpanded = () => setIsExpanded(!isExpanded);

	return (
		<Layer
			animate
			animation="slide"
			responsive={false}
			full={isExpanded ? true : "vertical"}
			position="right"
			onEsc={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box gap="small" width={isExpanded ? undefined : "medium"}>
				<Box background="light-1" direction="row" justify={isSmallScreen ? "end" : "between"} pad="small" align="center">
					{(size !== "small" && !props.blockExpand) && (<Button
						hoverIndicator
						onClick={toggleExpanded}
						icon={isExpanded ? <FormNext /> : <FormPrevious />}
					/>)}
					<Button
						hoverIndicator
						icon={<FormClose />}
						onClick={props.onClose}
					/>
				</Box>
				<Box margin="small">
					<RightSidebarModalContext.Provider value={isExpanded}>
						{props.children}
					</RightSidebarModalContext.Provider>
				</Box>
			</Box>
		</Layer>
	);
};