import { Anchor, Box, DropButton, Footer, Image } from "grommet";
import { CircleQuestion, Facebook, Instagram, Phone, StatusUnknown } from "grommet-icons";
import { push } from "redux-first-history";
import config from "../../../app/config";
import { useAppDispatch } from "../../../app/store";
import whiteLogo from "../../../img/rego_white_logo_transparent.png";

interface HelpDropContentItemProps {
	icon?: JSX.Element;
	title: string;
	onClick: () => void;
	href?: string;
}

const HelpDropContentItem: React.FC<HelpDropContentItemProps> = (props) => {
	return (
		<Box
			hoverIndicator
			onClick={props.onClick}
		>
			<Box direction="row" gap="medium" margin="small">
				<Box align="center" justify="center">
					{props.icon}
				</Box>
				<Box align="center" justify="center">
					<Anchor label={props.title} onClick={props.onClick} href={props.href} />
				</Box>
			</Box>
		</Box>
	);
};

const HelpDropContent: React.FC = (props) => {
	const dispatch = useAppDispatch();
	return (
		<Box
			background="white"
			width="small"
		>
			<Box margin="small" gap="small">
				<HelpDropContentItem
					title="Call Us"
					icon={<Phone />}
					href={`tel:${config.contact.phone}`}
					onClick={() => {
						window.open(`tel:${config.contact.phone}`, "_self");
					}}
				/>
				{/* <HelpDropContentItem
					title="Terms"
					icon={<Phone />}
					onClick={() => {

					}}
				/>
				<HelpDropContentItem
					title="Privacy"
					icon={<Phone />}
					onClick={() => {

					}}
				/> */}
				<HelpDropContentItem
					title="FAQs"
					icon={<StatusUnknown />}
					onClick={() => {
						dispatch(push("/help/faq"));
					}}
				/>
				<HelpDropContentItem
					title="Instagram"
					icon={<Instagram />}
					onClick={() => {
						window.open("https://www.instagram.com/regoapp.io", "_blank");
					}}
				/>
				<HelpDropContentItem
					title="Facebook"
					icon={<Facebook />}
					onClick={() => {
						window.open("https://www.facebook.com/regoapp.io", "_blank");
					}}
				/>
			</Box>
		</Box>
	);
};

export const RegoFooter: React.FC = (props) => {
	return (
		<Box
			background="black"
			id="footer"
		>
			<Box direction="row">
				<Box margin="medium" width="small">
					<Image
						src={whiteLogo}
					/>
				</Box>
				<Box flex align="end" justify="center" margin="small">
					<DropButton
						size="large"
						hoverIndicator
						icon={<CircleQuestion size="large" />}
						dropContent={<HelpDropContent />}
						dropAlign={{
							bottom: "top",
							right: "right"
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};