import { useLazyQuery, useMutation } from "@apollo/client";
import { Anchor, Box, Form, FormField, Grid, MaskedInput, Page, PageContent, Spinner, TextInput } from "grommet";
import { Phone } from "grommet-icons";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useLoginNavigation } from "../../../app/navigation";
import { AuthService } from "../../../app/services/auth";
import { RegisterUser } from "../../../app/services/request";
import { GetBuildingByCode } from "../../../app/services/request/gql";
import { UtilService } from "../../../app/services/util";
import { getStandardFormValidations } from "../../../helpers";
import { LoadingButton } from "../../common";
import { useWindowDimensions } from "../../common/hooks";
import { useQueryParams } from "../../common/hooks/useQuery";

interface RegistrationFormState {
	code: string;
	buildingName: string;
	firstName: string;
	lastName: string;
	phone: string;
	unit: string;
}

export const RegistrationForm: React.FC = (props) => {
	const snack = useSnackbar();
	const query = useQueryParams();
	const dimensions = useWindowDimensions();
	const loginNavigation = useLoginNavigation();

	const [registerUserMutation, { loading: registerLoading }] = useMutation(RegisterUser);
	const [fetchBuildingQuery, { data: buildingData, loading: buildingLoading, error: buildingError }] = useLazyQuery(GetBuildingByCode);

	const [state, setState] = useState<RegistrationFormState>({
		code: "",
		buildingName: "",
		firstName: "",
		lastName: "",
		phone: "",
		unit: ""
	});

	useEffect(() => {
		if(buildingData?.GetBuildingByCode) {
			setState({
				...state,
				buildingName: buildingData.GetBuildingByCode.name
			});
		}
	}, [buildingData]);

	useEffect(() => {
		if(buildingError) {
			snack.enqueueSnackbar("We couldn't find a building with that code", { variant: "error" });
		}
	}, [snack, buildingError]);

	const columns = useMemo(() => {
		return ["small"].includes(dimensions.size)
			? 1
			: 2;
	}, [dimensions.size]);

	useEffect(() => {
		if(query.get("phone")) {
			setState((old) => {
				return { ...old, phone: query.get("phone") ?? "" };
			});
		}
	}, [query]);

	useEffect(() => {
		if(query.get("code")) {
			setState((old) => {
				return { ...old, code: query.get("code") ?? "" };
			});
		}
	}, [query]);

	const codeFromQuery = useMemo(() => query.get("code"), [query]);
	const phoneFromQuery = useMemo(() => query.get("phone"), [query]);

	function handleFetchBuilding(): void {
		fetchBuildingQuery({
			variables: { code: state.code }
		});
	}

	function handleRegistration(): void {
		const { firstName, lastName, phone, code, unit } = state;
		registerUserMutation({
			variables: {
				firstName,
				lastName,
				unit,
				buildingCode: code,
				phone: UtilService.formatPhoneToNumber(phone)
			}
		}).then((res) => {
			AuthService.setAccessToken(res.data?.RegisterUser.access_token ?? "");
			AuthService.setRefreshToken(res.data?.RegisterUser.refresh_token ?? "");
			window.location.assign(query.get("returnUrl") ?? "/dashboard");
		}).catch(err => {
			console.error("Registration error", err);
			snack.enqueueSnackbar("We ran into an issue confirming your account", { variant: "error" });
		});
	}

	function handleFormChanges(updates: Partial<RegistrationFormState>) {
		setState((old) => {
			return {
				...old,
				...updates
			};
		});
	}

	return (
		<Form
			value={state}
			onChange={handleFormChanges}
			validate="submit"
			onSubmit={() => {
				handleRegistration();
			}}
		>
			<Box gap="medium">
				<Box gap="small">
					<Grid columns={{ count: columns, size: "auto" }} gap="small">
						<FormField
							name="code"
							label="Building Code"
							validate={[
								...getStandardFormValidations()
							]}
							onBlur={handleFetchBuilding}
						>
							<TextInput
								name="code"
								disabled={!!codeFromQuery}
								readOnly={!!codeFromQuery}
								value={codeFromQuery ? codeFromQuery : undefined}
							/>
						</FormField>
						<FormField
							name="buildingName"
							label="Building Name"
							validate={[
								(value: any) => {
									if(!value) {
										return {
											status: "error",
											message: "Please enter a valid building code"
										};
									}
								}
							]}
						>
							<TextInput
								name="buildingName"
								disabled={true}
								readOnly={true}
								value={state.buildingName}
								icon={buildingLoading ? <Spinner /> : undefined}
							/>
						</FormField>
					</Grid>
					<Grid columns={{ count: columns, size: "auto" }} gap="small">
						<FormField
							name="firstName"
							label="First Name"
							validate={[
								...getStandardFormValidations()
							]}
						>
							<TextInput
								name="firstName"
							/>
						</FormField>
						<FormField
							name="lastName"
							label="Last Name"
							validate={[
								...getStandardFormValidations()
							]}
						>
							<TextInput
								name="lastName"
							/>
						</FormField>
					</Grid>
					<Grid columns={{ count: columns, size: "auto" }} gap="small">
						<FormField
							name="phone"
							label="Phone Number"
							help="Enter the phone number on file with your building"
							validate={[
								...getStandardFormValidations(),
								(value: unknown) => {
									if(!value) {
										return {
											status: "error",
											message: "This field is required"
										};
									}

									try {
										UtilService.formatPhoneToNumber(state.phone);
									}
									catch(e) {
										return {
											status: "error",
											message: "Please enter a valid phone number"
										};
									}
								}
							]}
						>
							<MaskedInput
								name="phone"
								disabled={!!phoneFromQuery}
								readOnly={!!phoneFromQuery}
								value={phoneFromQuery ? phoneFromQuery : undefined}
								inputMode={"tel"}
								mask={[
									{ fixed: '1 (' },
									{
										length: 3,
										regexp: /^[0-9]{1,3}$/,
										placeholder: 'xxx',
									},
									{ fixed: ')' },
									{ fixed: ' ' },
									{
										length: 3,
										regexp: /^[0-9]{1,3}$/,
										placeholder: 'xxx',
									},
									{ fixed: '-' },
									{
										length: 4,
										regexp: /^[0-9]{1,4}$/,
										placeholder: 'xxxx',
									},
								]}
								icon={<Phone />}
							/>
						</FormField>
						<FormField
							name="unit"
							label="Unit #"
							help="Enter the number / letter only (ex. 5A for Apt 5A)"
							validate={[
								...getStandardFormValidations()
							]}
						>
							<TextInput
								name="unit"
							/>
						</FormField>
					</Grid>
				</Box>
				<Box direction="row" justify="between" align="center">
					<Anchor
						color="accent-1"
						alignSelf="center"
						label="Login?"
						onClick={loginNavigation.loginNavigate}
					/>
					<LoadingButton
						primary
						type="submit"
						isLoading={registerLoading}
						color="accent-1"
						label="Register"
						alignSelf="end"
					/>
				</Box>
			</Box>
		</Form>
	);
};

export const RegisterPage: React.FC = () => {
	return (
		<Page kind="narrow">
			<PageContent margin="medium">
				<RegistrationForm />
			</PageContent>
		</Page>
	);
};