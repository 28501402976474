import { Box, Text } from "grommet";
import { formatCurrency } from "../../../../helpers";

interface PickupCardProps {
	isLoading: boolean;
	itemCount: number;
	totalFee: number | null;
	onSelected(): void;
	scheduledDateFormatted: string;
}

export const PickupCard: React.FC<PickupCardProps> = (props) => {
	return (
		<Box
			round
			pad="small"
			fill="horizontal"
			background="light-1"
			hoverIndicator
			onClick={props.onSelected}
		>
			<Box direction="row" justify="between" margin="small">
				<Box>
					<Text weight="bold">{props.itemCount} item(s)</Text>
					<Text weight="bold">{props.scheduledDateFormatted ?? "Not Scheduled"}</Text>
				</Box>
				<Box>
					<Text>Total Fee:</Text>
					<Text textAlign="center" weight="bold">{props.totalFee ? formatCurrency(props.totalFee) : "-"}</Text>
				</Box>
			</Box>
		</Box>
	);
};