import { gql } from "../../../../__generated__/gql";

export const ListItemClassifications = gql(/* GraphQL */ `
	query ListItemClassifications {
    	ListItemClassifications {
			id
			name
			examples
			description
			pricing {
				disassemblyFee
				pickupFee
			}
			pricingComparison {
				disassemblyFee
				pickupFee
			}
			code
		}		
    }
`);