import { Box, Heading, Image, Text } from "grommet";
import { useWindowDimensions, DashboardCard, WrapSkeleton } from "../../../common";
import regoBlackLogo from "../../../../img/rego_black_logo.png";

interface ResidenceDetailsCardProps {
	isLoading: boolean;
	logoUrl: string;
	residenceName: string;
	unitNumber?: string;
	hideLogo?: boolean;
}

export const ResidenceDetailsCard: React.FC<ResidenceDetailsCardProps> = (props) => {
	const { size } = useWindowDimensions();

	return (
		<DashboardCard>
			<Box gap="medium" flex>
				<Box width={size === "small" ? "xsmall" : "small"}>
					<WrapSkeleton isLoading={props.isLoading} variant="rounded">
						{!!props.hideLogo
							? (
								<Image
									src={regoBlackLogo}
								/>
							)
							: (
								<Image
									src={props.logoUrl}
								/>
							)}
					</WrapSkeleton>
				</Box>
				<Box gap="xsmall" flex>
					<WrapSkeleton isLoading={props.isLoading}>
						<Heading level="3" margin="none">
							{props.hideLogo
								? "Your Building"
								: props.residenceName}
						</Heading>
					</WrapSkeleton>
					<WrapSkeleton isLoading={props.isLoading}>
						{props.unitNumber && (
							<Text>Your Unit: <Text weight="bold">{props.unitNumber}</Text></Text>
						)}
					</WrapSkeleton>
				</Box>
			</Box>
		</DashboardCard>
	);
};