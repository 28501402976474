import { Box, Heading, Image } from "grommet";
import { WrapSkeletonFormField, DashboardCard } from "../../../common";
import regoLogo from "../../../../img/rego_black_logo.png";

interface HaulerDetailsCardProps {
	id: string;
	name: string;
	isLoading: boolean;
}

export const HaulerDetailsCard: React.FC<HaulerDetailsCardProps> = (props) => {
	return (
		<DashboardCard>
			<Box gap="small">
				<Heading margin="none" level="2">Hauler Details</Heading>
				<Box width="small">
					<Image src={regoLogo} />
				</Box>
				<WrapSkeletonFormField
					isLoading={props.isLoading}
					label="Hauler Id"
					name="haulerId"
					value={props.id}
					inputProps={{
						disabled: true,
						readOnly: true
					}}
				/>
				<WrapSkeletonFormField
					isLoading={props.isLoading}
					label="Hauler Name"
					name="haulerName"
					value={props.name}
					inputProps={{
						disabled: true,
						readOnly: true
					}}
				/>
			</Box>
		</DashboardCard>
	);
};