import { useQuery } from "@apollo/client";
import { ListItemClassifications } from "../../../app/services/request";

export function useClassifications() {
	const { data, loading } = useQuery(ListItemClassifications);

	return {
		loading,
		classifications: data?.ListItemClassifications ?? [],
	};
}