import { useQuery } from "@apollo/client";
import { AdminGetService } from "../../../app/services/request/gql";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../app/store";
import { push } from "redux-first-history";
import { Box, Grid } from "grommet";
import { useWindowDimensions, DashboardCard } from "../../common";
import { ScheduledPickupSidebar, ServiceBaseProps, ServiceCustomerDetails, ServiceDetailsCard, ServiceProductsContainer } from "../components";
import { useSnackbar } from "notistack";

export const AdminServiceDetailsPage: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const [serviceId, setServiceId] = useState("");

	useEffect(() => {
		if(!params.serviceId) {
			dispatch(push("/pickups"));
			return;
		}

		setServiceId(params.serviceId);
	}, [params]);

	const { data, loading, error } = useQuery(AdminGetService, {
		variables: { serviceId }
	});

	useEffect(() => {
		if(error) {
			console.error(`Failed to load service ${serviceId}`, error);
			snack.enqueueSnackbar(`Failed to load service ${serviceId}`, { variant: "error" });
		}
	}, [error, serviceId]);

	const service = useMemo(() => {
		if(data) return data.GetService;
		return null;
	}, [data]);

	const isSmall = useMemo(() => {
		return ["small", "medium"].includes(size);
	}, [size]);

	const [isSchedulingPickup, setIsSchedulingPickup] = useState(false);

	const baseServiceProps: ServiceBaseProps = useMemo(() => {
		return {
			isLoading: loading,
			isSmall,
			isSchedulingPickup,
			onSchedulePickup: () => setIsSchedulingPickup(true),
			serviceId: service?.id ?? "",
			scheduledWindowDate: service?.pickup?.scheduledDate ?? "",
			scheduledWindowFrom: service?.pickup?.scheduledWindowFrom ?? 0,
			scheduledWindowTo: service?.pickup?.scheduledWindowTo ?? 0,
			scheduledWindowTimezone: service?.pickup?.scheduledWindowTimezone ?? "",
			pickupId: service?.pickup?.id ?? "",
			pickupStarted: service?.pickup?.started ?? false,
			pickupStartedAt: service?.pickup?.startedAt ?? "",
			pickupCompleted: service?.pickup?.completed ?? false,
			pickupCompletedAt: service?.pickup?.completedAt ?? ""
		};
	}, [service, isSchedulingPickup, isSmall, loading]);

	return (
		<Box margin="small">
			{isSchedulingPickup && (
				<ScheduledPickupSidebar
					pickupId={service?.pickup?.id ?? ""}
					onClose={() => setIsSchedulingPickup(false)}
					isScheduling={false}
					onScheduled={() => { }}
					scheduledDate={service?.pickup?.scheduledDate ?? ""}
					scheduledWindowFrom={service?.pickup?.scheduledWindowFrom ?? 0}
					scheduledWindowTo={service?.pickup?.scheduledWindowTo ?? 0}
				/>
			)}
			<Grid columns={{ count: isSmall ? 1 : 2, size: "auto" }} gap="small">
				<ServiceDetailsCard
					{...baseServiceProps}
				/>
				<ServiceCustomerDetails
					{...baseServiceProps}
					userId={service?.user?.id ?? ""}
					userFirstName={service?.user?.firstName ?? ""}
					userLastName={service?.user?.lastName ?? ""}
					userEmailAddress={service?.user?.emailAddress ?? ""}
					userPhoneNumber={service?.user?.phoneNumber ?? ""}
					userResidenceId={service?.user?.currentResident?.residence?.id ?? ""}
					userResidenceName={service?.user?.currentResident?.residence?.name ?? ""}
					userResidenceUnit={service?.user?.currentResident?.verification?.unit ?? ""}
				/>
				<ServiceProductsContainer
					{...baseServiceProps}
					products={(service?.products ?? []).map(product => {
						return {
							id: product.id,
							disposition: product.disposition || null,
							itemId: product.item?.id ?? "",
							itemName: product.item?.name ?? "",
							classificationId: product.itemClassification?.id ?? "",
							classificationCode: product.itemClassification?.code ?? "",
							classificationName: product.itemClassification?.name ?? "",
							media: (product.media ?? []).map(media => {
								return {
									fileName: media.fileName,
									contentUrl: media.contentUrl ?? ""
								};
							})
						};
					})}
				/>
				<DashboardCard>
					Donation Media Stuff
				</DashboardCard>
			</Grid>
		</Box>
	);
};