import { Box, CheckBox, FormField, Grid, Heading, Spinner, Text, TextInput } from "grommet";
import { ProductBase, ProductSummaryCard, ProductSummarySidebar } from "./products";
import { Calendar, FormNext } from "grommet-icons";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../app/store";
import { DashboardCard, WrapSkeletonFormField } from "../../common";
import { Skeleton } from "@mui/material";
import { formatDateFromWindow, formatTimeFromWindow, parseTimestampFromUTC } from "../../../helpers";
import { useTimezone } from "../../common/hooks";
import { useState } from "react";

export interface ServiceBaseProps {
	isSmall: boolean;
	isLoading: boolean;
	isSchedulingPickup: boolean;
	onSchedulePickup: () => void;
	serviceId: string;
	pickupId: string;
	pickupStarted: boolean;
	pickupStartedAt: string | null;
	pickupCompleted: boolean;
	pickupCompletedAt: string | null;
	scheduledWindowDate: string;
	scheduledWindowFrom: number;
	scheduledWindowTo: number;
	scheduledWindowTimezone: string;
}

interface ServiceCustomerDetailsProps extends ServiceBaseProps {
	userId: string;
	userFirstName: string;
	userLastName: string;
	userEmailAddress: string;
	userPhoneNumber: string;
	userResidenceId: string;
	userResidenceName: string;
	userResidenceUnit: string;
}

export const ServiceCustomerDetails: React.FC<ServiceCustomerDetailsProps> = (props) => {
	const dispatch = useAppDispatch();
	return (
		<DashboardCard>
			<Box gap="small">
				<Heading level="2" margin="none">Customer Details</Heading>
				<Box pad="small">
					<WrapSkeletonFormField
						label="User Id"
						value={props.userId}
						isLoading={props.isLoading}
						inputProps={{
							readOnly: true,
							disabled: true
						}}
						specialFieldType="ACTION"
						icon={<FormNext />}
						onAction={() => {
							dispatch(push(`/users/${props.userId}`));
						}}
					/>
					<Grid columns={{ count: props.isSmall ? 1 : 2, size: "auto" }} gap="small">
						<WrapSkeletonFormField
							label="First Name"
							value={props.userFirstName}
							isLoading={props.isLoading}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
						/>
						<WrapSkeletonFormField
							label="Last Name"
							value={props.userLastName}
							isLoading={props.isLoading}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
						/>
					</Grid>
					<Grid columns={{ count: props.isSmall ? 1 : 2, size: "auto" }} gap="small">
						<WrapSkeletonFormField
							label="Phone Number"
							value={props.userPhoneNumber.length === 11
								? props.userPhoneNumber.slice(1)
								: props.userPhoneNumber ?? ""}
							isLoading={props.isLoading}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
							specialFieldType="COPY"
						/>
						<WrapSkeletonFormField
							label="Email Address"
							value={props.userEmailAddress}
							isLoading={props.isLoading}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
							specialFieldType="COPY"
						/>
					</Grid>
					<Grid columns={{ count: props.isSmall ? 1 : 2, size: "auto" }} gap="small">
						<WrapSkeletonFormField
							label="Current Residence"
							value={props.userResidenceName}
							isLoading={props.isLoading}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
							specialFieldType="ACTION"
							icon={<FormNext />}
							onAction={() => {
								dispatch(push(`/residences/${props.userResidenceId}`));
							}}
						/>
						<WrapSkeletonFormField
							label="Current Unit"
							value={props.userResidenceUnit}
							isLoading={props.isLoading}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
							specialFieldType="COPY"
						/>
					</Grid>
				</Box>
			</Box>
		</DashboardCard>
	);
};


interface ServiceProductsContainerProps extends ServiceBaseProps {
	products: ProductBase[];
}

export const ServiceProductsContainer: React.FC<ServiceProductsContainerProps> = (props) => {
	const [selectedProductId, setSelectedProductId] = useState("");

	return (
		<DashboardCard>
			{selectedProductId && (
				<ProductSummarySidebar
					onClose={() => setSelectedProductId("")}
					productId={selectedProductId}
				/>
			)}
			<Box gap="small">
				<Heading level="2" margin="none">Products</Heading>
				<Box pad="small" gap="small">
					{props.isLoading && (
						<Box align="center">
							<Spinner size="large" />
						</Box>
					)}
					{(props.products.length === 0 && !props.isLoading) && (
						<Box align="center" justify="center">
							<Text weight="bold">No products found</Text>
						</Box>
					)}
					{props.products.map(product => (
						<ProductSummaryCard
							key={product.id}
							isLoading={props.isLoading}
							isSmall={props.isSmall}
							serviceId={props.serviceId}
							pickupId={props.pickupId}
							onSelected={() => setSelectedProductId(product.id)}
							{...product}
						/>
					))}
				</Box>
			</Box>
		</DashboardCard>
	);
};

interface ServiceDetailsCardProps extends ServiceBaseProps {

}

export const ServiceDetailsCard: React.FC<ServiceDetailsCardProps> = (props) => {
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	return (
		<DashboardCard>
			<Box gap="small">
				<Heading level="2" margin="none">Service Details</Heading>
				<Box pad="small">
					<WrapSkeletonFormField
						label="Service Id"
						value={props.serviceId}
						isLoading={props.isLoading}
						specialFieldType="COPY"
					/>
					<WrapSkeletonFormField
						label="Pickup Id"
						value={props.pickupId}
						isLoading={props.isLoading}
						specialFieldType="ACTION"
						icon={<FormNext />}
						onAction={() => {
							dispatch(push(`/pickups/${props.pickupId}`));
						}}
					/>
					<Grid columns={{ count: props.isSmall ? 1 : 2, size: "auto" }} gap="small">
						<WrapSkeletonFormField
							label="Scheduled Date"
							value={formatDateFromWindow(props.scheduledWindowDate, props.scheduledWindowTimezone)}
							isLoading={props.isLoading}
							icon={<Calendar style={{ padding: undefined }} />}
							specialFieldType="ACTION"
							onAction={props.onSchedulePickup}
						/>
						<WrapSkeletonFormField
							label="Scheduled Window"
							value={formatTimeFromWindow(props.scheduledWindowFrom, props.scheduledWindowTo)}
							isLoading={props.isLoading}
							icon={<Calendar />}
							specialFieldType="ACTION"
							onAction={props.onSchedulePickup}
						/>
					</Grid>
					<Grid columns={{ count: props.isSmall ? 1 : 2, size: "auto" }} gap="small">
						<FormField
							label="Started"
						>
							{props.isLoading && (
								<Box pad="xsmall">
									<Skeleton style={{ minHeight: "30px" }} />
								</Box>
							)}
							{!props.isLoading && (
								<Box direction="row">
									<TextInput
										plain
										readOnly
										disabled
										value={parseTimestampFromUTC(props.pickupStartedAt, timezone)}
									/>
									<CheckBox
										readOnly
										disabled
										checked={props.pickupStarted}
									/>
								</Box>
							)}
						</FormField>
						<FormField
							label="Completed"
						>
							{props.isLoading && (
								<Box pad="xsmall">
									<Skeleton style={{ minHeight: "30px" }} />
								</Box>
							)}
							{!props.isLoading && (
								<Box direction="row">
									<TextInput
										plain
										readOnly
										disabled
										value={parseTimestampFromUTC(props.pickupCompletedAt, timezone)}
									/>
									<CheckBox
										readOnly
										disabled
										checked={props.pickupCompleted}
									/>
								</Box>
							)}
						</FormField>
					</Grid>
				</Box>
			</Box>
		</DashboardCard>
	);
};