import React, { useEffect } from 'react';
import ReactDOM from "react-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Grommet } from "grommet";
import theme from "./theme";
import { ApplicationProvider, LayoutProvider, Loader } from "./features/common";
import Bugsnag from '@bugsnag/js';
import "./app/reporting";
import "./app/services/request";
import { useAppDispatch, useAppSelector } from "./app/store";
import { selectInstance, setInstance } from "./app/store/application";
import { AdminEntryPoint, HaulerEntryPoint, ResidentEntryPoint, ResidentManagerEntryPoint } from "./entry";

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

export const InstanceRouter: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const instance = useAppSelector(selectInstance);

	useEffect(() => {
		dispatch(setInstance("RESIDENT"));
		if(window.location.host.includes("admin.liverego")) {
			dispatch(setInstance("ADMIN"));
		}

		if(window.location.host.includes("resident.liverego")) {
			dispatch(setInstance("RESIDENT"));
		}

		if(window.location.host.includes("manager.liverego")) {
			dispatch(setInstance("RESIDENT_MANAGER"));
		}
	}, [window.location.host]);

	switch(instance) {
		case "ADMIN": {
			return (<AdminEntryPoint />);
		}
		case "DONATION_CENTER": {
			return (<HaulerEntryPoint />);
		}
		case "HAULER": {
			return (<HaulerEntryPoint />);
		}
		case "RESIDENT": {
			return (<ResidentEntryPoint />);
		}
		case "RESIDENT_MANAGER": {
			return (<ResidentManagerEntryPoint />);
		}
		case "PENDING": {
			return (
				<LayoutProvider>
					<Loader visible={true}>

					</Loader>
				</LayoutProvider>
			);
		}
	}
};

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Grommet theme={theme}>
				<ApplicationProvider>
					<InstanceRouter />
				</ApplicationProvider>
			</Grommet>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
