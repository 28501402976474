import { useQuery } from "@apollo/client";
import { ListMarkets } from "../../../app/services/request/admin";

export function useMarkets() {
	const { data, loading, refetch } = useQuery(ListMarkets);

	return {
		isLoading: loading,
		markets: data?.ListMarkets ?? [],
		refetch
	};
}