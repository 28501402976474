import { Box, Button, Layer, LayerExtendedProps } from "grommet";
import { FormClose } from "grommet-icons";

interface ModalProps extends LayerExtendedProps {
	onClickClose?: () => void;
	onCloseAll?: () => void;
}

export const Modal: React.FC<ModalProps> = (props) => {

	function getLayerProps() {
		const layerProps = { ...props };

		delete layerProps.onCloseAll;
		delete layerProps.onClickClose;

		return layerProps;
	}

	return (
		<Layer {...getLayerProps()} onClickOutside={props.onCloseAll ?? props.onClickOutside} onEsc={props.onCloseAll ?? props.onEsc}>
			<Box margin="xxsmall" flex>
				{(props.onClickClose || props.onCloseAll) && (
					<Box align="end" margin="xxsmall">
						<Button
							hoverIndicator
							onClick={props.onCloseAll ?? props.onClickClose}
							icon={
								<FormClose size="medium" />
							}
						/>
					</Box>
				)}
				{props.children}
			</Box>
		</Layer>
	);
};