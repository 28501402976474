import { Box, Grid, Heading, Text } from "grommet";
import { Fragment, useMemo, useState } from "react";
import { Loader, Pagination, useWindowDimensions } from "../../../common";
import { ResidentPickupCard } from "./ResidentPickupCard";
import { ServiceDetailsSidebar } from "./ServiceDetailsSidebar";

export interface PickupDetails {
	id: string;
	serviceId: string;
	productCount: number;
	residenceId: string;
	residenceName: string;
	unitNumberFormatted: string;
	customerNameFormatted: string;
	scheduledDateFormatted: string;
	scheduled: boolean;
	started: boolean;
	completed: boolean;
}

interface BuildingPickupsCardProps {
	isLoading: boolean;
	startedPickups: PickupDetails[];
	scheduledPickups: PickupDetails[];
	completedPickups: PickupDetails[];
	onSelectResidence(residenceId: string): void;
}

export const BuildingPickupsCard: React.FC<BuildingPickupsCardProps> = (props) => {
	const dimensions = useWindowDimensions();

	const columns = useMemo(() => {
		return ["small", "medium"].includes(dimensions.size)
			? 1
			: 3;
	}, [dimensions.size]);

	const [selectedServiceId, setSelectedServiceId] = useState("");

	return (
		<Box>
			{!!selectedServiceId && (
				<ServiceDetailsSidebar
					serviceId={selectedServiceId}
					onClose={() => {
						setSelectedServiceId("");
					}}
				/>
			)}
			<Grid columns={{ count: columns, size: "auto" }} gap="small">
				<Box gap="small" flex>
					<Box align="center">
						<Heading margin="none" level="3" style={{ textDecoration: "underline" }}>Scheduled</Heading>
					</Box>
					<Loader visible={props.isLoading}>
						{props.scheduledPickups.length > 0 && (
							<Box gap="small" flex>
								<Pagination
									pageSize={4}
									items={props.scheduledPickups}
									renderItems={(pickups: PickupDetails[]) => (
										<Box gap="small" flex>
											{pickups.map(pickup => (
												<ResidentPickupCard
													key={pickup.serviceId}
													name={pickup.customerNameFormatted}
													unit={pickup.unitNumberFormatted}
													status="SCHEDULED"
													date={pickup.scheduledDateFormatted}
													items={pickup.productCount}
													residenceId={pickup.residenceId}
													residenceName={pickup.residenceName}
													onClick={() => {
														setSelectedServiceId(pickup.serviceId);
													}}
													onClickResidence={() => props.onSelectResidence(pickup.residenceId)}
												/>
											))}
										</Box>
									)}
								/>
							</Box>
						)}
						{props.scheduledPickups.length === 0 && (
							<Box align="center">
								<Text textAlign="center">nothing here yet</Text>
							</Box>
						)}
					</Loader>
				</Box>
				<Box gap="small" flex>
					<Box align="center">
						<Heading margin="none" level="3" style={{ textDecoration: "underline" }}>In Progress</Heading>
					</Box>
					<Loader visible={props.isLoading}>
						{props.startedPickups.length > 0 && (
							<Box gap="small" flex>
								<Pagination
									pageSize={4}
									items={props.startedPickups}
									renderItems={(pickups: PickupDetails[]) => (
										<Box gap="small" flex>
											{pickups.map(pickup => (
												<ResidentPickupCard
													key={pickup.serviceId}
													name={pickup.customerNameFormatted}
													unit={pickup.unitNumberFormatted}
													status="IN_PROGRESS"
													date={pickup.scheduledDateFormatted}
													items={pickup.productCount}
													residenceId={pickup.residenceId}
													residenceName={pickup.residenceName}
													onClick={() => {
														setSelectedServiceId(pickup.serviceId);
													}}
													onClickResidence={() => props.onSelectResidence(pickup.id)}
												/>
											))}
										</Box>
									)}
								/>
							</Box>
						)}
						{props.startedPickups.length === 0 && (
							<Box align="center">
								<Text textAlign="center">nothing here yet</Text>
							</Box>
						)}
					</Loader>
				</Box>
				<Box gap="small" flex>
					<Box align="center">
						<Heading margin="none" level="3" style={{ textDecoration: "underline" }}>Completed</Heading>
					</Box>
					<Loader visible={props.isLoading}>
						{props.completedPickups.length > 0 && (
							<Box gap="small" flex>
								<Pagination
									pageSize={4}
									items={props.completedPickups}
									renderItems={(pickups: PickupDetails[]) => (
										<Box gap="small" flex>
											{pickups.map(pickup => (
												<ResidentPickupCard
													key={pickup.serviceId}
													name={pickup.customerNameFormatted}
													unit={pickup.unitNumberFormatted}
													status="COMPLETED"
													date={pickup.scheduledDateFormatted}
													items={pickup.productCount}
													residenceId={pickup.residenceId}
													residenceName={pickup.residenceName}
													onClick={() => {
														setSelectedServiceId(pickup.serviceId);
													}}
													onClickResidence={() => props.onSelectResidence(pickup.id)}
												/>
											))}
										</Box>
									)}
								/>
							</Box>
						)}
						{props.completedPickups.length === 0 && (
							<Box align="center">
								<Text textAlign="center">nothing here yet</Text>
							</Box>
						)}
					</Loader>
				</Box>
			</Grid>
		</Box>
	);
};