import { useEffect } from "react";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../app/store";
import { Loader } from "../components";

export const HomePage: React.FC = (props) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(push("/dashboard"));
	}, []);
	return (
		<Loader visible={true} />
	);
};