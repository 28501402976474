import React, { createContext, useContext, useMemo } from "react";
import { useAppSelector } from "../../../app/store";
import { selectStripePublicKey } from "../../../app/store/config";
import { Loader } from "../../common";
import { loadStripe } from '@stripe/stripe-js';

const StripeContext = createContext<ReturnType<typeof loadStripe> | null>(null);

export const StripeProvider: React.FC = (props) => {
	const publicKey = useAppSelector(selectStripePublicKey);

	const stripePromise = useMemo(() => {
		if(publicKey) return loadStripe(publicKey);
		return null;
	}, [publicKey]);

	if(!stripePromise) return <Loader visible={true} />;

	return (
		<StripeContext.Provider value={stripePromise}>
			{props.children}
		</StripeContext.Provider>
	);
};

export function useRegoStripe() {
	const stripe = useContext(StripeContext);

	if(!stripe) {
		throw new Error(`could not get stripe instance ... was this hook used in the StripeProvider?`);
	}

	return stripe;
}