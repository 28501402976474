import axios from "axios";
import { setupInterceptorsTo } from "./intercept";
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client";
import { AuthService } from "../auth";

const axiosInstance = axios.create({
	withCredentials: true
});

export function updateBaseUrl(url: string): string {
	//FIXME: Config does not include backslash

	if(url.charAt(url.length - 1) !== "/") {
		console.debug(`Appending [/] to base url: [${url}]`);
		url += "/";
	}

	console.debug("Setting base url to: ", url);
	axiosInstance.defaults.baseURL = url;

	return url;
}

export const RequestService = setupInterceptorsTo(axiosInstance);

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers }: { headers: { [key: string]: string; }; }) => ({
		headers: {
			...headers,
			"X-Rego-GraphQL-Operation": operation.operationName,
			authorization: `Bearer ${AuthService.getAccessToken()}`
		}
	}));

	return forward(operation);
});

const customFetch = (uri: string, options: RequestInit) => {

	// This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
	let refreshingPromise: any | null = null;

	// Create initial fetch, this is what would normally be executed in the link without the override
	const initialRequest = fetch(uri, options);

	//skip if tokens not present in storage
	if(!AuthService.getAccessToken() || !AuthService.getRefreshToken()) {
		return initialRequest;
	}

	// The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
	// Here we return the initialRequest promise
	return initialRequest.then((response) => {
		return (response.json());
	}).then((json) => {
		// We should now have the JSON from the response of initialRequest
		// We check that we do and look for errors from the GraphQL server
		// If it has the error 'User is not logged in' (that's our implementation of a 401) we execute the next steps in the re-auth flow
		if(json && json.errors && json.errors[0] && json.errors[0].message === "Unauthorized") {
			if(!refreshingPromise) {
				const accessToken = AuthService.getAccessToken();
				const refreshToken = AuthService.getRefreshToken();
				if(!refreshToken) {
					throw new Error(`cannot get refresh token`);
				}

				refreshingPromise = fetch("/graphql", {
					method: "POST",
					credentials: "include",
					headers: {
						"content-type": "application/json",
						"authorization": `Bearer ${accessToken}`
					},
					body: JSON.stringify({
						operationName: "RefreshLogin",
						query: `
							mutation RefreshLogin($refreshToken: String!) {
								RefreshLogin(refreshToken: $refreshToken) {
									access_token
									refresh_token
								}
							}
						`.trim(),
						variables: {
							refreshToken
						}
					})
				}).then(response => response.json()).then(res => {
					const { access_token, refresh_token } = res?.data?.RefreshLogin;
					if(access_token && refresh_token) {
						AuthService.setAccessToken(access_token);
						AuthService.setRefreshToken(refresh_token);
						return access_token;
					}

					throw new Error("could not get access token from refresh request");
				}).catch(err => {
					console.error("Failed in token refresh ... clearing local state", err);
					AuthService.setAccessToken("");
					AuthService.setRefreshToken("");

					throw err;
				});
			}

			return refreshingPromise.then((newAccessToken: string) => {
				// Now that the refreshing promise has been executed, set it to null
				refreshingPromise = null;

				///@ts-ignore
				options.headers.authorization = `Bearer ${newAccessToken}`;

				// Return the promise from the new fetch (which should now have used an active access token)
				// If the initialRequest had errors, this fetch that is returned below is the final result.
				return fetch(uri, options);
			});
		}

		return {
			ok: true,
			text: () => new Promise(function (resolve, reject) {
				resolve(JSON.stringify(json));
			}),
			json: () => new Promise(function (resolve, reject) {
				resolve(json);
			})
		};
	});
};

const httpLink = new HttpLink({
	uri: "/graphql",
	fetch: customFetch
});

export const gqlClient = new ApolloClient({
	link: from([authLink, httpLink]),
	cache: new InMemoryCache(),
});

export * from "./auth";
export * from "./reference";
export * from "./pickup";