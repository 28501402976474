import { gql } from "../../../../__generated__/gql";

export const GetSelf = gql(/* GraphQL */ `
	query GetSelf {
		GetSelf {
			id
			firstName
			lastName
			role
			contacts {
				id
				type
				value
				isPrimary
				isVerified
			}
			config {
				defaultResidence {
					id
					name
				}
				defaultPartner {
					... on Hauler {
						id
						name
						type
					}
					... on DonationCenter {
						id
						name
						type
					}
					... on DisposalCenter {
						id
						name
						type
					}
				}
			}
		}
	}
`);

export const Account = gql(/* GraphQL */ `
	query Account {
		GetSelf {
			id
			firstName
			lastName
			emailAddress
			phoneNumber
			contacts {
				id
				type
				value
				isPrimary
				isVerified
			}
			currentResident {
				verification {
					unit
				}
				residence {
					name
					customizations {
						logoUrl
					}
				}
			}
		}
	}
`);
