import { useMemo } from "react";
import { useLocation } from "react-router";

export function useQueryParams() {
	const location = useLocation();

	const params = useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location.search]);

	return params;
}