import { useState, useEffect } from "react";

type WindowSize =
	| "small"
	| "medium"
	| "large"
	| "xlarge";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;

	const size = ((): WindowSize => {
		if(width >= 1200) {
			return "xlarge";
		}

		if(width >= 900) {
			return "large";
		}

		if(width >= 600) {
			return "medium";
		}

		return "small";
	})();

	return {
		size,
		width,
		height
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}