import { Anchor, Box, Heading, Paragraph } from "grommet";

export const Error500: React.FC = (props) => {
	return (
		<Box margin="large" gap="small">
			<Heading level="3">Well this is embarrassing</Heading>

			<Paragraph>
				We ran into an error and can't continue. Try refreshing the page or click below to try again
			</Paragraph>

			<Anchor
				label="Refresh Page"
				onClick={() => {
					window.location.reload();
				}}
			/>
		</Box>
	);
};