import { datadogRum } from "@datadog/browser-rum";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
	apiKey: "e8f6a186365c8d962c8b8b4253180fb0",
	plugins: [new BugsnagPluginReact()]
});

export function reportUser(userId?: string, email?: string, fullName?: string): void {
	Bugsnag.setUser(userId, email, fullName);
	datadogRum.setUser({ id: userId, email, name: fullName });
}

datadogRum.init({
	applicationId: "e79cbc31-ee43-4220-b132-7973cd59638c",
	clientToken: "pubcbee499b3aff76e9f921a786dd46e733",
	site: "datadoghq.com",
	service: "liverego-frontend",
	// Specify a version number to identify the deployed version of your application in Datadog 
	version: process.env.REACT_APP_VERSION ?? undefined,
	sampleRate: 100,
	sessionReplaySampleRate: 100,
	trackInteractions: true,
	defaultPrivacyLevel: "mask-user-input",
	allowedTracingOrigins: [
		"https://api.regoapp.io",
		"https://api.liverego.com",
		/https:\/\/.*\.api\.regoapp\.io/,
		/https:\/\/.*\.api\.liverego\.com/
	]
});

datadogRum.startSessionReplayRecording();