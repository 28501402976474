import { gql } from "../../../__generated__";

export const GetDashboardData = gql(/* GraphQL */ `
	query Dashboard {
		GetSelf {
			id
			firstName
			lastName
			contacts {
				type
				value
				isPrimary
				isVerified
			}
			services {
				id
				paid
				paidAt
				scheduled
				completed
				completedAt
				pickup {
					id
					scheduled
					scheduledDate
					scheduledWindowFrom
					scheduledWindowTo
					scheduledWindowTimezone
				}
				selectedEstimate {
					taxAmount
					totalAmount
					subtotalAmount
				}
				productCount
			}
			currentResident {
				verification {
					unit
				}
				residence {
					name
					customizations {
						logoUrl
						hideLogo
						customLandingPageMessage
					}
					upcomingPickups{
						id
						scheduledDate
						scheduledWindowFrom
						scheduledWindowTo
						scheduledWindowTimezone
					}
					nextScheduledPickup {
						id
						scheduledDate
						scheduledWindowFrom
						scheduledWindowTo
						scheduledWindowTimezone
					}	
				}
			}
		}
	}
`);

export const BeginCheckoutSession = gql(/* GraphQL */`
	mutation BeginCheckoutSession {
		BeginCheckoutSession {
			id
			phoneNumber
		}
	}
`);

export const FetchCheckoutSession = gql(/* GraphQL */`
	query FetchCheckoutSession(
		$sessionId: String!
		$phoneNumber: String!
	) {
		GetPickupSession(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
		) {
			id
			firstName
			lastName
			emailAddress
			isEmailVerified
			itemCountSmall
			disassemblyCountSmall
			itemCountMedium
			disassemblyCountMedium
			itemCountLarge
			disassemblyCountLarge
			itemCountXLarge
			disassemblyCountXLarge
			itemCountBagsBoxes
			itemCountMattresses
			itemCountChristmasTree
			media {
				fileName
				extension
				contentType
				contentUrl
			}
			paymentMethod {
				id
			}
			service {
				id
				paid
				products {
					itemClassification {
						name
						code
						pricing {
							assemblyFee
							disassemblyFee
							pickupFee
						}
					}
				}
			}
			pickup {
				id
				scheduled
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
				isCustomerScheduled
			}
			residence {
				id
				name
				customizations {
					logoUrl
					pricing {
						items{
							code
							pricing {
								assemblyFee
								disassemblyFee
								pickupFee
							}
						}
					}
					hideLogo
					customLandingPageMessage
				}
				upcomingPickups {
					id
					scheduled
					scheduledDate
					scheduledWindowFrom
					scheduledWindowTo
					scheduledWindowTimezone
				}
			}
			scheduledDate
			scheduledWindowFrom
			scheduledWindowTo
			scheduledWindowTimezone
			isCustomScheduled
			promotion {
				id
				code
				description
			}
			estimate {
				id
				subtotalAmount
				feeAmount
				discountAmount
				taxAmount
				totalAmount
			}
		}
	}
`);

export const AssignCheckoutSessionPickup = gql(/* GraphQL */`
	mutation AssignCheckoutSessionPickup(
		$sessionId: String!
		$phoneNumber: String!
		$pickupId: String!
	) {
		AssignCheckoutSessionPickup(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			pickupId: $pickupId
		) { id }
	}
`);

export const AssignCheckoutSessionPickupWindow = gql(/* GraphQL */`
	mutation AssignCheckoutSessionPickupWindow(
		$sessionId: String!
		$phoneNumber: String!
		$windowDate: String!
		$windowFrom: Int!
		$windowTo: Int!
		$windowTimezone: String!
	) {
		AssignCheckoutSessionPickupWindow(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			windowDate: $windowDate
			windowFrom: $windowFrom
			windowTo: $windowTo
			windowTimezone: $windowTimezone
		) { id }
	}
`);

export const GetPaymentMethodsSelf = gql(/* GraphQL */`
	query GetPaymentMethodsSelf {
		GetPaymentMethodsSelf {
			id
			brand
			lastFour
			expirationYear
			expirationMonth
			isPrimary
			canDelete
		}
	}
`);

export const GetFrontendConfig = gql(/* GraphQL */ `
	query GetFrontendConfig {
		GetFrontendConfig {
			host
			applications {
				stripe {
					publicKey
				}
			}
		}
	}
`);

export const CreatePaymentMethodIntent = gql(/* GraphQL */`
	mutation CreatePaymentMethodIntent {
		CreatePaymentMethodIntent
	}
`);

export const CreateCheckoutSessionEstimate = gql(/* GraphQL */`
	mutation CreateCheckoutSessionEstimate($sessionId: String! $phoneNumber: String!){
		CreateCheckoutSessionEstimate(sessionId: $sessionId phoneNumber: $phoneNumber) { 
			id
			baseAmount
			subtotalAmount
			taxAmount
			totalAmount
			discountAmount
			discounts {
				amount
				description
			}
			fees {
				type
				rate
				totalFee
				description
				subjectToFee
			}
		}
	}
`);

export const DeletePaymentMethod = gql(/* GraphQL */`
	mutation DeletePaymentMethod($paymentMethodId: String!) {
		DeletePaymentMethod(paymentMethodId: $paymentMethodId) { 
			id 
		}
	}
`);

export const AssignCheckoutSessionPaymentMethod = gql(/* GraphQL */`
	mutation AssignCheckoutSessionPaymentMethod(
		$sessionId: String!
		$phoneNumber: String!
		$paymentMethodId: String!
	) {
		AssignCheckoutSessionPaymentMethod(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			paymentMethodId: $paymentMethodId
		) { id }
	}
`);

export const ConfirmCheckoutSession = gql(/* GraphQL */`
	mutation ConfirmCheckoutSession(
		$sessionId: String!
		$phoneNumber: String!
	) {
		ConfirmCheckoutSession(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
		) { id }
	}
`);

export const BeginOneTimeCodeLogin = gql(/* GraphQL */`
	mutation BeginOneTimeCodeLogin($tokenId: String!) {
		BeginOneTimeCodeLogin(tokenId: $tokenId) {
			access_token
			refresh_token
		}
	}
`);

export const UpdateCheckoutSessionInventory = gql(/* GraphQL */`
	mutation UpdateCheckoutSessionInventory(
		$sessionId: String!
		$phoneNumber: String!
		$itemCountSmall: Int!
		$disassemblyCountSmall: Int!
		$itemCountMedium: Int!
		$disassemblyCountMedium: Int!
		$itemCountLarge: Int!
		$disassemblyCountLarge: Int!
		$itemCountXLarge: Int!
		$disassemblyCountXLarge: Int!
		$itemCountBagsBoxes: Int!
		$itemCountMattresses: Int!
		$itemCountChristmasTree: Int!
	) {
		UpdateCheckoutSessionInventory(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			itemCountSmall: $itemCountSmall
			disassemblyCountSmall: $disassemblyCountSmall
			itemCountMedium: $itemCountMedium
			disassemblyCountMedium: $disassemblyCountMedium
			itemCountLarge: $itemCountLarge
			disassemblyCountLarge: $disassemblyCountLarge
			itemCountXLarge: $itemCountXLarge,
			disassemblyCountXLarge: $disassemblyCountXLarge
			itemCountBagsBoxes: $itemCountBagsBoxes,
			itemCountMattresses: $itemCountMattresses
			itemCountChristmasTree: $itemCountChristmasTree
		) { id }
	}
`);

export const UploadCheckoutSessionMedia = gql(/* GraphQL */`
	mutation UploadCheckoutSessionMedia(
		$sessionId: String!
		$phoneNumber: String!
		$extension: String!
		$fileName: String!
		$content: String!
		$contentType: String!
	) {
		UploadCheckoutSessionMedia(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			extension: $extension
			fileName: $fileName
			content: $content
			contentType: $contentType
		) { 
			id 
			contentUrl
		}
	}
`);

export const GetBuildingByCode = gql(/* GraphQL */`
	query GetBuildingByCode($code: String!) {
		GetBuildingByCode(code: $code) {
			id
			name
			code
			customizations {
				logoUrl
				hideLogo
				customLandingPageMessage
			}
		}
	}
`);

export const AssignCheckoutSessionPromotion = gql(/* GraphQL */`
	mutation AssignCheckoutSessionPromotion(
		$sessionId: String!
		$phoneNumber: String!
		$promotionCode: String!
	) {
		AssignCheckoutSessionPromotion(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			promotionCode: $promotionCode
		) { 
			id
			promotion {
				id
				code
				description
			}
		 }
	}
`);

export const CreateContact = gql(/* GraphQL */`
	mutation CreateContact(
		$userId: String!
		$type: ContactType!
		$value: String!
		$isPrimary: Boolean!
	) {
		CreateContact(
			userId: $userId
			type: $type
			value: $value
			isPrimary: $isPrimary
		) { id }
	}
`);

export const BeginContactVerification = gql(/* GraphQL */`
	mutation BeginContactVerification(
		$userId: String!
		$contactId: String!
	) {
		BeginContactVerification(
			userId: $userId
			contactId: $contactId
		)
	}
`);

export const VerifyContact = gql(/* GraphQL */`
	mutation VerifyContact(
		$userId: String!
		$contactId: String!
		$code: String!
	) {
		VerifyContact(
			userId: $userId
			contactId: $contactId
			code: $code
		) { id }
	}
`);

export const UpdateCheckoutSessionContactInformation = gql(/* GraphQL */`
	mutation UpdateCheckoutSessionContactInformation(
		$sessionId: String!
		$phoneNumber: String!
		$firstName: String!
		$lastName: String!
	) {
		UpdateCheckoutSessionContactInformation(
			sessionId: $sessionId
			phoneNumber: $phoneNumber
			firstName: $firstName
			lastName: $lastName
		) { id }
	}
`);

export const BeginOnboarding = gql(/* GraphQL */`
	mutation BeginOnboarding($phoneNumber: String!) {
		BeginOnboarding(phoneNumber: $phoneNumber)
	}
`);

export const ConfirmOnboarding = gql(/* GraphQL */`
	mutation ConfirmOnboarding(
		$phoneNumber: String!
		$code: String!
		$unit: String!
		$buildingCode: String!
	) {
		ConfirmOnboarding(
			phoneNumber: $phoneNumber
			code: $code
			unit: $unit
			buildingCode: $buildingCode
		) {
			userId
			tokenId
			sessionId
			phoneNumber
		}
	}
`);

export const AdminGetPickups = gql(/* GraphQL */`
	query GetPickups(
		$completed: Boolean
		$completedAtAfter: DateTime
		$completedAtBefore: DateTime
		$scheduled: Boolean
		$scheduledForAfter: DateTime
		$scheduledForBefore: DateTime

	) {
		GetPickups(
			completed: $completed
			completedAtAfter: $completedAtAfter
			completedAtBefore: $completedAtBefore
			scheduled: $scheduled
			scheduledForAfter: $scheduledForAfter
			scheduledForBefore: $scheduledForBefore
		) {
			id
			started
			scheduled
			completed
			scheduledDate
			scheduledWindowFrom
			scheduledWindowTo
			scheduledWindowTimezone
			market {
				id
				code
			}
			residences {
				id
				name
				address {
					city
					state
				}
				code
				customizations {
					logoUrl
					hideLogo
					customLandingPageMessage
				}
			}
			services {
				id
				user {
					firstName
					lastName
					currentResident {
						verification {
							unit
						}
						residence {
							id
							name
						}
					}
				}
				selectedEstimate {
					totalAmount
				}
				productCount
				products {
					media {
						fileName
						contentUrl
					}
					item {
						id
						name
					}
					itemClassification {
						id
						name
						code
					}
				}
			}
			isCustomerScheduled
		}
	}
`);

export const AdminGetPickup = gql(/* GraphQL */`
	query GetPickup(
		$pickupId: String!
	) {
		GetPickup(
			pickupId: $pickupId
		) {
			id
			started
			startedAt
			scheduled
			scheduledAt
			completed
			completedAt
			scheduledDate
			scheduledWindowFrom
			scheduledWindowTo
			scheduledWindowTimezone
			market {
				id
				code
			}
			residences {
				id
				name
				address {
					city
					state
				}
				code
				customizations {
					logoUrl
					hideLogo
					customLandingPageMessage
				}
			}
			services {
				id
				user {
					firstName
					lastName
					currentResident {
						verification {
							unit
						}
						residence {
							id
							name
						}
					}
				}
				selectedEstimate {
					totalAmount
				}
				productCount
				products {
					media {
						fileName
						contentUrl
					}
					item {
						id
						name
					}
					itemClassification {
						id
						name
						code
					}
				}
			}
			isCustomerScheduled
		}
	}
`);

export const AdminGetService = gql(/* GraphQL */`
	query GetService(
		$serviceId: String!
	) {
		GetService(
			serviceId: $serviceId
		) {
			id
			scheduled
			completed
			pickup {
				id
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
				isCustomerScheduled
				scheduled
				started
				startedAt
				completed
				completedAt
			}
			user {
				id
				firstName
				lastName
				emailAddress
				phoneNumber
				currentResident {
					verification {
						unit
					}
					residence {
						id
						name
					}
				}
			}
			productCount
			products {
				id
				disposition
				media {
					fileName
					contentUrl
				}
				item {
					id
					name
				}
				itemClassification {
					id
					name
					code
				}
			}
			selectedEstimate {
				totalAmount
			}
		}
	}
`);

export const GetProduct = gql(/* GraphQL */`
	query GetProduct(
		$productId: String!
	) {
		GetProduct(
			productId: $productId
		) {
			id
			disposition
			media {
				fileName
				contentUrl
			}
			item {
				id
				name
			}
			itemClassification {
				id
				name
				code
			}
		}
	}
`);

export const UpdateProduct = gql(/* GraphQL */`
	mutation UpdateProduct(
		$productId: String!
		$intent: ProductIntent
		$disposition: ProductIntent
		$itemId: String
		$itemClassificationId: String
	) {
		UpdateProduct(
			productId: $productId
			intent: $intent
			disposition: $disposition
			itemId: $itemId
			itemClassificationId: $itemClassificationId
		) {
			id
			updatedAt
		}
	}
`);

export const QueryResidenceByAddress = gql(/* GraphQL */`
	query QueryResidenceByAddress(
		$addressLineOne: String
		$city: String
		$state: String
		$zip: String
	) {
		QueryResidenceByAddress(
			addressLineOne: $addressLineOne
			city: $city
			state: $state
			zip: $zip
		) {
			id
			name
			code
			customizations {
				logoUrl
				hideLogo
				customLandingPageMessage
			}
		}
	}
`);

export const ListUserManagedResidences = gql(/* GraphQL */ `
	query ListUserManagedResidences{
		ListUserManagedResidences{
			id
			name
			customizations {
				logoUrl
				hideLogo
				customLandingPageMessage
			}
		}
	}
`);

export const GetUnifiedDashboardServices = gql(/* GraphQL */ `
	query ListServices($residenceIds: [String!]!, $scheduledForAfter: DateTime) {
		ListServices(residenceIds: $residenceIds, scheduledForAfter: $scheduledForAfter) {
			id
			productCount
			user {
				firstName
				lastName
				emailAddress
				phoneNumber
				currentResident {
					residence {
						id
						name
					}
					verification {
						unit
					}
				}
			}
			paid
			pickup {
				id
				started
				scheduled
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
				completed
				completedAt
				expedited
				isCustomerScheduled
			}
		}
	}
`);

export const GetBuildingDashboardData = gql(/* GraphQL */ `
	query GetResidence($residenceId: String!, $scheduledForAfter: DateTime) {
		GetResidence(residenceId: $residenceId) {
			id
			name
			customizations {
				logoUrl
				hideLogo
				customLandingPageMessage
			}
			nextScheduledPickup {
				id
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
			}	
			upcomingPickups {
				id
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
			}
			pickups(scheduled:true, scheduledForAfter: $scheduledForAfter) {
				id
				started
				scheduled
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
				completed
				completedAt
				expedited
				isCustomerScheduled
				services(residenceId: $residenceId) {
					id
					user {
						firstName
						lastName
						currentResident {
							verification {
								unit
							}
						}
					}
					paid
					productCount
				}
			}
		}
	}
`);