import { Box, Heading } from "grommet";
import { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { BuildingPickupsCard, PickupDetails } from "../components";
import moment from "moment-timezone";
import { GetUnifiedDashboardServices } from "../../../../app/services/request/gql";
import { useBuildingDashboard } from "../hooks";

interface UnifiedDashboardProps {
	onSelectResidence(residenceId: string): void;
}

export const UnifiedDashboard: React.FC<UnifiedDashboardProps> = (props) => {
	const [execute, { data, loading }] = useLazyQuery(GetUnifiedDashboardServices);

	const { residences } = useBuildingDashboard();

	useEffect(() => {
		if(residences.length === 0) return;

		execute({
			variables: {
				residenceIds: residences.map(r => r.id),
				scheduledForAfter: moment().subtract(7, "days").startOf("day").toDate()
			}
		});
	}, [residences]);

	const services = useMemo((): PickupDetails[] => {
		return (data?.ListServices ?? []).map(service => {
			return {
				serviceId: service.id,
				id: service.pickup?.id ?? "",
				productCount: service.productCount ?? 0,
				customerNameFormatted: `${service.user.firstName} ${service.user.lastName.charAt(0).toUpperCase()}.`,
				residenceId: service.user.currentResident?.residence?.id ?? "",
				residenceName: service.user.currentResident?.residence?.name ?? "",
				unitNumberFormatted: `Unit ${service.user.currentResident?.verification?.unit ?? ""}`,
				scheduledDateFormatted: moment.tz(service.pickup?.scheduledDate, service.pickup?.scheduledWindowTimezone ?? "").format("MM/DD/YYYY"),
				scheduled: service.pickup?.scheduled ?? false,
				started: service.pickup?.started ?? false,
				completed: service.pickup?.completed ?? false
			};
		});
	}, [data]);

	return (
		<Box>
			<BuildingPickupsCard
				isLoading={loading}
				scheduledPickups={services.filter(s => s.scheduled && !s.started && !s.completed)}
				startedPickups={services.filter(s => s.started && !s.completed)}
				completedPickups={services.filter(s => s.completed)}
				onSelectResidence={props.onSelectResidence}
			/>
		</Box>
	);
};

interface ResidenceDetails {
	id: string;
	name: string;
	logoUrl?: string;
}

interface UnifiedDashboardResidenceCardProps {
	residences: ResidenceDetails[];
}

export const UnifiedDashboardResidenceCard: React.FC<UnifiedDashboardResidenceCardProps> = (props) => {
	return (
		<Box>

		</Box>
	);
};

interface ResidenceCardContainerProps {
	residences: ResidenceDetails[];
}

export const ResidenceCardContainer: React.FC<ResidenceCardContainerProps> = (props) => {
	return (
		<Box gap="small">
			<Heading margin="none" level="2">Residences / Buildings</Heading>
			<Box gap="small">
				{props.residences.map(residence => (
					<ResidenceCard
						key={residence.id}
						{...residence}
					/>
				))}
			</Box>
		</Box>
	);
};

interface ResidenceCardProps extends ResidenceDetails {

}

export const ResidenceCard: React.FC<ResidenceCardProps> = (props) => {
	return (
		<Box
			round
			hoverIndicator
			onClick={() => { }}
			background="light-2"
			pad="medium"
		>
			{props.name}
		</Box>
	);
};