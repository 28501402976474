import { useMutation, useQuery } from "@apollo/client";
import { Divider } from "@mui/material";
import { Box, Grid, Heading, Anchor, Text, FormField, TextInput } from "grommet";
import { FormAdd } from "grommet-icons";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo } from "react";
import { AssignCheckoutSessionPaymentMethod, AssignCheckoutSessionPromotion, CreateCheckoutSessionEstimate, GetPaymentMethodsSelf } from "../../../../../app/services/request/gql";
import { formatCurrency } from "../../../../../helpers";
import { PaymentMethodCard } from "../../../../auth";
import { Loader, LoadingButton, WrapSkeleton, useWindowDimensions, DashboardCard, useGoogleTagManager } from "../../../../common";
import { AddPaymentMethodModal } from "../../../../payments";
import { CheckoutSessionProps } from "./common";

interface CheckoutSessionPaymentMethodStepProps extends CheckoutSessionProps {
	paymentMethodId?: string;
}

export const CheckoutSessionPaymentMethodStep: React.FC<CheckoutSessionPaymentMethodStepProps> = (props) => {
	const { size } = useWindowDimensions();
	const gtm = useGoogleTagManager();
	const snack = useSnackbar();
	const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
	const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");

	const { data: paymentMethodsData, loading: paymentMethodsLoading, error: paymentMethodsError } = useQuery(GetPaymentMethodsSelf);
	const [createEstimateMutation, { data: estimateData, loading: estimateLoading, error: estimateError, reset }] = useMutation(CreateCheckoutSessionEstimate);
	const [assignPaymentMethodMutation, { loading: isAssigning, error: assignError }] = useMutation(AssignCheckoutSessionPaymentMethod);

	useEffect(() => {
		setSelectedPaymentMethodId(props.paymentMethodId ?? "");
	}, [props.paymentMethodId]);

	useEffect(() => {
		gtm.push("add_payment_info", {
			currency: "USD",
			value: estimateData?.CreateCheckoutSessionEstimate.totalAmount ?? 0,
			payment_type: "card",
			payment_method_id: selectedPaymentMethodId,
			items: props.eventData.items
		});
	}, [selectedPaymentMethodId]);

	const paymentMethods = useMemo(() => paymentMethodsData?.GetPaymentMethodsSelf ?? [], [paymentMethodsData]);

	function handleCreateEstimate(): void {
		createEstimateMutation({ variables: { sessionId: props.sessionId, phoneNumber: props.phoneNumber } });
	}

	useEffect(() => {
		handleCreateEstimate();
	}, []);

	useEffect(() => {
		if(paymentMethodsError) {
			console.error("error loading payment methods", paymentMethodsError);
			snack.enqueueSnackbar("We ran into an issue loading your information", { variant: "error" });
		}

		if(estimateError) {
			console.error("error loading estimate", estimateError);
			snack.enqueueSnackbar("We ran into an issue loading your information", { variant: "error" });
		}
	}, [paymentMethodsError, estimateError]);

	useEffect(() => {
		if(!selectedPaymentMethodId) {
			const primary = paymentMethods.find(p => p.isPrimary);
			if(primary) {
				setSelectedPaymentMethodId(primary.id);
			}
		}
	}, [paymentMethods]);

	const columns = useMemo(() => {
		switch(size) {
			case "small":
			case "medium": {
				return 1;
			}
			default: {
				return 2;
			}
		}
	}, [size]);

	function handleAssignPaymentMethod(): void {
		if(!selectedPaymentMethodId) {
			return;
		}

		assignPaymentMethodMutation({
			variables: {
				sessionId: props.sessionId, phoneNumber: props.phoneNumber, paymentMethodId: selectedPaymentMethodId
			}
		}).then(() => props.onComplete());
	}

	useEffect(() => {
		if(assignError) {
			console.error("Failed to assign payment method", assignError);
			snack.enqueueSnackbar("We ran into an issue saving your information", { variant: "error" });
		}
	}, [assignError]);

	return (
		<Loader visible={paymentMethodsLoading}>
			{isAddingPaymentMethod && (
				<AddPaymentMethodModal
					onClose={() => setIsAddingPaymentMethod(false)}
				/>
			)}
			<Box margin="medium" gap="small">
				<Grid columns={{ count: columns, size: "auto" }} gap="medium">
					<EstimateCard
						sessionId={props.sessionId}
						phoneNumber={props.phoneNumber}
						isLoading={estimateLoading}
						onPromotionApplied={() => {
							handleCreateEstimate();
						}}
						base={estimateData?.CreateCheckoutSessionEstimate.baseAmount ?? 0}
						subtotal={estimateData?.CreateCheckoutSessionEstimate.subtotalAmount ?? 0}
						tax={estimateData?.CreateCheckoutSessionEstimate.taxAmount ?? 0}
						total={estimateData?.CreateCheckoutSessionEstimate.totalAmount ?? 0}
						discounts={estimateData?.CreateCheckoutSessionEstimate.discounts ?? []}
						fees={estimateData?.CreateCheckoutSessionEstimate?.fees ?? []}
					/>
					<DashboardCard>
						<Box gap="medium" flex>
							<Box gap="small">
								<Heading level="3" margin="none">Payment Methods</Heading>
								<Text>
									Select the payment method you'd like to use for this pickup.
								</Text>
								<WrapSkeleton isLoading={paymentMethodsLoading} variant="rounded">
									<Box gap="medium">
										<Box gap="small">
											{paymentMethods.map(method => (
												<PaymentMethodCard
													{...method}
													key={method.id}
													isSelected={selectedPaymentMethodId ? selectedPaymentMethodId === method.id : method.isPrimary}
													onClick={() => {
														setSelectedPaymentMethodId(method.id);
													}}
												/>
											))}
										</Box>
										<Box>
											<Anchor
												label="Add Payment Method"
												color="accent-1"
												icon={<FormAdd />}
												onClick={() => setIsAddingPaymentMethod(true)}
											/>
										</Box>
									</Box>
								</WrapSkeleton>
							</Box>
							<Box flex align="end" justify="end">
								<LoadingButton
									primary
									isLoading={isAssigning}
									disabled={paymentMethodsLoading || !selectedPaymentMethodId}
									color="accent-1"
									label="Submit"
									onClick={() => handleAssignPaymentMethod()}
								/>
							</Box>
						</Box>
					</DashboardCard>
				</Grid>

			</Box>
		</Loader>
	);
};

interface EstimateCardProps {
	sessionId: string;
	phoneNumber: string;
	isLoading: boolean;
	base: number;
	subtotal: number;
	tax: number;
	total: number;
	discounts: {
		amount: number;
		description: string;
	}[];
	fees: {
		totalFee: number;
		description: string;
		subjectToFee: number;
	}[];
	onPromotionApplied(): void;
}

export const EstimateCard: React.FC<EstimateCardProps> = (props) => {
	const snack = useSnackbar();
	const gtm = useGoogleTagManager();
	const [promotionCode, setPromotionCode] = useState("");
	const [assignPromotionMutation, { loading }] = useMutation(AssignCheckoutSessionPromotion);

	function handleAssignPromotion() {
		if(!promotionCode) return;

		assignPromotionMutation({
			variables: { sessionId: props.sessionId, phoneNumber: props.phoneNumber, promotionCode }
		}).then((res) => {
			props.onPromotionApplied();
		}).catch(err => {
			console.error("Failed to apply promotion", err);
			const friendlyMessage = err.graphQLErrors?.[0]?.extensions?.friendlyMessage;
			snack.enqueueSnackbar(friendlyMessage ?? "We couldn't apply that promotion code. Please try again.", { variant: "error" });
		}).finally(() => {
			setPromotionCode("");
		});
	}

	return (
		<DashboardCard>
			<Box gap="small">
				<Heading level="3" margin="none">Pickup Estimate</Heading>
				<Box gap="medium" margin="medium">
					<Box gap="small">
						<WrapSkeleton isLoading={props.isLoading}>
							<Grid columns={["auto", "flex"]}>
								<Box>
									<Text>
										Base Cost:
									</Text>
								</Box>
								<Box align="end">
									<Text>
										{formatCurrency(props.base)}
									</Text>
								</Box>
							</Grid>
						</WrapSkeleton>
						{props.fees.length > 0 && (
							<WrapSkeleton isLoading={props.isLoading}>
								<Grid columns={["flex"]}>
									<Box>
										<Text weight="bold">
											Fees:
										</Text>
									</Box>
								</Grid>
								{props.fees.map((fee) => (
									<Box margin={{ left: "small" }} key={fee.description}>
										<Grid columns={["flex", "auto"]}>
											<Box>
												<Text>
													{fee.description} (x{fee.subjectToFee})
												</Text>
											</Box>
											<Box align="end">
												<Text>
													{formatCurrency(fee.totalFee)}
												</Text>
											</Box>
										</Grid>
									</Box>

								))}
							</WrapSkeleton>
						)}
						<WrapSkeleton isLoading={props.isLoading}>
							<Grid columns={["auto", "flex"]}>
								<Box>
									<Text>
										Subtotal:
									</Text>
								</Box>
								<Box align="end">
									<Text>
										{formatCurrency(props.subtotal)}
									</Text>
								</Box>
							</Grid>
						</WrapSkeleton>
						{props.discounts.length > 0 && (
							<WrapSkeleton isLoading={props.isLoading}>
								<Grid columns={["flex"]}>
									<Box>
										<Text weight="bold">
											Discounts:
										</Text>
									</Box>
								</Grid>
								{props.discounts.map((discount) => (
									<Box margin={{ left: "small" }} key={discount.description}>
										<Grid columns={["flex", "auto"]}>
											<Box>
												<Text>
													{discount.description}
												</Text>
											</Box>
											<Box align="end">
												<Text color="red">
													- {formatCurrency(discount.amount)}
												</Text>
											</Box>
										</Grid>
									</Box>

								))}
							</WrapSkeleton>
						)}
						<WrapSkeleton isLoading={props.isLoading}>
							<Grid columns={["auto", "flex"]}>
								<Box>
									<Text>
										Tax:
									</Text>
								</Box>
								<Box align="end">
									<Text>
										{formatCurrency(props.tax)}
									</Text>
								</Box>
							</Grid>
						</WrapSkeleton>
						<Divider />
						<WrapSkeleton isLoading={props.isLoading}>
							<Grid columns={["auto", "flex"]}>
								<Box>
									<Text weight="bold">
										Total:
									</Text>
								</Box>
								<Box align="end">
									<Text>
										{formatCurrency(props.total)}
									</Text>
								</Box>
							</Grid>
						</WrapSkeleton>
					</Box>
					<Box gap="small">
						<FormField
							name="promo"
							label="Promo Code"
							onChange={(event) => setPromotionCode(event.target.value)}
						>
							<TextInput
								name="promo"
								value={promotionCode}
							/>
						</FormField>
						<Box align="start">
							<LoadingButton
								disabled={!promotionCode}
								isLoading={loading}
								label="Apply"
								primary
								color="accent-1"
								onClick={handleAssignPromotion}
							/>
						</Box>
					</Box>
					<Box flex justify="end">
						<Text>
							Your card will not be charged until your pickup is completed. You can cancel for free if you cancel more than 24 hours from the scheduled pickup date. Cancellations within 24 hours are charged a 50% dispatch fee.
						</Text>
					</Box>
				</Box>
			</Box>
		</DashboardCard>
	);
};