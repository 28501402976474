
export const config = {
	google: {
		tagManager: {
			PARTNER: "GTM-W4WMRJX",
			MANAGER: "GTM-M8RNJLX",
			RESIDENT: "GTM-NDJ37VJ"
		}
	},
	contact: {
		phone: "12157706989"
	}
};

export default config;