import { Box, Button, Carousel, Form, FormField, Image, Select, Text } from "grommet";
import { ProductIntent } from "../../../__generated__/graphql";
import { Chip, Skeleton } from "@mui/material";
import { useWindowDimensions } from "../../common/hooks";
import { LoadingButton, RightExpandableSidebarModal, RightSidebarModalContext, WrapSkeleton, WrapSkeletonFormField } from "../../common";
import { useAppDispatch } from "../../../app/store";
import { push } from "redux-first-history";
import { useMutation, useQuery } from "@apollo/client";
import { GetProduct, UpdateProduct } from "../../../app/services/request/gql";
import { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { FormNext } from "grommet-icons";
import { useClassifications } from "../../common/hooks/useClassifications";

interface ProductSummarySidebarProps {
	onClose(): void;
	productId: string;
}

export const ProductSummarySidebar: React.FC<ProductSummarySidebarProps> = (props) => {
	const { product, isLoading, isUpdating, updateProduct } = useProductMutations(props.productId);

	const productBase: ProductBaseProps = useMemo(() => {
		return {
			id: product?.id ?? "",
			isLoading: isLoading,
			itemId: product?.item?.id ?? "",
			itemName: product?.item?.name ?? "",
			disposition: product?.disposition ?? null,
			classificationId: product?.itemClassification?.id ?? "",
			classificationCode: product?.itemClassification?.code ?? "",
			classificationName: product?.itemClassification?.name ?? "",
			media: (product?.media ?? []).map(m => {
				return {
					fileName: m.fileName,
					contentUrl: m.contentUrl ?? ""
				};
			})
		};
	}, [product, isLoading]);

	return (
		<RightExpandableSidebarModal onClose={props.onClose}>
			<RightSidebarModalContext.Consumer>
				{isExpanded => {
					return (isExpanded)
						? (
							<ProductDetailsComponent

							/>
						)
						: (
							<ProductSummaryComponent
								{...productBase}
								isUpdating={isUpdating}
								onUpdateProduct={updateProduct}
							/>
						);
				}}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};

export interface ProductBase {
	id: string;
	itemId: string;
	itemName: string;
	classificationId: string;
	classificationCode: string;
	classificationName: string;
	disposition: ProductIntent | null;
	media: {
		fileName: string;
		contentUrl: string;
	}[];
}

interface ProductBaseProps extends ProductBase {
	isLoading: boolean;
}

interface ProductSummaryComponentProps extends ProductBaseProps {
	isUpdating: boolean;
	onUpdateProduct(data: UpdateProductArgs): void;
}

export const ProductSummaryComponent: React.FC<ProductSummaryComponentProps> = (props) => {
	const dispatch = useAppDispatch();
	const { classifications, loading: classificationsLoading } = useClassifications();
	const [formState, setFormState] = useState({
		wasChanged: false,
		itemName: props.itemName,
		itemClass: props.classificationName
	});

	function handleViewMore(): void {
		dispatch(push(`/products/${props.id}`));
	}

	useEffect(() => {
		setFormState({
			...formState,
			itemClass: props.classificationName
		});
	}, [classificationsLoading, props.classificationName]);

	return (
		<Box margin="small" gap="medium" flex>
			<Form
				value={formState}
				onChange={(changes) => {
					setFormState({
						...formState,
						...changes,
						wasChanged: true,
					});
				}}
				onSubmit={() => {
					const updates: UpdateProductArgs = {
						disposition: props.disposition || undefined,
						itemId: undefined,
						itemClassificationId: classifications.find(c => c.name === formState.itemClass)?.id || undefined
					};

					props.onUpdateProduct(updates);
				}}
			>
				<Box gap="medium">
					<Box>
						<WrapSkeletonFormField
							isLoading={props.isLoading}
							label="Product Id"
							value={props.id}
							icon={<FormNext />}
							specialFieldType="ACTION"
							onAction={handleViewMore}
						/>
						<WrapSkeletonFormField
							name="itemType"
							isLoading={props.isLoading}
							label="Item Type"
							value={props.itemName}
						/>
						<FormField
							name="itemClass"
							label="Item Class"
						>
							{(props.isLoading || classificationsLoading) && (
								<Box pad="xsmall">
									<Skeleton style={{ minHeight: "30px" }} />
								</Box>
							)}
							{!(props.isLoading || classificationsLoading) && (
								<Select
									name="itemClass"
									disabled={props.isLoading}
									options={classifications.map(c => c.name)}
								/>
							)}
						</FormField>
					</Box>
					<Box gap="small">
						<Box height="small" gap="small">
							{(!props.isLoading && props.media.length === 0) && (
								<Box align="center" justify="center" flex>
									<Text weight="bold">No image(s) uploaded</Text>
								</Box>
							)}
							{(props.isLoading || props.media.length > 0) && (
								<WrapSkeleton
									fillHeight={true}
									isLoading={props.isLoading}
								>
									<Carousel
										fill
										wrap
										controls="arrows"
										initialChild={1}
									>
										{props.media.map(media => (
											<Box key={media.fileName} >
												<Image
													fit="cover"
													src={media.contentUrl}
												/>
											</Box>
										))}
									</Carousel>
								</WrapSkeleton>
							)}
						</Box>
						<Box align="center">
							<Button
								primary
								color="accent-1"
								label="Upload Image(s)"
							/>
						</Box>
					</Box>
					<Box justify="between" direction="row" flex align="end">
						<LoadingButton
							primary
							isLoading={false}
							color="accent-1"
							label="View More"
							onClick={handleViewMore}
						/>
						<LoadingButton
							primary
							isLoading={props.isUpdating}
							color="accent-1"
							type="submit"
							disabled={!formState.wasChanged}
							label="Save Changes"
						/>
					</Box>
				</Box>
			</Form>
		</Box>
	);
};

interface ProductDetailsComponentProps {

}

export const ProductDetailsComponent: React.FC<ProductDetailsComponentProps> = (props) => {
	return (
		<Box>
			PRODUCT DETAILS
		</Box>
	);
};

export interface ProductCardProps extends ProductBase {
	isLoading: boolean;
	isSmall: boolean;
	serviceId: string;
	pickupId: string;
	onSelected(): void;
}

export const ProductSummaryCard: React.FC<ProductCardProps> = (props) => {
	const { size } = useWindowDimensions();
	return (
		<Box
			round
			pad="medium"
			hoverIndicator
			background="light-2"
			onClick={props.onSelected}
		>
			<Box direction={size === "small" ? "column" : "row"} gap="small">
				<Box>
					<Text>Item Type: <Text weight="bold">{props.itemName || "None"}</Text></Text>
					<Text>Item Class: <Text weight="bold">{props.classificationName || "None"}</Text></Text>
				</Box>
				<Box justify="end" align="center" gap="small" direction="row" flex>
					{props.disposition === ProductIntent.Donate && (
						<Chip
							color="success"
							label="Donated"
						/>
					)}
					{props.disposition === ProductIntent.Junk && (
						<Chip
							color="success"
							label="Disposed"
						/>
					)}
					{!props.disposition && (
						<Chip
							color="warning"
							label="Pending"
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export interface UpdateProductArgs {
	intent?: ProductIntent;
	disposition?: ProductIntent;
	itemId?: string;
	itemClassificationId?: string;
}

export function useProductMutations(productId: string) {
	const snack = useSnackbar();
	const { data: productData, loading: isLoadingProduct, refetch } = useQuery(GetProduct, {
		variables: { productId }
	});

	const [updateProductMutation, { loading: isUpdatingProduct }] = useMutation(UpdateProduct);


	function updateProduct(data: UpdateProductArgs): void {
		updateProductMutation({
			variables: {
				productId,
				...data
			}
		}).then(() => {
			refetch();
		}).catch(err => {
			console.error("Failed to update product", productId, err);
			snack.enqueueSnackbar(`We ran into an issue updating your information`, { variant: "error" });
		});
	}

	return {
		product: productData?.GetProduct ?? null,
		isLoading: isLoadingProduct,
		isUpdating: isUpdatingProduct,
		updateProduct
	};
}