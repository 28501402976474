import { FC } from "react";
import { useAppSelector } from "../../../app/store";
import { selectUser } from "../../../app/store/application";
import { LoginRequiredPage } from "../pages";

export const withAuth = (Component: FC, hideRegistration?: boolean) => {
	const Auth = (props: any) => {
		const user = useAppSelector(selectUser);

		return (
			<>
				{(user) ? <Component {...props} /> : <LoginRequiredPage hideRegistration={hideRegistration} />}
			</>
		);

	};

	return Auth;
};