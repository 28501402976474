import { gql } from "../../../../__generated__";

export const ListVehicles = gql(/* GraphQL */ `
	query ListVehicles {
    	ListVehicles {
			id
			name
			type
		}		
    }
`);