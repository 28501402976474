import { useLazyQuery } from "@apollo/client";
import { Box, Button, FormField, Select, Spinner, Text, TextInput } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { ListPartners, ListResidents } from "../../../../app/services/request/admin";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectIsAdmin, selectUser } from "../../../../app/store/application";
import { AdminControlsBox } from "../../../common";
import Fuse from "fuse.js";
import { DataValue } from "@apollo/react-hoc";
import { ListResidentsQuery } from "../../../../__generated__/graphql";
import { setImpersonateUserId } from "../../../../app/store/admin";
import { FormClose } from "grommet-icons";

type Unpacked<T> = T extends (infer U)[] ? U : T;
type ResidentQueryType = NonNullable<Unpacked<NonNullable<DataValue<ListResidentsQuery>>["ListResidents"]>>;

interface AdminControlsProps {
	isLoading: boolean;
}

const fuse = new Fuse<ResidentQueryType>(
	[],
	{
		keys: [
			{ name: "user.firstName", weight: .33 },
			{ name: "user.lastName", weight: .34 },
			{ name: "user.emailAddress", weight: .33 },
		],
		threshold: 0.3,
		includeScore: true,
		ignoreLocation: true,
		ignoreFieldNorm: true,
		minMatchCharLength: 3
	}
);

export const ResidentAdminControls: React.FC<AdminControlsProps> = (props) => {
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const isAdmin = useAppSelector(selectIsAdmin);
	const [execute, { data, loading, error }] = useLazyQuery(ListResidents);

	const [resident, setResident] = useState<ResidentQueryType | null>(null);

	const [query, setQuery] = useState("");

	useEffect(() => {
		if(isAdmin) {
			execute({});
		}
	}, []);

	useEffect(() => {
		if(data?.ListResidents) {
			const found = data.ListResidents.find(r => r?.user?.id === user?.id);
			if(found) {
				setResident({ ...found });
			}
		}
	}, [data]);

	useEffect(() => {
		if(data?.ListResidents) {
			fuse.setCollection(data?.ListResidents || []);
		}
	}, [data]);

	function toSuggestion(r: ResidentQueryType) {
		return `${r.user?.lastName}, ${r.user?.firstName} (${r.user?.emailAddress})`;
	}

	function fromSuggestion(s: string) {
		const components = s.split(", ");
		const lastName = components[0] ?? "";
		const firstName = components[1]?.split(" ")[0] ?? "";
		const emailAddress = s.match(/\((.*)\)/)?.[1] ?? "";

		return (data?.ListResidents ?? []).find(r => {
			return r.user?.firstName === firstName && r.user?.lastName === lastName && r.user?.emailAddress === emailAddress;
		});
	}

	const suggestions = useMemo(() => {
		return fuse.search(query).map(r => toSuggestion(r.item));
	}, [query]);

	if(!isAdmin) {
		return null;
	}

	return (
		<AdminControlsBox>
			<Box gap="small">
				<Box margin={{ horizontal: "small" }}>
					<Text weight="bold">Viewing As</Text>
				</Box>
				<Box
					width="large"
					direction="row"
					align="center"
					pad={{ horizontal: "xsmall" }}
					border
				>
					<TextInput
						plain
						value={query}
						suggestions={suggestions}
						onChange={(event) => {
							setQuery(event.target.value);
						}}
						onSuggestionSelect={(selected) => {
							const found = fromSuggestion(selected.suggestion);
							if(found) {
								setResident({ ...found });
								setQuery(toSuggestion(found));
								dispatch(setImpersonateUserId(found.user?.id ?? ""));
							}
						}}
					/>
					<Button
						icon={<FormClose />}
						onClick={() => {
							setQuery("");
						}}
					/>
				</Box>
			</Box>
		</AdminControlsBox>
	);
};