import React from "react";
import { LayoutProvider } from "../features/common";
import { ResidentRouter } from "../features/dashboard";

export const ResidentEntryPoint: React.FC = (props) => {
	return (
		<LayoutProvider>
			<ResidentRouter />
		</LayoutProvider>
	);
};