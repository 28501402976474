import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

export interface ConfigState {
	host: string;
	stripePublicKey: string;
}

const initialState: ConfigState = {
	host: "",
	stripePublicKey: ""
};

export const configSlice = createSlice({
	name: "config",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setHost: (state, action: PayloadAction<string>) => {
			state.host = action.payload;
		},
		setStripePublicKey: (state, action: PayloadAction<string>) => {
			state.stripePublicKey = action.payload;
		}
	}
});

export const {
	setHost,
	setStripePublicKey
} = configSlice.actions;

export const selectHost = (state: RootState): string => state.config.host;
export const selectStripePublicKey = (state: RootState): string => state.config.stripePublicKey;

export default configSlice.reducer;