import { useLazyQuery, useMutation } from "@apollo/client";
import { UserModel } from "@rego-app/schema";
import { Anchor, Box, Button, Form, FormField, Heading, Page, PageContent, Text, TextInput } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { push } from "redux-first-history";
import { useLoginNavigation } from "../../../app/navigation";
import { BeginLogin, GetSelf, Login } from "../../../app/services/request";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectUser, setLoginResponse, setUserLogin } from "../../../app/store/application";
import { getNumberFormValidations, getStandardFormValidations } from "../../../helpers";
import { LoadingButton } from "../../common";
import { useQueryParams } from "../../common/hooks";

function useAuthController() {
	const dispatch = useAppDispatch();

	const [GetSelfQuery] = useLazyQuery(GetSelf);
	const [login, { }] = useMutation(Login);
	const [beginLogin, { }] = useMutation(BeginLogin);

	const [username, setUsername] = useState("");
	const [wasCodeSent, setWasCodeSent] = useState(false);
	const [hasSendCodeError, setHasSendCodeError] = useState(false);
	const [isRequestingCode, setIsRequestingCode] = useState(false);
	const [isAttemptingLogin, setIsAttemptingLogin] = useState(false);
	const [hasLoginError, setHasLoginError] = useState(false);
	const [wasLoginSuccessful, setWasLoginSuccessful] = useState(false);

	function requestLoginCode(username: string): Promise<boolean> {
		setUsername(username);
		setIsRequestingCode(true);
		setHasSendCodeError(false);

		return beginLogin({ variables: { username } }).then(res => {
			setWasCodeSent(true);
			return res.data?.BeginLogin ?? false;
		}).catch(err => {
			console.error("Caught an error in beginLogin", err);
			setHasSendCodeError(true);
			return false;
		}).finally(() => {
			setIsRequestingCode(false);
		});
	}

	function confirmLoginCode(username: string, code: string): Promise<boolean> {
		setHasLoginError(false);
		setIsAttemptingLogin(true);
		return login({ variables: { username, code } }).then(res => {
			const result = res.data?.Login;
			if(!result) throw new Error(`could not get response from body`);

			dispatch(setLoginResponse({
				username,
				accessToken: result.access_token,
				refreshToken: result.refresh_token
			}));

			return GetSelfQuery();
		}).then(res => {
			const result = res.data?.GetSelf;
			if(!result) throw new Error(`could not get user response from body`);
			dispatch(setUserLogin(result));
			setWasLoginSuccessful(true);
			return true;
		}).catch(err => {
			console.error("Caught an error in login", err);
			setHasLoginError(true);
			return false;
		}).finally(() => {
			setIsAttemptingLogin(false);
		});
	}

	function resendLoginCode(): Promise<boolean> {
		if(!wasCodeSent || !username) {
			return Promise.resolve(false);
		}

		return requestLoginCode(username);
	}

	return {
		isAttemptingLogin,
		isRequestingCode,
		wasCodeSent,
		wasLoginSuccessful,
		hasLoginError,
		hasSendCodeError,
		resendLoginCode,
		confirmLoginCode,
		requestLoginCode
	};
}

export const LoginPage: React.FC = (props) => {
	const params = useQueryParams();
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const authController = useAuthController();
	const loginNavigation = useLoginNavigation();

	const [formState, setFormState] = useState({
		code: "",
		username: ""
	});

	const isLoading = useMemo(() => {
		return authController.isRequestingCode || authController.isAttemptingLogin;
	}, [authController.isRequestingCode, authController.isAttemptingLogin]);

	const returnUrl = useMemo(() => {
		return params.get("returnUrl") ?? "/dashboard";
	}, [params]);

	useEffect(() => {
		if(user) {
			onLoginSuccess();
		}
	}, [user]);

	function onLoginSuccess(): void {
		dispatch(push(returnUrl));
	}

	function resendCode(): void {
		if(!authController.wasCodeSent) {
			return;
		}

		setFormState({ ...formState, code: "" });
		authController.requestLoginCode(formState.username);
	}

	function handleSubmit(): void {
		if(!authController.wasCodeSent) {
			authController.requestLoginCode(formState.username);
			return;
		}

		if(formState.code) {
			authController.confirmLoginCode(formState.username, formState.code).then(res => {
				if(res) {
					onLoginSuccess();
				}
			});
		}
	}

	return (
		<Page kind="narrow" margin="medium">
			<PageContent>
				<Form
					value={formState}
					onChange={(changes) => setFormState({ ...formState, ...changes })}
					validate="submit"
					onSubmit={handleSubmit}
				>
					<Box gap="large">
						<Box gap="small">
							<Box align="center">
								<Heading level="2">Log In</Heading>
							</Box>
							<FormField
								name="username"
								label="Email Address or Phone Number"
								validate={[
									...getStandardFormValidations()
								]}
							>
								<TextInput
									name="username"
								/>
							</FormField>
							{authController.wasCodeSent && (
								<FormField
									name="code"
									label="Login Code"
									validate={[
										...getStandardFormValidations(),
										...getNumberFormValidations()
									]}
								>
									<TextInput
										name="code"
									/>
								</FormField>
							)}
							{authController.hasSendCodeError && (
								<Text color="status-error">
									We ran into an issue sending your login code
								</Text>
							)}
							{authController.wasCodeSent && (
								<span>
									{(authController.wasCodeSent && !authController.hasLoginError) && (
										<Text weight="bold">
											We just sent a 6 digit code to your phone or email. Please enter the code above.
										</Text>
									)}
									{(authController.hasLoginError) && (
										<Text weight="bold">
											That code doesn't look right. Please try entering again.
										</Text>
									)}
									&nbsp;
									<Anchor color="accent-1" label="Need another code?" onClick={resendCode} />
								</span>
							)}
						</Box>
						<Box justify="between" direction="row">
							<Anchor
								label="Register?"
								color="accent-1"
								onClick={loginNavigation.registerNavigate}
							/>
							<LoadingButton
								isLoading={isLoading}
								type="submit"
								label="Submit"
								primary
								color="accent-1"
							/>
						</Box>
					</Box>
				</Form>
			</PageContent>
		</Page>
	);
};

interface LoginRequiredPageProps {
	hideRegistration?: boolean;
}

export const LoginRequiredPage: React.FC<LoginRequiredPageProps> = (props) => {
	const loginNavigation = useLoginNavigation();

	return (
		<Page kind="narrow">
			<PageContent>
				<Box gap="medium">
					<Box align="center" gap="small">
						<Heading>
							Login Required
						</Heading>
						<Text>
							You need to be logged in to view this page. Please {props.hideRegistration ? "login" : "login or register"} below
						</Text>
					</Box>
					<Box direction="row-responsive" align="center" gap="medium" justify="center">
						{!props.hideRegistration && (
							<Box align="center">
								<Button
									primary
									label="Register"
									color="accent-1"
									onClick={loginNavigation.registerNavigate}
								/>
							</Box>
						)}
						<Box align="center">
							<Button
								primary
								label="Login"
								color="accent-1"
								onClick={loginNavigation.loginNavigate}
							/>
						</Box>
					</Box>
				</Box>
			</PageContent>
		</Page>
	);
};