import { useMutation } from "@apollo/client";
import { Box, Heading, Grid, DateInput, Form, FormField, Select, Text } from "grommet";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { AssignCheckoutSessionPickup, AssignCheckoutSessionPickupWindow } from "../../../../../app/services/request/gql";
import { useWindowDimensions, useTimezone, LoadingButton, useQueryParams } from "../../../../common";
import { formatCurrency, getStandardFormValidations } from "../../../../../helpers";
import moment from "moment-timezone";
import { AvailablePickupCard, CheckoutSessionProps } from "./common";
import { Chip, List } from "@mui/material";
import { pickupWindows } from "../../../helpers";

interface CheckoutSessionScheduleStepProps extends CheckoutSessionProps {
	selectedPickupId?: string;
	availablePickups: {
		id: string;
		date: string;
		from: number;
		to: number;
		timezone: string;
	}[];
	customPickupDate?: string;
	customPickupWindow?: string;
	buildingPickupFee: number;
	standardPickupFee: number;
}

export const CheckoutSessionScheduleStep: React.FC<CheckoutSessionScheduleStepProps> = (props) => {
	const { size } = useWindowDimensions();
	const snack = useSnackbar();
	const timezone = useTimezone();
	const [isLoading, setIsLoading] = useState(false);
	const [assignCheckoutSessionPickupMutation] = useMutation(AssignCheckoutSessionPickup);
	const [assignCheckoutSessionPickupWindowMutation] = useMutation(AssignCheckoutSessionPickupWindow);

	function assignCheckoutSessionPickup(pickupId: string): void {
		setIsLoading(true);
		assignCheckoutSessionPickupMutation({
			variables: {
				pickupId,
				sessionId: props.sessionId,
				phoneNumber: props.phoneNumber
			}
		}).then(() => {
			props.onComplete();
		}).catch(err => {
			console.error(`Failed to assign session [${props.sessionId}] to pickup [${pickupId}]`);
			snack.enqueueSnackbar("We ran into an issue scheduling your pickup", { variant: "error" });
		}).finally(() => {
			setIsLoading(false);
		});
	}

	function assignCheckoutSessionPickupWindow(date: string, from: number, to: number): void {
		setIsLoading(true);
		assignCheckoutSessionPickupWindowMutation({
			variables: {
				sessionId: props.sessionId,
				phoneNumber: props.phoneNumber,
				windowDate: date,
				windowFrom: from,
				windowTo: to,
				windowTimezone: timezone
			}
		}).then(() => {
			props.onComplete();
		}).catch(err => {
			console.error(`Failed to assign session [${props.sessionId}] to window [${date}]`);
			snack.enqueueSnackbar("We ran into an issue scheduling your pickup", { variant: "error" });
		}).finally(() => {
			setIsLoading(false);
		});
	}

	return (
		<Box margin="medium" gap="small">
			<Heading level="2" margin="none">Schedule Your Pickup</Heading>
			<Grid columns={{ count: ["small", "medium"].includes(size) ? 1 : 2, size: "auto" }} gap="medium">
				<ScheduleBuildingPickup
					total={props.buildingPickupFee}
					isLoading={isLoading || props.isLoading}
					selectedPickupId={props.selectedPickupId}
					onContinue={assignCheckoutSessionPickup}
					availablePickups={props.availablePickups}
				/>
				<ScheduleExpressPickup
					total={props.standardPickupFee}
					isLoading={isLoading || props.isLoading}
					onContinue={assignCheckoutSessionPickupWindow}
					date={props.customPickupDate}
					window={props.customPickupWindow}
				/>
			</Grid>
		</Box>
	);
};

interface ScheduleExpressPickupProps {
	date?: string;
	window?: string;
	isLoading: boolean;
	total: number;
	onContinue(date: string, from: number, to: number): void;
}

export const ScheduleExpressPickup: React.FC<ScheduleExpressPickupProps> = (props) => {
	const timezone = useTimezone();
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedWindow, setSelectedWindow] = useState("");

	useEffect(() => {
		setSelectedDate(props.date ?? "");
	}, [props.date]);

	useEffect(() => {
		setSelectedWindow(props.window ?? "");
	}, [props.window]);

	const disabledDates = (() => {
		const disabled: string[] = [];

		const maxDays = 31;
		const cursor = moment();

		while(cursor.diff(moment(), "days") < maxDays) {
			if([0, 6].includes(cursor.day())) {
				disabled.push(cursor.toISOString());
			}

			cursor.add(1, "day");
		}

		return disabled;
	})();

	return (
		<Box elevation="small" gap="medium" round pad="small">
			<Box>
				<Box direction="row" margin="small">
					<Box>
						<Heading margin="none" level="3">Schedule Express Pickup</Heading>
					</Box>
					<Box flex align="end">
						<Text size="large" weight="bold">{formatCurrency(props.total)}</Text>
					</Box>
				</Box>
				<Box margin="small">
					<Text>
						Schedule an on-demand pickup that fits your schedule (extra fee applies, please check building elevator availability)
					</Text>
				</Box>
			</Box>
			<Form
				value={{
					selectedDate,
					selectedWindow
				}}
				onChange={(changes) => {
					setSelectedDate(changes.selectedDate);
					setSelectedWindow(changes.selectedWindow);
				}}
				onSubmit={() => {
					const window = pickupWindows.find(w => w.label === selectedWindow);
					if(window && selectedDate) {
						props.onContinue(
							moment.tz(selectedDate, timezone).format("YYYY-MM-DD"),
							window.from,
							window.to
						);
					}
				}}
			>
				<Box gap="small">
					<FormField
						name="selectedDate"
						label="Pickup Date"
						validate={[
							...getStandardFormValidations()
						]}
					>
						<DateInput
							name="selectedDate"
							dropProps={{
								align: { bottom: "top" }
							}}
							format={"mm/dd/yyyy"}
							calendarProps={{
								animate: true,
								firstDayOfWeek: 0,
								showAdjacentDays: "trim",
								bounds: [
									moment().add(1, "days").toDate().toISOString(),
									moment().add(30, "days").toDate().toISOString()
								],
								disabled: disabledDates
							}}
							readOnly
						/>
					</FormField>
					<FormField
						name="selectedWindow"
						label="Pickup Window"
						validate={[
							...getStandardFormValidations()
						]}
					>
						<Select
							name="selectedWindow"
							options={pickupWindows.map(w => w.label)}
						/>
					</FormField>
					<Box align="end" justify="end" flex>
						<LoadingButton
							primary
							isLoading={props.isLoading}
							disabled={!selectedDate || !selectedWindow}
							color="accent-1"
							label="Continue"
							type="submit"
						/>
					</Box>
				</Box>
			</Form>
		</Box>
	);
};


interface ScheduleBuildingPickupProps {
	isLoading: boolean;
	selectedPickupId?: string;
	availablePickups: {
		id: string;
		date: string;
		from: number;
		to: number;
		timezone: string;
	}[];
	total: number;
	onContinue(date: string): void;
}

export const ScheduleBuildingPickup: React.FC<ScheduleBuildingPickupProps> = (props) => {
	const [selectedPickup, setSelectedPickup] = useState("");

	useEffect(() => {
		setSelectedPickup(props.selectedPickupId ?? "");
	}, [props.selectedPickupId]);

	const queryParams = useQueryParams();

	useEffect(() => {
		if(queryParams.get("pickupId")) {
			setSelectedPickup(queryParams.get("pickupId") ?? "");
		}
	}, [queryParams]);

	return (
		<Box elevation="small" gap="medium" round pad="small">
			<Box>
				<Box direction="row" margin="small">
					<Box>
						<Heading margin="none" level="3">Schedule Building Pickup</Heading>
					</Box>
					<Box flex align="end">
						<Text size="large" weight="bold">{formatCurrency(props.total)}</Text>
					</Box>
				</Box>
				<Box margin="small">
					<Text>Select a pre-scheduled pickup time from your building with preferred rates</Text>
				</Box>
			</Box>
			<Box>
				<List>
					{props.availablePickups.map((pickup, index) => (
						<AvailablePickupCard
							key={pickup.id}
							date={pickup.date}
							to={pickup.to}
							from={pickup.from}
							timezone={pickup.timezone}
							isFastest={index === 0}
							isSelected={pickup.id === selectedPickup}
							onSelect={() => setSelectedPickup(pickup.id)}
						/>
					))}
				</List>
				{props.availablePickups.length === 0 && (
					<Box align="center" justify="center">
						<Text>no pickup dates found</Text>
					</Box>
				)}
			</Box>
			<Box align="end" flex justify="end" pad="small">
				<LoadingButton
					primary
					isLoading={props.isLoading}
					disabled={!selectedPickup}
					color="accent-1"
					label={"Continue"}
					onClick={() => props.onContinue(selectedPickup)}
				/>
			</Box>
		</Box>
	);
};