import { SnackbarProvider } from "notistack";

export const AlertProvider: React.FC = (props) => {

	return (
		<SnackbarProvider
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			autoHideDuration={2500}
		>
			{props.children}
		</SnackbarProvider>
	);
};