import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import config from "../../../app/config";
import { useAppSelector } from "../../../app/store";
import { selectUser } from "../../../app/store/application";

export const useGoogleTagManager = () => {
	const [isReady, setIsReady] = useState(false);
	const user = useAppSelector(selectUser);

	useEffect(() => {
		const tagId = (() => {
			if(window.location.host.includes("admin.liverego")) {
				return null;
			}

			if(window.location.host.includes("resident.liverego")) {
				return config.google.tagManager.RESIDENT;
			}

			if(window.location.host.includes("manager.liverego")) {
				return config.google.tagManager.MANAGER;
			}

			if(window.location.host.includes("partner.liverego")) {
				return config.google.tagManager.PARTNER;
			}

			return config.google.tagManager.RESIDENT;
		})();

		if(tagId) {
			const tagManagerArgs = {
				gtmId: tagId,
				dataLayer: {
					userId: user?.id ?? ""
				}
			};

			TagManager.initialize(tagManagerArgs);
		}

		setIsReady(true);
	}, []);

	return {
		isReady,
		push: (event: string, data: object) => {
			if(isReady) {
				TagManager.dataLayer({
					dataLayer: {
						event,
						...data
					}
				});
			}
		}
	};
};