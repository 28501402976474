import { useQuery } from "@apollo/client";
import { Box, Button, Form, FormField, Grid, Heading, MaskedInput, Spinner, TextInput } from "grommet";
import { Phone } from "grommet-icons";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Account } from "../../../app/services/request";
import { ContactType } from "../../../__generated__/graphql";
import { useWindowDimensions } from "../../common/hooks";
import { CreateContactModal, UserPaymentMethods } from "../components";
import { withAuth } from "../guards";

interface ContactVerificationState {
	type: ContactType;
	value?: string;
	contactId?: string;
}

const AccountPage: React.FC = (props) => {
	const { size } = useWindowDimensions();
	const snack = useSnackbar();
	const { data, loading, error, refetch } = useQuery(Account);

	const [contactVerification, setContactVerification] = useState<ContactVerificationState | null>(null);

	const formData = useMemo(() => data?.GetSelf, [data]);

	useEffect(() => {
		if(error) {
			snack.enqueueSnackbar("We ran into an issue loading your information", { variant: "error" });
		}
	}, [error]);

	const columnsOuter = useMemo(() => {
		switch(size) {
			case "small":
			case "medium": {
				return 1;
			}
			default: {
				return 2;
			}
		}
	}, [size]);

	const columnsInner = useMemo(() => {
		switch(size) {
			case "small":
			case "medium": {
				return 1;
			}
			default: {
				return 2;
			}
		}
	}, [size]);

	const emailContact = useMemo(() => formData?.contacts?.find(c => c.type === ContactType.Email), [formData]);
	const phoneContact = useMemo(() => formData?.contacts?.find(c => c.type === ContactType.Phone), [formData]);

	function handleStartContactCreateOrVerify(type: ContactType) {
		if(type === ContactType.Email && !emailContact?.isVerified) {
			setContactVerification({
				type,
				value: emailContact?.value,
				contactId: emailContact?.id,
			});
		}

		if(type === ContactType.Phone && !phoneContact?.isVerified) {
			setContactVerification({
				type,
				value: phoneContact?.value,
				contactId: phoneContact?.id,
			});
		}
	}

	return (
		<Fragment>
			{contactVerification && (
				<CreateContactModal
					userId={data?.GetSelf?.id ?? ""}
					value={contactVerification.value}
					contactId={contactVerification.contactId}
					type={contactVerification.type}
					onClose={() => {
						refetch();
						setContactVerification(null);
					}}
				/>
			)}
			<Box margin="medium">
				<Form value={formData}>
					<Grid columns={{ count: columnsOuter, size: "auto" }} gap="medium">
						<Box gap="medium">
							<Box gap="small">
								<Box border={{ side: "bottom", size: "small", color: "light-2" }}>
									<Heading level="3" margin="none">
										Personal Information
									</Heading>
								</Box>
								<Grid columns={{ count: columnsInner, size: "auto" }} gap="small">
									<FormField
										name="firstName"
										label="First Name"
									>
										<TextInput
											name="firstName"
											icon={loading ? <Spinner /> : undefined}
										/>
									</FormField>

									<FormField
										name="lastName"
										label="Last Name"
									>
										<TextInput
											name="lastName"
											icon={loading ? <Spinner /> : undefined}
										/>
									</FormField>
								</Grid>
								<Grid columns={{ count: columnsInner, size: "auto" }} gap="small">

									<FormField
										name="emailAddress"
										label="Email Address"
										help={!loading && <ContactVerifiedBadge
											verified={emailContact?.isVerified ?? false}
											onClick={() => handleStartContactCreateOrVerify(ContactType.Email)}
										/>}
									>
										<TextInput
											readOnly
											name="emailAddress"
											icon={loading ? <Spinner /> : undefined}
											value={formData?.emailAddress}
											onClick={() => handleStartContactCreateOrVerify(ContactType.Email)}
										/>
									</FormField>
									<FormField
										name="phoneNumber"
										label="Phone Number"
										help={!loading && <ContactVerifiedBadge
											verified={phoneContact?.isVerified ?? false}
											onClick={() => handleStartContactCreateOrVerify(ContactType.Email)}
										/>}
									>
										<MaskedInput
											readOnly
											name="phoneNumber"
											icon={loading ? <Spinner /> : <Phone />}
											required
											inputMode={"tel"}
											mask={[
												{ fixed: '1 (' },
												{
													length: 3,
													regexp: /^[0-9]{1,3}$/,
													placeholder: 'xxx',
												},
												{ fixed: ')' },
												{ fixed: ' ' },
												{
													length: 3,
													regexp: /^[0-9]{1,3}$/,
													placeholder: 'xxx',
												},
												{ fixed: '-' },
												{
													length: 4,
													regexp: /^[0-9]{1,4}$/,
													placeholder: 'xxxx',
												},
											]}
											onClick={() => handleStartContactCreateOrVerify(ContactType.Phone)}
										/>
									</FormField>
								</Grid>
							</Box>
							{formData?.currentResident && (
								<Box gap="small">
									<Box border={{ side: "bottom", size: "small", color: "light-2" }}>
										<Heading level="3" margin="none">
											Residence Information
										</Heading>
									</Box>
									<Grid columns={{ count: columnsInner, size: "auto" }} gap="small">
										<FormField
											name="residence"
											label="Building Name"
										>
											<TextInput
												name="residence"
												icon={loading ? <Spinner /> : undefined}
												value={formData?.currentResident?.residence?.name}
												readOnly
												disabled
											/>
										</FormField>
										<FormField
											name="unit"
											label="Unit / Apartment"
										>
											<TextInput
												name="unit"
												icon={loading ? <Spinner /> : undefined}
												value={formData?.currentResident?.verification?.unit}
												readOnly
												disabled
											/>
										</FormField>
									</Grid>
								</Box>
							)}
						</Box>
						<UserPaymentMethods

						/>
					</Grid>
				</Form>
			</Box>
		</Fragment>
	);
};

const ConnectedAccountPage = withAuth(AccountPage);
export { ConnectedAccountPage as AccountPage };

export const ContactVerifiedBadge: React.FC<{ verified: boolean; onClick?: () => void; }> = (props) => {
	return (
		<Box align="start" pad={{ left: "small" }}>
			<Button label={props.verified ? "Verified" : "Not Verified"} primary size="small" color={props.verified ? "status-ok" : "status-error"} onClick={props.onClick} />
		</Box>
	);
};