import { Box, Button, FormField, Grid, Heading, Image, Text, TextInput } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../app/store";
import { setRequestNoMargin } from "../../../../app/store/application";
import { formatCurrency, formatNumber, getNumberFormValidations } from "../../../../helpers";
import { DashboardCard, useWindowDimensions } from "../../../common";
import Co2Icon from '@mui/icons-material/Co2';
import FlightIcon from '@mui/icons-material/Flight';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ScaleIcon from '@mui/icons-material/Scale';
import FactoryIcon from '@mui/icons-material/Factory';

export const SavingsCalculator: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	const [units, setUnits] = useState<number>(0);
	const [zipCode, setZipCode] = useState("");

	const turnoverRate = .55;
	const hourlyCost = 71;
	const hoursPerUnit = 1;
	const poundsPerUnit = 330;
	const kgCo2ePerPound = 1.445;
	const donationRate = .90;
	const kgCo2ePerFlightHour = 250;

	useEffect(() => {
		dispatch(setRequestNoMargin(true));

		return () => {
			dispatch(setRequestNoMargin(false));
		};
	}, []);

	const timeSavings = useMemo(() => {
		if(units) {
			return units * turnoverRate * hoursPerUnit;
		}

		return 0;
	}, [units]);

	const costSavings = useMemo(() => {
		if(units) {
			return timeSavings * hourlyCost;
		}

		return 0;
	}, [timeSavings, units]);

	const weight = useMemo(() => {
		if(units) {
			return units * turnoverRate * poundsPerUnit;
		}

		return 0;
	}, [units]);

	const kgCo2e = useMemo(() => {
		return weight * donationRate * kgCo2ePerPound;
	}, [weight]);

	const weightComparison = useMemo(() => {
		if(weight >= 300000) {
			const compare = formatNumber(weight / 300000, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare}</Text> Blue Whale(s) worth of non recycled waste
				</Text>
			);
		}

		if(weight >= 12000) {
			const compare = formatNumber(weight / 12000, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare} T-Rex(s)</Text> worth of non recycled waste
				</Text>
			);
		}

		if(weight >= 5000) {
			const compare = formatNumber(weight / 5000, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare} Rhino(s)</Text> worth of non recycled waste
				</Text>
			);
		}

		if(weight >= 600) {
			const compare = formatNumber(weight / 600, true);
			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from <Text weight="bold">{compare} adult grizzly bear(s)</Text> worth of non recycled waste
				</Text>
			);
		}

		return (
			<Text>
				<Text weight="bold" style={{ textDecoration: "underline" }}>The Earth</Text> from (almost) an adult grizzly bear(s) worth of non recycled waste
			</Text>
		);
	}, [weight]);

	const emissionsSavings = useMemo(() => {
		if(kgCo2e) {
			const flightHours = kgCo2e / kgCo2ePerFlightHour;
			if(flightHours < 5) {
				const flightMinutes = flightHours * 60;
				return (
					<Text>
						<Text weight="bold" style={{ textDecoration: "underline" }}>The Air</Text> from <Text weight="bold">{formatNumber(flightMinutes, true)} minutes</Text> worth of flying in Kim K's private jet
					</Text>
				);
			}

			return (
				<Text>
					<Text weight="bold" style={{ textDecoration: "underline" }}>The Air</Text> from <Text weight="bold">{formatNumber(flightHours, true)} hours</Text> worth of flying in Kim K's private jet
				</Text>
			);
		}

		return 0;
	}, [kgCo2e]);

	const propertyManagerSaving = useMemo(() => {
		return (
			<Text>
				<Text weight="bold" style={{ textDecoration: "underline" }}>Yourself</Text> from <Text weight="bold">countless headaches</Text> caused by furniture removal
			</Text>
		);
	}, []);

	return (
		<Box>
			<Box width="full" background="black" height="medium" id="calc">
				<Box align="center">
					<Heading>Calculate Your Savings</Heading>
				</Box>
			</Box>
			<Box align="center">
				<Box
					round
					pad="large"
					width="large"
					elevation="medium"
					background="white"
					style={{ position: "relative", top: "-250px", maxWidth: "650px", width: "80%" }}
				>
					<Box gap="medium">
						<Box align="center">
							<Heading level="3" margin="none">Your savings with Rego over a year</Heading>
						</Box>
						<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap="small">
							<FormField
								name="zipCode"
								label="Zip Code"
							>
								<TextInput
									name="zipCode"
									value={zipCode}
									inputMode="numeric"
									onChange={(event) => {
										const value = event.target.value.replace(/[^0-9]/g, "");
										setZipCode(value.slice(0, 5));
									}}
									placeholder="Enter your zip code"
								/>
							</FormField>
							<FormField
								label="Number of Units"
								name="units"
								validate={[
									...getNumberFormValidations()
								]}
							>
								<TextInput
									name="units"
									value={units === 0 ? "" : units}
									inputMode="numeric"
									onChange={(event) => {
										const value = event.target.value.replace(/[^0-9]/g, "");
										setUnits(Number(value));
									}}
									placeholder="Enter the number of units"
								/>
							</FormField>
						</Grid>
						<Box gap="small">
							<Heading level="2" margin="none">Savings for Your Business</Heading>
							<Grid columns={{ count: 2, size: "auto" }} gap="small">
								<DashboardCard>
									<Box gap="medium">
										<Text size="large">Annual Cost Savings</Text>
										<Heading level="2" margin="none">{formatCurrency(costSavings)}</Heading>
									</Box>
								</DashboardCard>
								<DashboardCard>
									<Box gap="medium">
										<Text size="large">Annual Time Savings</Text>
										<Heading level="2" margin="none">{formatNumber(timeSavings, true)} hour(s)</Heading>
									</Box>
								</DashboardCard>
							</Grid>
							<Box align="center" justify="center" margin={{ top: "medium" }}>
								<Button
									primary
									color="accent-2"
									label="Schedule a Call"
									size="large"
									href="https://form.jotform.com/230777985883175"
									target="_blank"
									disabled={!zipCode || !units}
								/>
							</Box>
						</Box>
						<Box gap="small">
							<Heading level="2" margin="none">Environmental Impact</Heading>
							<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap="small">
								<DashboardCard>
									<Box gap="small" flex>
										<Box gap="small">
											<FactoryIcon fontSize="large" />
											<Text>Carbon Footprint Reduction</Text>
										</Box>
										<Box flex justify="end">
											<Heading margin="none" level="3">{formatNumber(kgCo2e, true)} kgCO<sub>2</sub>e</Heading>
										</Box>
									</Box>
								</DashboardCard>
								<DashboardCard>
									<Box gap="small" flex>
										<Box gap="small">
											<ScaleIcon fontSize="large" />
											<Text>Bulk Waste Diverted</Text>
										</Box>
										<Box flex justify="start">
											<Heading margin="none" level="3">{formatNumber(weight, true)} lb(s)</Heading>
										</Box>
									</Box>
								</DashboardCard>
							</Grid>
						</Box>
						{units > 0 && (
							<DashboardCard background="light-1">
								<Box gap="small">
									<Text weight="bold">You're saving:</Text>
									<DashboardCard background="white">
										<Grid columns={["auto", "flex", "auto"]} gap="small">
											<Box align="center" justify="center">
												<FlightIcon />
											</Box>
											<Box>
												<Text>{emissionsSavings}</Text>
											</Box>
											<Box align="center" justify="center">
												<Text weight="bold">{formatNumber(kgCo2e, true)} kg</Text>
												<Text size="small">CO<sub>2</sub> emissions</Text>
											</Box>
										</Grid>
									</DashboardCard>
									<DashboardCard background="white">
										<Grid columns={["auto", "flex", "auto"]} gap="small">
											<Box align="center" justify="center">
												<ScaleIcon />
											</Box>
											<Box>
												<Text>{weightComparison}</Text>
											</Box>
											<Box align="center" justify="center">
												<Text weight="bold">{formatNumber(weight, true)} lb(s)</Text>
												<Text size="small">of bulk waste</Text>
											</Box>
										</Grid>
									</DashboardCard>
									<DashboardCard background="white">
										<Grid columns={["auto", "flex", "auto"]} gap="small">
											<Box align="center" justify="center">
												<SentimentSatisfiedAltIcon />
											</Box>
											<Box>
												<Text>{propertyManagerSaving}</Text>
											</Box>
											<Box align="center" justify="center">
												<Text weight="bold">{formatNumber(timeSavings, true)} hour(s)</Text>
												<Text style={{ opacity: 0 }}>0</Text>
											</Box>
										</Grid>
									</DashboardCard>
								</Box>
							</DashboardCard>
						)}
						<Box direction="row" flex margin={{ top: "medium" }}>
							<Box>
								<Button
									primary
									color="accent-1"
									label="Reset"
									type="reset"
									onClick={() => {
										setUnits(0);
										setZipCode("");
									}}
								/>
							</Box>
							<Box justify="center" align="end" flex>
								<Button
									primary
									color="accent-2"
									label="Schedule a Call"
									size="large"
									href="https://form.jotform.com/230777985883175"
									target="_blank"
									disabled={!zipCode || !units}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};