import { Route, RouteProps, Routes } from "react-router";
import { ResidentDashboard, SchedulePickupPage } from "..";
import { CommonRoutes } from "../../common";
import { BuildingSearchPage, FAQPage } from "../../help";
import { BuildingLandingPage } from "../../landing";
import { OnboardingRouter } from "../../onboarding";

export const ResidentDashboardRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="" element={<ResidentDashboard />} />
			<Route path="schedule" element={<SchedulePickupPage />} />
		</Routes>
	);
};

export const ResidentRoutes: React.ReactElement<RouteProps>[] = [
	...CommonRoutes,
	<Route key="faq" path="/help/faq" element={<FAQPage />} />,
	<Route key="landing" path="/landing" element={<BuildingLandingPage />} />,
	<Route key="onboarding" path="/onboarding/*" element={<OnboardingRouter />} />,
	<Route key="dashboard" path="/dashboard/*" element={<ResidentDashboardRouter />} />,
	<Route key="building-search" path="/help/building-search" element={<BuildingSearchPage />} />
];

export const ResidentRouter: React.FC = (props) => {
	return (
		<Routes>
			{ResidentRoutes}
		</Routes>
	);
};