import { Anchor, Box, Button, Grid, Text } from "grommet";
import { RoundType } from "grommet/utils";
import React, { useState, ReactElement, useContext, Fragment, useEffect } from "react";

export const StepContext = React.createContext<[StepProviderState, (updates: Partial<StepProviderState>) => void]>([
	{ total: 0, current: 0 },
	() => { return; }
]);

interface StepProviderState {
	total: number;
	current: number;
}

export const StepProvider: React.FC = (props) => {
	const [state, setState] = useState<StepProviderState>({
		total: 0,
		current: 0
	});

	function updateState(updates: Partial<StepProviderState>): void {
		setState((old) => {
			return {
				...old,
				...updates
			};
		});
	}

	return (
		<StepContext.Provider value={[state, updateState]}>
			{props.children}
		</StepContext.Provider>
	);
};

export const useSteps = () => {
	const context = React.useContext(StepContext);
	if(!context) {
		throw new Error(`useSteps must be called from within a StepProvider`);
	}

	const [state, setState] = context;

	function hasPrev(): boolean {
		return state.current !== 0;
	}

	function hasNext(): boolean {
		return state.current < (state.total - 1);
	}

	function next(): void {
		if(hasNext()) {
			setState({
				...state,
				current: state.current + 1
			});
		}
	}

	function prev(): void {
		if(hasPrev()) {
			setState({
				...state,
				current: state.current - 1
			});
		}
	}

	return {
		total: state.total,
		current: state.current,
		isFirst: state.current === 0,
		isLast: state.current === Math.max((state.total - 1), 0),
		hasNext,
		hasPrev,
		next,
		prev,
		force: (index: number) => setState({ current: index, total: state.total })
	};
};

export interface StepProps {

}

export const Step: React.FC<StepProps> = (props) => {
	return (
		<Fragment>

		</Fragment>
	);
};


interface StepControllerProps {
	startFrom?: number;
	children: ReactElement<StepProps>[];
	onStepChange?: (index: number) => void;
}

export const StepController: React.FC<StepControllerProps> = (props) => {
	const context = useContext(StepContext);
	if(!context) {
		throw new Error("StepController must be within a StepProvider");
	}

	const { current, force, hasPrev, prev } = useSteps();

	useEffect(() => {
		context[1]({ current: 0, total: props.children.length });
	}, []);

	useEffect(() => {
		if(props.startFrom && props.startFrom < props.children.length) {
			context[1]({ current: props.startFrom, total: props.children.length });
		}
	}, [props.startFrom]);

	useEffect(() => {
		if(props.onStepChange) {
			props.onStepChange(context[0].current);
		}
	}, [context[0].current]);

	function getRoundType(index: number): RoundType {
		if(index === 0) {
			return { corner: "left" };
		}

		if(index === props.children.length - 1) {
			return { corner: "right" };
		}

		return false;
	}

	return (
		<StepContext.Consumer>
			{(context) => (
				<Box flex margin="small" gap="small">
					<Box margin="small" align="center" gap="small">
						<Grid columns={{ count: props.children.length, size: "auto" }} fill="horizontal" gap="xsmall">
							{props.children.map((step, index) => {
								return (
									<Box
										align="center"
										pad="xxsmall"
										round={getRoundType(index)}
										background={index <= current ? "accent-1" : undefined}
										onClick={index < current ? () => force(index) : undefined}
										border
										key={index}
									>
										<Text color={index <= current ? "white" : "black"}>Step {index + 1}</Text>
									</Box>
								);
							})}
						</Grid>
					</Box>
					{hasPrev() && (
						<Box align="start" margin="small">
							<Anchor
								label="Go Back"
								onClick={prev}
							/>
						</Box>
					)}
					{props.children[context[0].current]}
				</Box>
			)}
		</StepContext.Consumer>
	);

};