import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeController } from "../controller";
import { useMutation } from "@apollo/client";
import { CreatePaymentMethodIntent } from "../../../app/services/request/gql";
import { useEffect, useMemo, useState } from "react";
import { Loader, LoadingButton } from "../../common";
import { useSnackbar } from "notistack";
import { Box, Text } from "grommet";
import { useLocation } from "react-router";

export const AddPaymentMethodForm: React.FC = (props) => {
	const snack = useSnackbar();
	const location = useLocation();
	const [createPaymentMethodIntentMutation, { loading, data }] = useMutation(CreatePaymentMethodIntent);
	const clientSecret = useMemo(() => {
		return data?.CreatePaymentMethodIntent ?? null;
	}, [data]);

	useEffect(() => {
		createPaymentMethodIntentMutation().then(() => {

		}).catch(err => {
			console.error("Failed to create payment method intent", err);
			snack.enqueueSnackbar("We ran into an issue loading your information", { variant: "error" });
		});
	}, []);

	return (
		<Loader visible={loading || !data}>
			{clientSecret && (
				<StripeController clientSecret={clientSecret}>
					<Box margin="medium">
						<RegoPaymentElement
							returnUrl={`${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`}
						/>
					</Box>
				</StripeController>
			)}
		</Loader>
	);
};

interface RegoPaymentElementProps {
	returnUrl: string;
}

export const RegoPaymentElement: React.FC<RegoPaymentElementProps> = (props) => {
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		if(!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setLoading(true);
		const { error } = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: props.returnUrl
			},
		});


		if(error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			setErrorMessage(error.message ?? "");
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}

		setLoading(false);
	};

	return (
		<Box gap="medium">
			<Box gap="small">
				<PaymentElement />
				{errorMessage && (
					<Text weight="bold">
						{errorMessage}
					</Text>
				)}
			</Box>
			<LoadingButton
				type="submit"
				label="Add Payment Method"
				primary
				color="accent-1"
				isLoading={loading}
				onClick={handleSubmit}
			/>
		</Box>
	);
};