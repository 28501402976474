import { Box, Text } from "grommet";
import React from "react";
import "../../../css/loader.css";
import logo from "../../../img/rego_black_logo.png";

interface LoaderProps {
	visible: boolean;
}

export const Loader: React.FC<LoaderProps> = (props) => {
	if(props.visible) {
		return (
			<Box pad="large" margin="medium" align="center" justify="center">
				<img src={logo} className="loader" alt="Loading"></img>
				<div className="splash-screen">
					<div className="loading-dot">.</div>
				</div>
			</Box>
		);
	}

	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	);
};