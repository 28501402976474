import { useQuery, useLazyQuery } from "@apollo/react-hoc";
import { Page, PageContent, Box, Heading, Grid } from "grommet";
import { useEffect, useMemo } from "react";
import { GetUserDashboardData } from "../../../../app/services/request/admin";
import { GetDashboardData } from "../../../../app/services/request/gql";
import { useAppSelector } from "../../../../app/store";
import { selectUser } from "../../../../app/store/application";
import { withAuth } from "../../../auth";
import { useWindowDimensions, Loader } from "../../../common";
import { ResidenceDetailsCard, ResidenceScheduledPickupCard } from "../../common/components";
import { PickupCardContainer, ResidentAdminControls } from "../components";
import moment from "moment";

const ResidentDashboard: React.FC = (props) => {
	const user = useAppSelector(selectUser);
	const impersonateUser = useAppSelector(state => state.admin.impersonateUserId);
	const { loading, data, refetch } = useQuery(GetDashboardData);
	const [executeImpersonate, { data: impersonateData, loading: impersonateLoading }] = useLazyQuery(GetUserDashboardData);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if(impersonateUser) {
			executeImpersonate({
				variables: {
					userId: impersonateUser
				}
			});
		}
	}, [impersonateUser]);

	const isLoading = useMemo(() => impersonateLoading || loading, [impersonateLoading, loading]);

	const dashboardData = useMemo(() => {
		if(impersonateData?.GetUser) {
			return impersonateData?.GetUser;
		}

		return data?.GetSelf;
	}, [data, impersonateData]);

	const dimensions = useWindowDimensions();
	const columns = useMemo(() => {
		return ["small", "medium"].includes(dimensions.size)
			? 1
			: 2;
	}, [dimensions.size]);

	const completedServices = useMemo(() => {
		return (dashboardData?.services?.filter(s => s.completed) ?? []).sort((a, b) => {
			return moment(new Date(a.pickup?.scheduledDate ?? "")).unix() - moment(new Date(b.pickup?.scheduledDate ?? "")).unix();
		});
	}, [dashboardData]);

	const pendingServices = useMemo(() => {
		return (dashboardData?.services?.filter(s => !s.completed) ?? []).sort((a, b) => {
			return moment(new Date(a.pickup?.scheduledDate ?? "")).unix() - moment(new Date(b.pickup?.scheduledDate ?? "")).unix();
		});
	}, [dashboardData]);

	if(!user) {
		return <Loader visible={!user} />;
	}

	return (
		<Page>
			<PageContent>
				<Box gap="small" margin="medium">
					<ResidentAdminControls
						isLoading={false}
					/>
					<Heading level="2">Welcome {user.firstName}</Heading>
					<Grid columns={{ count: columns, size: "auto" }} gap="medium">
						<ResidenceDetailsCard
							isLoading={isLoading}
							unitNumber={dashboardData?.currentResident?.verification.unit ?? ""}
							residenceName={dashboardData?.currentResident?.residence.name ?? ""}
							logoUrl={dashboardData?.currentResident?.residence.customizations.logoUrl ?? ""}
							hideLogo={dashboardData?.currentResident?.residence.customizations.hideLogo ?? false}
						/>
						<ResidenceScheduledPickupCard
							isLoading={loading}
							nextPickup={(dashboardData?.currentResident?.residence.nextScheduledPickup?.scheduledDate)
								? {
									date: dashboardData?.currentResident?.residence.nextScheduledPickup?.scheduledDate ?? "",
									timezone: dashboardData?.currentResident?.residence.nextScheduledPickup?.scheduledWindowTimezone ?? "",
									from: dashboardData?.currentResident?.residence.nextScheduledPickup?.scheduledWindowFrom ?? 0,
									to: dashboardData?.currentResident?.residence.nextScheduledPickup?.scheduledWindowTo ?? 0
								}
								: null
							}
							scheduledPickups={(dashboardData?.currentResident?.residence.upcomingPickups ?? []).map(pickup => {
								return {
									id: pickup.id,
									scheduledWindow: {
										to: pickup.scheduledWindowTo ?? 0,
										from: pickup.scheduledWindowFrom ?? 0,
										date: pickup.scheduledDate ?? "",
										timezone: pickup.scheduledWindowTimezone ?? ""
									}
								};
							})}
						/>
					</Grid>
					<PickupCardContainer
						isLoading={isLoading}
						cardTitle="Your Upcoming Pickups"
						noRecordsMessage="Nothing scheduled yet"
						services={pendingServices}
					/>
					<PickupCardContainer
						isLoading={isLoading}
						cardTitle="Your Completed Pickups"
						noRecordsMessage="Nothing completed yet"
						services={completedServices}
					/>
				</Box>
			</PageContent>
		</Page>
	);
};

const ConnectedResidentDashboard = withAuth(ResidentDashboard);
export { ConnectedResidentDashboard as ResidentDashboard };