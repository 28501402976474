import { gql } from "../../../../__generated__";

export const ListPartners = gql(/* GraphQL */ `
	query ListPartners($type: String, $marketCode: String) {
		ListPartners(type: $type, marketCode: $marketCode) {
			... on Hauler {
				id
				name
				type
			}
			... on DonationCenter {
				id
				name
				type
			}
			... on DisposalCenter {
				id
				name
				type
			}	
		}
	}
`);

export const ListResidents = gql(/* GraphQL */ `
	query ListResidents {
		ListResidents {
			id
			user {
				id
				firstName
				lastName
				emailAddress
			}
			residence {
				id
				name
			}
			isCurrentResident
		}
	}
`);

export const ListMarkets = gql(/* GraphQL */ `
	query ListMarkets {
		ListMarkets {
			id
			name
			code
		}
	}
`);

export const ListResidencesByMarket = gql(/* GraphQL */ `
	query ListResidences($marketCode: String!) {
		ListResidences(marketCode: $marketCode) {
			id
			name
			address {
				addressLineOne
				addressLineTwo
				city
				state
				zip
			}
		}
	}
`);

export const GetUserDashboardData = gql(/* GraphQL */ `
	query GetUser($userId: String!) {
		GetUser(userId: $userId) {
			id
			firstName
			lastName
			contacts {
				type
				value
				isPrimary
				isVerified
			}
			services {
				id
				paid
				paidAt
				scheduled
				completed
				completedAt
				pickup {
					id
					scheduled
					scheduledDate
					scheduledWindowFrom
					scheduledWindowTo
					scheduledWindowTimezone
				}
				selectedEstimate {
					taxAmount
					totalAmount
					subtotalAmount
				}
				productCount
			}
			currentResident {
				verification {
					unit
				}
				residence {
					name
					customizations {
						logoUrl
						hideLogo
						customLandingPageMessage
					}
					nextScheduledPickup {
						id
						scheduledDate
						scheduledWindowFrom
						scheduledWindowTo
						scheduledWindowTimezone
					}
					upcomingPickups {
						id
						scheduledDate
						scheduledWindowFrom
						scheduledWindowTo
						scheduledWindowTimezone
					}
				}
			}
		}
	}
`);