import { Chip } from "@mui/material";
import { Box, Text } from "grommet";

interface RouteCardProps {
	stopsFrom: number;
	stopTo: number;
	quoted: boolean;
	invoiced: boolean;
	onClick(): void;
}

export const RouteCard: React.FC<RouteCardProps> = (props) => {
	return (
		<Box
			round
			pad="medium"
			hoverIndicator
			background="light-2"
			onClick={props.onClick}
		>
			<Box direction="row" gap="small">
				<Box align="center">
					<Text weight="bold">Stops</Text>
					{props.stopsFrom} - {props.stopTo}
				</Box>
				<Box flex align="center" justify="end" gap="small" direction="row">
					{props.quoted && (
						<Chip
							color="primary"
							label="Quoted"
						/>
					)}
					{props.invoiced && (
						<Chip
							color="success"
							label="Awarded"
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};