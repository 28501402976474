import { useMutation } from "@apollo/client";
import { Form, Box, Heading, FormField, TextInput, Anchor, Text } from "grommet";
import { useState, useMemo, useEffect } from "react";
import { CreateContact, BeginContactVerification, VerifyContact } from "../../../app/services/request/gql";
import { useAppSelector } from "../../../app/store";
import { selectUser } from "../../../app/store/application";
import { getStandardFormValidations } from "../../../helpers";
import { LoadingButton, Modal } from "../../common";
import { ContactType } from "../../../__generated__/graphql";

interface CreateOrVerifyContactModalProps {
	userId: string;
	contactId?: string;
	type: ContactType;
	value?: string;
	onClose(wasCreated: boolean, wasVerified: boolean): void;
}

export const CreateContactModal: React.FC<CreateOrVerifyContactModalProps> = (props) => {
	const user = useAppSelector(selectUser);
	const [contactId, setContactId] = useState(props.contactId ?? "");
	const [wasCodeSent, setWasCodeSent] = useState(false);
	const [canSendAnotherCode, setCanSendAnotherCode] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [code, setCode] = useState("");
	const [email, setEmail] = useState(props.value ?? "");

	const [createContactMutation, { loading: isCreating }] = useMutation(CreateContact);
	const [beginContactVerificationMutation, { loading: isSending }] = useMutation(BeginContactVerification);
	const [verifyContactMutation, { loading: isVerifying }] = useMutation(VerifyContact);

	const isLoading = useMemo(() => {
		return isSending || isVerifying || isCreating;
	}, [isSending, isVerifying, isCreating]);

	function handleCreateContact(): void {
		if(!email || !user) return;
		createContactMutation({
			variables: {
				userId: user.id,
				isPrimary: true,
				type: ContactType.Email,
				value: email,
			},
		}).then((res) => {
			setErrorMessage("");
			setContactId(res.data?.CreateContact?.id || "");
		}).catch(err => {
			console.error("Failed to create contact");
			setErrorMessage("We ran into an issue saving your information");
		});
	}

	useEffect(() => {
		if(contactId) {
			handleSendCode();
		}
	}, [contactId]);

	function handleSendCode(): void {
		if(!email || !user || !contactId) return;
		setErrorMessage("");
		beginContactVerificationMutation({
			variables: {
				contactId,
				userId: user.id
			},
		}).then(() => {
			setWasCodeSent(true);
		}).catch(err => {
			console.error("Failed to send code", err);
			setCanSendAnotherCode(true);
			setErrorMessage("We ran into an issue sending a verification code");
		});
	}

	function handleVerifyCode(): void {
		if(!email || !user || !contactId || !code) return;

		verifyContactMutation({
			variables: {
				code,
				contactId,
				userId: user.id
			}
		}).then(() => {
			props.onClose(true, true);
		}).catch(err => {
			console.error("Failed to verify code", err);
			setErrorMessage("That code doesn't look right. Please try again.");
			setCanSendAnotherCode(true);
		});
	}

	function handleSubmit(): void {
		if(!email || !user) return;

		if(!contactId) {
			handleCreateContact();
			return;
		}

		if(!wasCodeSent) {
			handleSendCode();
			return;
		}

		handleVerifyCode();
	}

	return (
		<Modal
			onCloseAll={() => props.onClose(false, false)}
		>
			<Form
				value={{ code, email }}
				onChange={(changes) => {
					setCode(changes.code);
					setEmail(changes.email);
				}}
				validate="submit"
				onSubmit={handleSubmit}
			>
				<Box pad="small" width="medium">
					<Box align="center" margin="small">
						<Heading level="2" margin="none">
							{props.contactId ? "Verify Contact" : "Create Contact"}
						</Heading>
					</Box>

					<Box gap="medium" margin="small">
						<Box gap="small">
							<FormField
								name="email"
								label="Email Address"
								validate={[...getStandardFormValidations()]}
							>
								<TextInput
									name="email"
								/>
							</FormField>
							{wasCodeSent && (
								<FormField
									name="code"
									label="Verification Code"
									validate={[...getStandardFormValidations()]}
								>
									<TextInput
										name="code"
									/>
								</FormField>
							)}
							{wasCodeSent && (
								<Text>
									We just sent a code to {email}. Please enter it above to verify your email address.
								</Text>
							)}
							{errorMessage && (
								<Text color="status-error">
									{errorMessage}
								</Text>
							)}
							{(wasCodeSent && canSendAnotherCode) && (
								<Anchor
									label="Need another code"
									onClick={() => handleSendCode()}
								/>
							)}
						</Box>
						<Box align="end" justify="end">
							<LoadingButton
								isLoading={isLoading}
								primary
								color="accent-1"
								label="Submit"
								type="submit"
							/>
						</Box>
					</Box>
				</Box>
			</Form>
		</Modal>
	);
};