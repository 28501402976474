import { useEffect, useState } from "react";
import { useGoogle } from "../provider";
import { GeoLocation, States } from "@rego-app/schema";

let autocomplete: google.maps.places.Autocomplete;

export const useAddressAutocomplete = () => {
	const googleHook = useGoogle();

	const [ready, setReady] = useState(false);

	useEffect(() => {
		if(googleHook.isLoaded) {
			setReady(true);
		}
	}, [googleHook]);

	function parseAddress(callback: (address: GeoLocation) => void): void {
		const place = autocomplete.getPlace();

		const address = {
			addressLineOne: "",
			addressLineTwo: "",
			city: "",
			state: "",
			zip: "",
			placeId: "",
			placeName: "",
			latitude: place.geometry?.location?.lat() ?? 0,
			longitude: place.geometry?.location?.lng() ?? 0
		} as GeoLocation;

		for(const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
			const componentType = component.types[0];

			switch(componentType) {
				case "street_number": {
					address.addressLineOne = component.long_name;
					break;
				}

				case "route": {
					address.addressLineOne += ` ${component.long_name}`;
					break;
				}

				case "postal_code": {
					address.zip = component.long_name;
					break;
				}

				case "locality":
					address.city = component.long_name;
					break;

				case "administrative_area_level_1": {
					address.state = component.short_name as States.Abbreviation;
					break;
				}
			}
		}

		callback(address);
	}

	function initialize(inputElement: HTMLInputElement, callback: (address: GeoLocation) => void): void {
		autocomplete = new google.maps.places.Autocomplete(inputElement, {
			componentRestrictions: {
				country: ["us"]
			},
			bounds: googleHook.currentLocation ? new google.maps.LatLngBounds(googleHook.currentLocation) : undefined,
			fields: ["address_components", "geometry"],
			types: ["address"]
		});
		autocomplete.addListener("place_changed", () => {
			parseAddress(callback);
		});
	}

	return {
		ready,
		initialize,
		autocomplete
	};
};