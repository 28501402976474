import React, { FC, useMemo } from "react";
import { StripeProvider, useRegoStripe } from "../provider";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Loader } from "../../common";

export interface StripeControllerProps {
	clientSecret: string;
}

const StripeController: React.FC<StripeControllerProps> = (props) => {
	const stripe = useRegoStripe();

	const stripeOptions: StripeElementsOptions = useMemo(() => {
		return {
			loader: "always",
			clientSecret: props.clientSecret,
			appearance: {

			}
		};
	}, [props.clientSecret]);

	return (
		<StripeProvider>
			<Elements stripe={stripe} options={stripeOptions}>
				{props.children}
			</Elements>
		</StripeProvider>
	);
};

export function withStripe<T>(Component: FC<T>) {
	const Stripe = (props: any) => {
		return (
			<StripeProvider>
				<Component {...props} />
			</StripeProvider>
		);

	};

	return Stripe;
};

const wrappedStripeController = withStripe(StripeController);
export { wrappedStripeController as StripeController };