import { Box, Pagination as GrommetPagination, Text } from "grommet";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useScreenSize } from "../hooks";

export interface PaginatedItem {
	id: string;
}

interface PaginationProps {
	items: PaginatedItem[];
	pageSize?: number;
	renderItems(items: PaginatedItem[]): JSX.Element;
	onChange?: (pageSize: number, currentPage: number, totalPages: number) => void;
}

export const Pagination: React.FC<PaginationProps> = (props) => {

	const size = useScreenSize();
	const [pageSize, setPageSize] = useState(props.pageSize ?? 10);
	const [indices, setIndices] = useState([0, pageSize]);
	const [displayed, setDisplayed] = useState<PaginatedItem[]>([]);

	useEffect(() => {
		setIndices([
			0,
			Math.min(props.items.length, pageSize)
		]);

		return () => {
			setIndices([
				0,
				Math.min(props.items.length, pageSize)
			]);
		};
	}, []);

	useEffect(() => {
		//reset indices when items changes
		setIndices([
			0,
			Math.min(props.items.length, pageSize)
		]);
	}, [props.items]);

	const getDisplayText = useCallback(() => {
		if(displayed.length <= 0) {
			return `Showing 0 of 0`;
		}

		return `Showing ${indices[0] + 1} - ${indices[1]} of ${props.items.length}`;
	}, [displayed, props.items, indices]);

	useEffect(() => {
		setDisplayed(props.items.slice(indices[0], indices[1]));
	}, [props.items, indices]);

	const handleChange = ({ startIndex, endIndex }: { startIndex: number, endIndex: number; }) => {
		setIndices([startIndex, Math.min(endIndex, props.items.length)]);

		if(props.onChange) {
			props.onChange(
				pageSize,
				1,
				Math.ceil(props.items.length / pageSize)
			);
		}
	};

	const items = useMemo(() => {
		return props.renderItems(displayed);
	}, [props.items, props.renderItems, props.pageSize, pageSize, indices, displayed]);

	return (
		<Box gap="small" flex id="pagination-container">
			<Box>
				{items}
			</Box>
			<Box flex="grow" justify="end" margin={{ top: "small" }}>
				<Box align="center" justify={size === "small" ? "center" : "between"} direction="row">
					{size !== "small" && (
						<Box>
							<Text>
								{getDisplayText()}
							</Text>
						</Box>
					)}
					<GrommetPagination
						numberItems={props.items.length}
						step={pageSize}
						onChange={handleChange}
					/>
				</Box>
			</Box>
		</Box>
	);
};