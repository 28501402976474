import { ThemeType } from "grommet";

export const theme: ThemeType = {
	global: {
		backgrounds: {
			default: {
				color: "#white"
			}
		},
		font: {
			family: "League Spartan",
			size: "20px",
			height: "20px",
		},
		colors: {
			brand: {
				//dark: "#ebebeb",
				dark: "#f2f2f2",
				light: "#21211f"
			},
			"text": "brand",
			"accent-1": "#e16259",
			"accent-2": "#71e390",
			"accent-3": "#63635c",
			"dark-1": "#21211f",
			"light-1": "#f2f2f2",
			"light-2": "#ebebeb",
			//"light-2": "#dedede"
		},
		focus: {
			border: {
				color: "accent-1"
			}
		}
	},
	button: {
		color: {
			dark: "white",
			light: "white"
		},
		border: {
			radius: "8px"
		},
		size: {
			small: {
				border: {
					radius: "8px"
				}
			},
			medium: {
				border: {
					radius: "8px"
				}
			},
			large: {
				border: {
					radius: "8px"
				}
			}
		}
	},
	heading: {
		color: "brand"
	},
	text: {

	},
	formField: {
		label: {
			weight: "bold"
		}
	}
};

export default theme;