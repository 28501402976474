import { useMutation } from "@apollo/react-hoc";
import { Box, Text } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { push } from "redux-first-history";
import { BeginCheckoutSession } from "../../../../app/services/request/gql";
import { useAppDispatch } from "../../../../app/store";
import { LoadingButton, useGoogleTagManager } from "../../../common";

export const useBeginScheduleFlow = () => {
	const gtm = useGoogleTagManager();
	const [pickupId, setPickupId] = useState("");
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [beginCheckoutSessionMutation, { loading, data, error }] = useMutation(BeginCheckoutSession);

	useEffect(() => {
		if(data) {
			gtm.push("begin_checkout", { sessionId: data?.BeginCheckoutSession.id ?? "" });
			dispatch(push(`/dashboard/schedule?sid=${data?.BeginCheckoutSession.id}&phone=${data?.BeginCheckoutSession.phoneNumber}&pickupId=${pickupId}`));
		}
	}, [data]);

	useEffect(() => {
		if(error) {
			console.error("Failed to begin checkout session", error);
			snack.enqueueSnackbar("We ran into an issue getting your pickup started", { variant: "error" });
		}
	}, [error]);

	return {
		loading,
		begin: (pickupId?: string) => {
			setPickupId(pickupId ?? "");
			beginCheckoutSessionMutation();
		}
	};
};

interface SchedulePickupButtonProps {
	pickupId?: string;
}

export const SchedulePickupButton: React.FC<SchedulePickupButtonProps> = (props) => {
	const { loading, begin } = useBeginScheduleFlow();

	return (
		<Box gap="xsmall" align="center">
			<LoadingButton
				primary
				size="large"
				color="accent-1"
				label="Get Started"
				isLoading={loading}
				onClick={() => begin(props.pickupId)}
			/>
			<Text>
				express pickups available
			</Text>
		</Box>
	);
};