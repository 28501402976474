import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid, Button, Spinner, Text, Heading, Anchor } from "grommet";
import { Amex, Mastercard, Visa, CreditCard, FormClose, FormAdd } from "grommet-icons";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { DeletePaymentMethod, GetPaymentMethodsSelf } from "../../../app/services/request/gql";
import { toProperCase } from "../../../helpers";
import { WrapSkeleton } from "../../common";
import { useWindowDimensions } from "../../common/hooks";
import { AddPaymentMethodModal } from "../../payments";

interface PaymentMethodCardProps {
	brand: string;
	expirationMonth: number;
	expirationYear: number;
	lastFour: string | number;
	onClick?: () => void;
	onDelete?: () => void;
	isSelected?: boolean;
	isDeleting?: boolean;
	canDelete: boolean;
}

export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = (props) => {
	const { size } = useWindowDimensions();

	const icon = useMemo(() => {
		switch(props.brand) {
			case "amex": {
				return <Amex size="large" />;
			}
			case "mastercard": {
				return <Mastercard size="large" />;
			}
			case "visa": {
				return <Visa size="large" />;
			}
			default: {
				return <CreditCard size="large" />;
			}
		}
	}, [props.brand]);

	const brandName = useMemo(() => {
		return toProperCase(props.brand);
	}, [props.brand]);

	return (
		<Box
			round
			pad="small"
			background="white"
			elevation="medium"
			hoverIndicator
			border={props.isSelected ? { color: "accent-1" } : undefined}
			onClick={props.onClick ? props.onClick : undefined}
			style={{
				boxShadow: props.isSelected ? "0 0 2px 2px #e16259" : undefined
			}}
		>
			<Grid columns={["auto", "auto", "flex", "auto", "auto"]} gap="small">
				<Box>
					{icon}
				</Box>
				<Box justify="center">
					<Box direction="row" gap="small" align="center">
						<Text>{brandName}</Text>
					</Box>
					{size === "small" && (
						<Box align="start" direction="row">
							<Text size="small">Expires {props.expirationMonth}/{props.expirationYear}</Text>
						</Box>
					)}
				</Box>
				<Box direction="row" gap="small" align="center" justify="end">
					<Text style={{ letterSpacing: size === "small" ? "2px" : "3px" }}>.... {props.lastFour}</Text>
				</Box>
				{size !== "small" && (
					<Box align="center" direction="row">
						<Text size="small">Exp. {props.expirationMonth}/{props.expirationYear}</Text>
					</Box>
				)}
				<Box align="center" justify="center">
					{!!props.onDelete && (
						<Button
							icon={props.isDeleting
								? <Spinner color="accent-1" />
								: <FormClose color="status-error" />
							}
							disabled={props.isDeleting || !props.canDelete}
							hoverIndicator
							onClick={props.onDelete}
						/>
					)}
				</Box>
			</Grid>
		</Box>
	);
};

export const UserPaymentMethods: React.FC = (props) => {
	const snack = useSnackbar();
	const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
	const { data, loading, error, refetch } = useQuery(GetPaymentMethodsSelf);
	const [deletePaymentMethodMutation, { loading: deletingPaymentMethod }] = useMutation(DeletePaymentMethod);

	function handleDeletePaymentMethod(id: string): void {
		deletePaymentMethodMutation({ variables: { paymentMethodId: id } }).then(() => {
			refetch();
		}).catch(err => {
			console.error("Failed to delete payment method", err);
			snack.enqueueSnackbar("We ran into an issue removing your payment method", { variant: "error" });
		});
	}

	useEffect(() => {
		if(error) {
			console.error("Failed to load payment methods", error);
			snack.enqueueSnackbar("We ran into an issue loading your information", { variant: "error" });
		}
	}, [error]);

	return (
		<Box gap="small">
			{isAddingPaymentMethod && (
				<AddPaymentMethodModal
					onClose={() => setIsAddingPaymentMethod(false)}
				/>
			)}
			<Box border={{ side: "bottom", size: "small", color: "light-2" }}>
				<Heading level="3" margin="none">
					Payment Methods
				</Heading>
			</Box>
			<WrapSkeleton isLoading={loading}>
				<Box gap="small">
					{(data?.GetPaymentMethodsSelf ?? []).map(method => (
						<PaymentMethodCard
							key={method.id}
							brand={method.brand}
							lastFour={method.lastFour}
							canDelete={method.canDelete}
							expirationMonth={method.expirationMonth}
							expirationYear={method.expirationYear}
							isDeleting={deletingPaymentMethod}
							onDelete={() => handleDeletePaymentMethod(method.id)}
						/>
					))}
					{(data?.GetPaymentMethodsSelf && data.GetPaymentMethodsSelf.length === 0) && (
						<Box align="center">
							<Text>no payment methods added</Text>
						</Box>
					)}
				</Box>
				<Box align="start" margin="medium" justify="center">
					<Anchor
						icon={<FormAdd />}
						label="Add Payment Method"
						color="accent-1"
						onClick={() => setIsAddingPaymentMethod(true)}
					/>
				</Box>
			</WrapSkeleton>
		</Box>
	);
};

export * from "./contact";