import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationInstance } from "../../types";
import { AuthService } from "../services/auth";
import { RootState } from "./index";
import { DataValue } from "@apollo/react-hoc";
import { GetSelfQuery, UserRole } from "../../__generated__/graphql";

type UserQueryType = NonNullable<DataValue<GetSelfQuery>["GetSelf"]>;


export interface ApplicationState {
	isLoggedIn: boolean;
	isLoaded: boolean;
	user: UserQueryType | null;
	requiresLogin: boolean;
	requiresRegistration: boolean;
	hasFatalError: boolean;
	fatalErrorMessage: string;
	forceHideFooter: boolean;
	forceHideHeader: boolean;
	isLoginFlow: boolean;
	loginCodeSent: boolean;
	loginErrorMessage: string;
	instance: ApplicationInstance;
	requestNoMargin: boolean;
}

const initialState: ApplicationState = {
	isLoaded: false,
	isLoggedIn: false,
	user: null,
	requiresLogin: false,
	requiresRegistration: false,
	hasFatalError: false,
	fatalErrorMessage: "",
	forceHideFooter: false,
	forceHideHeader: false,
	isLoginFlow: false,
	loginCodeSent: false,
	loginErrorMessage: "",
	instance: "PENDING",
	requestNoMargin: false
};

export const applicationSlice = createSlice({
	name: "application",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setInstance: (state, action: PayloadAction<ApplicationInstance>) => {
			state.instance = action.payload;
		},
		setRequiresLogin: (state, action: PayloadAction<boolean>) => {
			state.requiresLogin = action.payload;
		},
		setRequiresRegistration: (state, action: PayloadAction<boolean>) => {
			state.requiresRegistration = action.payload;
		},
		setFatalError: (state, action: PayloadAction<string>) => {
			state.hasFatalError = true;
			state.fatalErrorMessage = action.payload;
		},
		setForceHideFooter: (state, action: PayloadAction<boolean>) => {
			state.forceHideFooter = action.payload;
		},
		setForceHideHeader: (state, action: PayloadAction<boolean>) => {
			state.forceHideHeader = action.payload;
		},
		setIsLoginFlow: (state, action: PayloadAction<boolean>) => {
			state.isLoginFlow = action.payload;
		},
		setLoginCodeSent: (state, action: PayloadAction<boolean>) => {
			state.loginCodeSent = action.payload;
		},
		setLoginErrorMessage: (state, action: PayloadAction<string>) => {
			state.loginErrorMessage = action.payload;
		},
		setUserLogin: (state, action: PayloadAction<UserQueryType>) => {
			state.user = action.payload;
			AuthService.setUserId(state.user.id);
			state.isLoggedIn = true;
			state.requiresLogin = false;
		},
		setLoginResponse: (state, action: PayloadAction<{ accessToken: string, refreshToken: string, username: string; }>) => {
			const { accessToken, refreshToken, username } = action.payload;
			AuthService.setUsername(username);
			AuthService.setAccessToken(accessToken);
			AuthService.setRefreshToken(refreshToken);
		},
		setRequestNoMargin: (state, action: PayloadAction<boolean>) => {
			state.requestNoMargin = action.payload;
		}
	}
});

export const {
	setInstance,
	setUserLogin,
	setLoginResponse,
	setFatalError,
	setRequiresLogin,
	setRequiresRegistration,
	setForceHideFooter,
	setForceHideHeader,
	setIsLoginFlow,
	setLoginCodeSent,
	setLoginErrorMessage,
	setRequestNoMargin
} = applicationSlice.actions;

export const selectInstance = (state: RootState): ApplicationInstance => state.application.instance;
export const selectIsLoaded = (state: RootState): boolean => state.application.isLoaded;
export const selectRequiresLogin = (state: RootState): boolean => state.application.requiresLogin;
export const selectRequiresRegistration = (state: RootState): boolean => state.application.requiresRegistration;
export const selectFatalError = (state: RootState): { hasFatalError: boolean, fatalErrorMessage: string; } => {
	return {
		fatalErrorMessage: state.application.fatalErrorMessage,
		hasFatalError: state.application.hasFatalError
	};
};

export const selectForceHideFooter = (state: RootState): boolean => state.application.forceHideFooter;
export const selectForceHideHeader = (state: RootState): boolean => state.application.forceHideHeader;

export const selectUser = (state: RootState): UserQueryType | null => state.application.user;
export const selectIsAdmin = (state: RootState): boolean => state.application.user?.role === UserRole.Admin || false;
export const selectIsLoggedIn = (state: RootState): boolean => state.application.isLoggedIn;
export const selectIsLoginFlow = (state: RootState): boolean => state.application.isLoginFlow;
export const selectLoginCodeSent = (state: RootState): boolean => state.application.loginCodeSent;
export const selectRequestNoMargin = (state: RootState): boolean => state.application.requestNoMargin;

export default applicationSlice.reducer;