import { gql } from "../../../../__generated__/gql";

export const GetHauler = gql(/* GraphQL */ `
	query GetHauler($haulerId: String!) {
		GetHauler(haulerId: $haulerId) {
			id
			name
			vehicles {
				id
				type
				name
			}
		}
	}
`);

export const GetHaulerAvailablePickups = gql(/* GraphQL */`
	query GetAvailablePickups($haulerId: String!){
		GetAvailablePickups(haulerId: $haulerId) {
			id
			scheduled
			scheduledDate
			scheduledWindowFrom
			scheduledWindowTo
			scheduledWindowTimezone
			productCount
			quotes {
				id
				amount
				disposalFee
				estimatedHours
				converted
			}
		}
	}
`);

export const GetHaulerAwardedPickups = gql(/* GraphQL */`
	query GetAwardedPickups($haulerId: String!){
		GetAwardedPickups(haulerId: $haulerId) {
			id
			scheduled
			scheduledDate
			scheduledWindowFrom
			scheduledWindowTo
			scheduledWindowTimezone
			productCount
			quotes {
				id
				amount
				disposalFee
				estimatedHours
				converted
			}
			invoice {
				id
				amount
				disposalFee
				totalAmount
				paid
			}
		}
	}
`);

export const AssignVehicleToHauler = gql(/* GraphQL */ `
	mutation AssignVehicleToHauler($haulerId: String!, $vehicleId: String!) {
		AssignVehicleToHauler(haulerId: $haulerId, vehicleId: $vehicleId) {
			id
		}
	}
`);

export const RemoveVehicleFromHauler = gql(/* GraphQL */ `
	mutation RemoveVehicleFromHauler($haulerId: String!, $vehicleId: String!) {
		RemoveVehicleFromHauler(haulerId: $haulerId, vehicleId: $vehicleId) {
			id
		}
	}
`);