import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ListVehicles } from "../../../app/services/request";
import { VehicleType } from "../../../__generated__/graphql";

export function useVehicles() {
	const { data, loading, error } = useQuery(ListVehicles);

	useEffect(() => {
		if(data?.ListVehicles) {
			setVehicles(data.ListVehicles.map(vehicle => {
				return {
					id: vehicle.id,
					name: vehicle.name,
					type: vehicle.type
				};
			}));
		}
	}, [data]);

	const [vehicles, setVehicles] = useState<{ id: string, type: VehicleType, name: string; }[]>([]);

	return {
		loading,
		vehicles
	};
}