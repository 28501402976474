import { gql } from "../../../../__generated__/gql";

export const GetService = gql(/* GraphQL */`
	query BuildingDashboardGetService($serviceId: String!) {
		GetService(serviceId: $serviceId) {
			user {
				firstName
				lastName
				emailAddress
				phoneNumber
				currentResident {
					verification {
						unit
					}
					residence {
						id
						name
					}
				}
			}
			productCount
			pickup {
				id
				scheduled
				scheduledDate
				scheduledWindowFrom
				scheduledWindowTo
				scheduledWindowTimezone
			}
		}
	}
`);