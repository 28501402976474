import { useEffect } from "react";
import { Route, RouteProps } from "react-router";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../app/store";
import { AccountPage, LoginPage } from "../auth";
import { HomePage } from "./pages";

export const RedirectToRootOn404 = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(push("/"));
	}, []);

	return (<></>);
};

export const CommonRoutes: React.ReactElement<RouteProps>[] = [
	<Route key="home" path="/" element={<HomePage />} />,
	<Route key="login" path="/login" element={<LoginPage />} />,
	<Route key="account" path="/account" element={<AccountPage />} />,
	<Route key="404" path="*" element={<RedirectToRootOn404 />} />
];