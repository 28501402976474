import { Box, CheckBox, Heading, Page, PageContent, Text } from "grommet";
import { useState } from "react";
import { useAppSelector } from "../../../../app/store";
import { selectUser } from "../../../../app/store/application";
import { withAuth } from "../../../auth";
import { useWindowDimensions } from "../../../common";
import { BuildingDashboard, UnifiedDashboard } from "../pages";

const BuildingDashboardController: React.FC = (props) => {
	const [isUnified, setIsUnified] = useState(false);
	const user = useAppSelector(selectUser);

	const { size } = useWindowDimensions();

	const [selectedResidence, setSelectedResidence] = useState("");

	function handleSelectResidence(residenceId: string): void {
		setSelectedResidence(residenceId);
		setIsUnified(false);
	}

	return (
		<Page>
			<PageContent>
				<Box gap="medium" margin="small">
					<Box direction={size === "small" ? "column-reverse" : "row"} gap="small">
						{user && (
							<Box gap="small">
								<Heading margin="none" level="2">
									Welcome {user.firstName}
								</Heading>
							</Box>
						)}
						<Box flex align="end" justify="center">
							<CheckBox
								toggle
								checked={isUnified}
								onChange={() => {
									if(isUnified) {
										setIsUnified(false);
									}
									else {
										setIsUnified(true);
										setSelectedResidence("");
									}
								}}
								label={<Text style={{ position: "relative", top: ".1em" }} size="large" weight="bold">Unified</Text>}
							/>
						</Box>
					</Box>
					{isUnified
						? (
							<UnifiedDashboard
								onSelectResidence={handleSelectResidence}
							/>
						)
						: (
							<BuildingDashboard
								residenceId={selectedResidence}
							/>
						)
					}
				</Box>
			</PageContent>
		</Page>
	);
};

const ConnectedBuildingDashboardController = withAuth(BuildingDashboardController, true);
export { ConnectedBuildingDashboardController as BuildingDashboardController };