import { gql } from "../../../../__generated__/gql";

export const BeginLogin = gql(/* GraphQL */ `
	mutation BeginLogin($username: String!) {
		BeginLogin(username: $username)
	}
`);

export const Login = gql(/* GraphQL */ `
	mutation Login($username: String!, $code: String!) {
		Login(username: $username, code: $code) {
			access_token
			refresh_token
		}
	}
`);

export const RegisterUser = gql(/* GraphQL */ `
	mutation RegisterUser(
		$firstName: String!
		$lastName: String!
		$phone: String!
		$unit: String!
		$buildingCode: String!
	) {
		RegisterUser(
			firstName: $firstName
			lastName: $lastName
			phone: $phone
			unit: $unit
			buildingCode: $buildingCode
		) {
			access_token
			refresh_token
		}
	}
`);