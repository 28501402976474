import { Box } from "grommet";

export const DashboardCard: React.FC<{ height?: string; background?: string; }> = (props) => {
	return (
		<Box
			round
			flex
			pad="medium"
			elevation="medium"
			height={props.height ?? undefined}
			background={props.background ?? undefined}
		>
			{props.children}
		</Box>
	);
};