import { useQuery } from "@apollo/client";
import { Box, Grid, Heading, TextInput } from "grommet";
import { Fragment, useEffect, useMemo, useState } from "react";
import { GetService } from "../../../../app/services/request";
import { windowToMoment } from "../../../../helpers";
import { RightExpandableSidebarModal, RightSidebarModalContext, SkeletonFormField } from "../../../common";
import { useWindowDimensions } from "../../../common/hooks";

interface ServiceDetailsSidebarProps {
	firstName?: string;
	lastName?: string;
	unitNumber?: string;
	emailAddress?: string;
	phoneNumber?: string;
	residenceName?: string;
	serviceId: string;
	onClose: () => void;
}

export const ServiceDetailsSidebar: React.FC<ServiceDetailsSidebarProps> = (props) => {
	const { size } = useWindowDimensions();
	const { data, loading } = useQuery(GetService, { variables: { serviceId: props.serviceId } });

	const service = useMemo(() => data?.GetService, [props, data]);

	const scheduledDate = useMemo(() => {
		if(!service?.pickup?.scheduledDate) return "";
		return windowToMoment({
			date: service?.pickup?.scheduledDate,
			from: service?.pickup?.scheduledWindowFrom ?? 0,
			to: service?.pickup?.scheduledWindowTo ?? 0,
			timezone: service?.pickup?.scheduledWindowTimezone ?? ""
		}).format("MM/DD/YYYY");
	}, [service]);

	const [formState, setFormState] = useState({
		firstName: props.firstName ?? "",
		lastName: props.lastName ?? "",
		emailAddress: props.emailAddress ?? "",
		phoneNumber: props.phoneNumber ?? "",
		unitNumber: props.unitNumber ?? "",
		scheduledDate: scheduledDate ?? "",
		residenceName: props.residenceName ?? "",
	});

	useEffect(() => {
		if(data) {
			setFormState({
				...formState,
				firstName: service?.user?.firstName ?? props.firstName ?? "",
				lastName: service?.user?.lastName ?? props.lastName ?? "",
				emailAddress: service?.user?.emailAddress ?? props.emailAddress ?? "",
				phoneNumber: service?.user?.phoneNumber ?? props.phoneNumber ?? "",
				unitNumber: service?.user?.currentResident?.verification?.unit ?? props.unitNumber ?? "",
				residenceName: service?.user?.currentResident?.residence?.name ?? ""
			});
		}
	}, [data]);

	return (
		<RightExpandableSidebarModal
			onClose={props.onClose}
		>
			<RightSidebarModalContext.Consumer>
				{(isExpanded) => (
					<Fragment>
						<Heading margin="small" level="2">Resident Details</Heading>
						<Box overflow={{ vertical: isExpanded ? "auto" : "scroll" }}>
							<Grid columns={{ count: isExpanded && size !== "small" ? 2 : 1, size: "auto" }} gap="small">
								<SkeletonFormField
									isLoading={loading && !props.firstName}
									label="First Name"
								>
									<TextInput
										value={service?.user?.firstName}
									/>
								</SkeletonFormField>
								<SkeletonFormField
									isLoading={loading && !props.lastName}
									label="Last Name"
								>
									<TextInput
										value={formState.lastName}
									/>
								</SkeletonFormField>
								<SkeletonFormField
									isLoading={loading && !props.emailAddress}
									label="Email Address"
								>
									<TextInput
										value={formState.emailAddress}
									/>
								</SkeletonFormField>
								<SkeletonFormField
									isLoading={loading && !props.phoneNumber}
									label="Phone Number"
								>
									<TextInput
										value={formState.phoneNumber}
									/>
								</SkeletonFormField>
								<SkeletonFormField
									isLoading={loading && !props.residenceName}
									label="Residence Name"
								>
									<TextInput
										value={formState.residenceName}
									/>
								</SkeletonFormField>
								<SkeletonFormField
									isLoading={loading && !props.unitNumber}
									label="Resident Unit"
								>
									<TextInput
										value={formState.unitNumber}
									/>
								</SkeletonFormField>
								<SkeletonFormField
									isLoading={loading}
									label="Scheduled Date"
								>
									<TextInput
										value={scheduledDate}
									/>
								</SkeletonFormField>
							</Grid>
						</Box>
					</Fragment>
				)}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};