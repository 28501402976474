import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Text } from "grommet";
import { useCallback, useEffect, useMemo } from "react";
import { ConfirmCheckoutSession, FetchCheckoutSession } from "../../../../app/services/request/gql";
import { Loader, Modal, StepController, useSteps, useQueryParams, useClassifications, useGoogleTagManager } from "../../../common";
import { useAppDispatch } from "../../../../app/store";
import { push, replace } from "redux-first-history";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router";
import { CheckoutSessionContactStep, CheckoutSessionInventoryStep, CheckoutSessionPaymentMethodStep, CheckoutSessionScheduleStep } from "../components";
import { pickupWindows } from "../../helpers";
import { ItemClassificationCode } from "../../../../__generated__/graphql";
import { CheckoutSessionEventData } from "../types";
import { PaymentMethodType } from "@rego-app/schema";

export const ExpressFlowModal: React.FC<{ onContinue: () => void; }> = (props) => {
	return (
		<Modal>
			<Box margin="medium" style={{ minHeight: "200px" }}>
				<Text>
					We just sent you a text to finish scheduling your pickup. If you'd prefer to continue here, click the button below.
				</Text>
				<Box flex align="end" justify="end">
					<Button
						primary
						color="accent-1"
						label="Continue Here"
						onClick={props.onContinue}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export const SchedulePickupPage: React.FC = (props) => {
	const { next, current, force } = useSteps();
	const gtm = useGoogleTagManager();
	const snack = useSnackbar();
	const query = useQueryParams();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [ isWaiting, setIsWaiting ] = useState(false);
	const [ displayExpressFlowModal, setDisplayExpressFlowModal ] = useState(false);
	const { classifications, loading: classificationsLoading } = useClassifications();

	const sessionId = useMemo(() => {
		const sid = query.get("sid");
		if(!sid) {
			dispatch(push("/dashboard"));
			return "";
		}

		return sid;
	}, [ query, dispatch ]);

	const phoneNumber = useMemo(() => {
		const phone = query.get("phone");
		if(!phone) {
			dispatch(push("/dashboard"));
			return "";
		}

		return phone;
	}, [ query, dispatch ]);

	const startingIndex = useMemo(() => {
		const index = query.get("index");
		if(index) {
			return Number(index);
		}

		return 0;
	}, []);

	useEffect(() => {
		const index = query.get("index");
		if(index && Number(index) !== current) {
			force(Number(index));
		}
	}, [ query, force, current ]);

	const [ fetchCheckoutSessionQuery, { data, loading, error, refetch } ] = useLazyQuery(FetchCheckoutSession, {
		variables: { sessionId, phoneNumber }
	});

	const [ confirmCheckoutSessionMutation, { loading: isConfirming, error: confirmError } ] = useMutation(ConfirmCheckoutSession, {
		variables: { sessionId, phoneNumber }
	});

	const sessionData = useMemo(() => data?.GetPickupSession, [ data ]);

	const fetchCheckoutSession = useCallback((sessionId: string, phoneNumber: string) => {
		return fetchCheckoutSessionQuery({ variables: { phoneNumber, sessionId } });
	}, [ fetchCheckoutSessionQuery ]);

	useEffect(() => {
		if(phoneNumber && sessionId) {
			fetchCheckoutSession(sessionId, phoneNumber);
		}
	}, [ sessionId, phoneNumber, fetchCheckoutSession ]);

	useEffect(() => {
		if(error) {
			console.error("Failed to fetch session data", error);
			snack.enqueueSnackbar("We ran into an issue loading your information", { variant: "error" });
		}
	}, [ error, snack ]);

	const handleStepChanged = useCallback(() => {
		query.set("index", String(current));
		dispatch(push(`${location.pathname}?${query.toString()}`));
	}, [ current, query, dispatch ]);

	useEffect(() => {
		if(confirmError) {
			console.error("Failed to confirm session", confirmError);
			snack.enqueueSnackbar("We ran into an issue confirming your pickup", { variant: "error" });
		}
	}, [ confirmError ]);

	const standardPickupFee = useMemo(() => {
		return classifications.reduce((accum, curr) => {
			switch(curr.code) {
				case ItemClassificationCode.BagsBoxes: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountBagsBoxes ?? 0) ?? 0);
				}
				case ItemClassificationCode.Mattresses: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountMattresses ?? 0) ?? 0);
				}
				case ItemClassificationCode.Small: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountSmall ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountSmall ?? 0);
				}
				case ItemClassificationCode.Medium: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountMedium ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountMedium ?? 0);
				}
				case ItemClassificationCode.Large: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountLarge ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountLarge ?? 0);
				}
				case ItemClassificationCode.Xlarge: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountXLarge ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountXLarge ?? 0);
				}
				case ItemClassificationCode.ChristmasTree: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountChristmasTree ?? 0) ?? 0);
				}
			}
		}, 0);
	}, [ classifications, sessionData ]);

	const buildingPickupFee = useMemo(() => {
		const pricing = sessionData?.residence?.customizations?.pricing?.items;
		if(!pricing) return standardPickupFee;

		return pricing.reduce((accum, curr) => {
			switch(curr.code) {
				case ItemClassificationCode.BagsBoxes: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountBagsBoxes ?? 0) ?? 0);
				}
				case ItemClassificationCode.Mattresses: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountMattresses ?? 0) ?? 0);
				}
				case ItemClassificationCode.Small: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountSmall ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountSmall ?? 0);
				}
				case ItemClassificationCode.Medium: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountMedium ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountMedium ?? 0);
				}
				case ItemClassificationCode.Large: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountLarge ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountLarge ?? 0);
				}
				case ItemClassificationCode.Xlarge: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountXLarge ?? 0) ?? 0) + curr.pricing.disassemblyFee * (sessionData?.disassemblyCountXLarge ?? 0);
				}
				case ItemClassificationCode.ChristmasTree: {
					return accum + (curr.pricing.pickupFee * (sessionData?.itemCountChristmasTree ?? 0) ?? 0);
				}
			}
		}, 0);
	}, [ sessionData, standardPickupFee ]);

	const pickupFee = useMemo(() => {
		if(sessionData?.estimate?.totalAmount) {
			return sessionData?.estimate?.totalAmount;
		}

		if(sessionData?.pickup?.isCustomerScheduled) {
			return standardPickupFee;
		}

		return buildingPickupFee;
	}, [ sessionData, standardPickupFee, buildingPickupFee ]);

	const eventData = useMemo((): CheckoutSessionEventData => {
		return {
			paymentMethod: sessionData?.paymentMethod ? {
				id: sessionData?.paymentMethod?.id,
				type: PaymentMethodType.CARD
			} : undefined,
			pickup: sessionData?.pickup ? {
				id: sessionData.pickup.id,
				isCustomerScheduled: sessionData.pickup.isCustomerScheduled,
				scheduledDate: sessionData.pickup.scheduledDate ?? "",
				scheduledWindowFrom: sessionData.pickup.scheduledWindowFrom ?? 0,
				scheduledWindowTo: sessionData.pickup.scheduledWindowTo ?? 0
			} : undefined,
			residence: {
				id: sessionData?.residence?.id ?? "",
				name: sessionData?.residence?.name ?? "",
			},
			promotion: sessionData?.promotion ? {
				id: sessionData.promotion.id,
				code: sessionData.promotion.code,
				description: sessionData.promotion.description,
			} : undefined,
			service: sessionData?.service ? { id: sessionData.service.id } : undefined,
			items: [
				{
					index: 0,
					item_id: "",
					item_name: "Pickup Service",
					coupon: sessionData?.promotion?.code ?? "",
					quantity: 1,
					price: pickupFee,
					discount: sessionData?.estimate?.discountAmount ?? 0
				}
			]
		};
	}, [ sessionData ]);

	function handleSubmitSession(): void {
		confirmCheckoutSessionMutation({
			variables: { sessionId, phoneNumber }
		}).then(() => {
			snack.enqueueSnackbar("Your pickup has been scheduled!", { variant: "success" });
			gtm.push("purchase", {
				currency: "USD",
				value: pickupFee,
				pickupId: eventData.pickup?.id ?? "",
				pickupDate: eventData.pickup?.scheduledDate ?? "",
				discount: sessionData?.estimate?.discountAmount ?? 0,
				tax: sessionData?.estimate?.taxAmount ?? 0,
				shipping: 0,
				coupon: sessionData?.promotion?.code ?? "",
				transaction_id: eventData.service?.id ?? "",
				items: eventData.items
			});
			dispatch(push("/dashboard"));
		});
	}


	return (
		<Loader visible={loading}>
			<Box>
				{displayExpressFlowModal && (
					<ExpressFlowModal
						onContinue={() => {
							query.delete("isExpressFlow");
							dispatch(replace(`${location.pathname}?${query.toString()}`));
							setDisplayExpressFlowModal(false);
							next();
						}}
					/>
				)}
				<StepController
					startFrom={startingIndex}
					onStepChange={handleStepChanged}
				>
					<CheckoutSessionInventoryStep
						eventData={eventData}
						isLoading={loading || isConfirming}
						sessionId={sessionId}
						phoneNumber={phoneNumber}
						itemCountSmall={sessionData?.itemCountSmall ?? 0}
						disassemblyCountSmall={sessionData?.disassemblyCountSmall ?? 0}
						itemCountMedium={sessionData?.itemCountMedium ?? 0}
						disassemblyCountMedium={sessionData?.disassemblyCountMedium ?? 0}
						itemCountLarge={sessionData?.itemCountLarge ?? 0}
						disassemblyCountLarge={sessionData?.disassemblyCountLarge ?? 0}
						itemCountXLarge={sessionData?.itemCountXLarge ?? 0}
						disassemblyCountXLarge={sessionData?.disassemblyCountXLarge ?? 0}
						itemCountBagsBoxes={sessionData?.itemCountBagsBoxes ?? 0}
						itemCountMattresses={sessionData?.itemCountMattresses ?? 0}
						itemCountChristmasTree={sessionData?.itemCountChristmasTree ?? 0}
						customPricing={(sessionData?.residence?.customizations?.pricing?.items ?? []).map(r => {
							return {
								code: r.code,
								pricing: r.pricing
							};
						})}
						media={sessionData?.media.map(m => {
							return {
								fileName: m.fileName,
								contentUrl: m.contentUrl ?? ""
							};
						}) ?? []}
						onComplete={() => {
							refetch({ sessionId, phoneNumber }).then(() => {
								if(query.get("isExpressFlow")) {
									setDisplayExpressFlowModal(true);
									return;
								}

								next();
							});
						}}
					/>
					<CheckoutSessionScheduleStep
						eventData={eventData}
						isLoading={loading || isConfirming}
						sessionId={sessionId}
						phoneNumber={phoneNumber}
						selectedPickupId={sessionData?.pickup?.id ?? undefined}
						customPickupDate={sessionData?.isCustomScheduled ? sessionData?.scheduledDate ?? undefined : undefined}
						customPickupWindow={sessionData?.isCustomScheduled
							? pickupWindows.find(w => w.from === sessionData?.scheduledWindowFrom && w.to === sessionData?.scheduledWindowTo)?.label
							: undefined}
						availablePickups={sessionData?.residence?.upcomingPickups?.map(pickup => {
							return {
								id: pickup.id,
								date: pickup.scheduledDate ?? "",
								from: pickup.scheduledWindowFrom ?? 0,
								to: pickup.scheduledWindowTo ?? 0,
								timezone: pickup.scheduledWindowTimezone ?? ""
							};
						}) ?? []}
						onComplete={() => {
							refetch({ sessionId, phoneNumber }).then(() => {
								next();
							});
						}}
						buildingPickupFee={buildingPickupFee}
						standardPickupFee={standardPickupFee}
					/>
					<CheckoutSessionContactStep
						eventData={eventData}
						isLoading={loading || isWaiting}
						sessionId={sessionId}
						phoneNumber={phoneNumber}
						firstName={sessionData?.firstName ?? ""}
						lastName={sessionData?.lastName ?? ""}
						emailAddress={sessionData?.emailAddress ?? ""}
						isEmailVerified={sessionData?.isEmailVerified ?? false}
						handleRefetch={() => {
							//FIXME: RACE CONDITION!
							//IM SORRY
							setIsWaiting(true);
							setTimeout(() => {
								refetch({ sessionId, phoneNumber });
								setIsWaiting(false);
							}, 500);
						}}
						onComplete={() => {
							refetch({ sessionId, phoneNumber }).then(() => {
								next();
							});
						}}
					/>
					<CheckoutSessionPaymentMethodStep
						eventData={eventData}
						isLoading={loading || isConfirming}
						sessionId={sessionId}
						phoneNumber={phoneNumber}
						onComplete={handleSubmitSession}
						paymentMethodId={sessionData?.paymentMethod?.id}
					/>
				</StepController>
			</Box>
		</Loader>
	);
};