import { Avatar as GrommetAvatar } from "grommet";
import { User } from "grommet-icons";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../app/store";

export const Avatar: React.FC = (props) => {
	const dispatch = useAppDispatch();

	return (
		<GrommetAvatar
			size="medium"
			background="brand"
			border={{ color: "white", size: "small" }}
			onClick={() => {
				dispatch(push("/account"));
			}}
		>
			<User size="medium" color="white" />
		</GrommetAvatar>
	);
};