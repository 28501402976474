import { Box } from "grommet";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/store";
import { selectRequestNoMargin } from "../../../app/store/application";
import { RegoFooter, RegoHeader, RegoMain } from "../components";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

export const LayoutProvider: React.FC<{ children: React.ReactNode; }> = (props) => {
	const dimensions = useWindowDimensions();
	const [mainContentHeight, setMainContentHeight] = useState(dimensions.height);
	const requestNoMargin = useAppSelector(selectRequestNoMargin);

	const calculatePageSize = useCallback((headerSize: number) => {
		const windowHeight = dimensions.height;
		const headerHeight = document.getElementById("header")?.clientHeight ?? 0;
		return windowHeight - headerHeight;
	}, [dimensions]);

	// const mainContentHeight = useMemo(() => {
	// 	const windowHeight = dimensions.height;
	// 	const headerHeight = document.getElementById("header")?.clientHeight ?? 0;
	// 	return windowHeight - headerHeight;
	// }, [dimensions]);

	useEffect(() => {
		const headerHeight = document.getElementById("header")?.clientHeight ?? 0;
		setMainContentHeight(calculatePageSize(headerHeight));
	}, [dimensions]);

	return (
		<Fragment>
			<RegoHeader />
			<RegoMain height={mainContentHeight}>
				<Box margin={requestNoMargin ? undefined : "medium"}>
					{props.children}
				</Box>
			</RegoMain>
			<RegoFooter />
		</Fragment>
	);
};