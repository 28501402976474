import { useMutation } from "@apollo/client";
import { Box, CheckBox, Heading, Spinner, Text } from "grommet";
import { useCallback, useEffect, useState } from "react";
import { AssignVehicleToHauler, RemoveVehicleFromHauler } from "../../../../app/services/request/partner";
import { VehicleType } from "../../../../__generated__/graphql";
import { Pagination, useVehicles, DashboardCard } from "../../../common";

interface HaulerVehiclesCardProps {
	name: string;
	isLoading: boolean;
	vehicles: {
		id: string;
		name: string;
		type: VehicleType;
	}[];
	haulerId: string;
	handleRefetch(): void;
}

export const HaulerVehiclesCard: React.FC<HaulerVehiclesCardProps> = (props) => {
	const { loading, vehicles } = useVehicles();
	const [haulerVehicles, setHaulerVehicles] = useState(props.vehicles);
	const [activeVehicleId, setActiveVehicleId] = useState("");

	const [assignVehicle, { loading: isAssigning }] = useMutation(AssignVehicleToHauler);
	const [removeVehicle, { loading: isRemoving }] = useMutation(RemoveVehicleFromHauler);

	useEffect(() => {
		setHaulerVehicles([...props.vehicles]);
	}, [props.vehicles]);

	function handleRemoveVehicle(vehicleId: string): void {
		if(haulerVehicles.some(v => v.id === vehicleId)) {
			removeVehicle({ variables: { vehicleId, haulerId: props.haulerId } }).then(() => {
				props.handleRefetch();
			}).catch(err => {
				console.error("Failed to remove hauler vehicle: ", err);
			});
		}

		setActiveVehicleId("");
	}

	function handleAssignVehicle(vehicleId: string): void {
		if(!haulerVehicles.some(v => v.id === vehicleId)) {
			assignVehicle({ variables: { vehicleId, haulerId: props.haulerId } }).then(() => {
				props.handleRefetch();
			}).catch(err => {
				console.error("Failed to assign hauler vehicle: ", err);
			});
		}

		setActiveVehicleId("");
	}

	useEffect(() => {
		if(activeVehicleId) {
			const isFound = haulerVehicles.some(v => v.id === activeVehicleId);

			if(isFound) {
				handleRemoveVehicle(activeVehicleId);
				return;
			}

			handleAssignVehicle(activeVehicleId);
			return;
		}
	}, [activeVehicleId]);

	const renderChildren = useCallback((vehicles: HaulerVehiclesCardProps["vehicles"]) => {
		return (
			<Box gap="small">
				{vehicles.map(vehicle => (
					<HaulerVehicleCard
						key={vehicle.id}
						name={vehicle.name}
						isLoading={vehicle.id === activeVehicleId || loading}
						onSelect={() => {
							setActiveVehicleId(vehicle.id);
						}}
						isAssigned={haulerVehicles.some(v => v.id === vehicle.id)}
					/>
				))}
			</Box>
		);
	}, [props.vehicles, haulerVehicles, activeVehicleId, vehicles]);

	return (
		<DashboardCard>
			<Box gap="small">
				<Heading margin="none" level="2">Hauler Vehicles</Heading>
				<Pagination
					pageSize={5}
					items={vehicles}
					renderItems={renderChildren}
				/>
			</Box>
		</DashboardCard>
	);
};

interface HaulerVehicleCardProps {
	onSelect(): void;
	isLoading: boolean;
	isAssigned: boolean;
	name: string;
}

export const HaulerVehicleCard: React.FC<HaulerVehicleCardProps> = (props) => {
	return (
		<Box
			direction="row"
			justify="between"
			pad="small"
			background="light-2"
			hoverIndicator
			onClick={props.onSelect}
		>
			<Box>
				<Text weight="bold">{props.name}</Text>
			</Box>
			<Box>
				{props.isLoading
					? (
						<Spinner />
					)
					: (
						<CheckBox
							checked={props.isAssigned}
							onClick={props.onSelect}
						/>
					)}
			</Box>
		</Box>
	);
};