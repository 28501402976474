import { useMutation } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import { Divider } from "@mui/material";
import { ItemClassificationCode, ItemPricing } from "@rego-app/schema";
import { Box, Heading, Grid, FormField, FileInput, Text, Anchor, Stack, Button, CheckBox } from "grommet";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import config from "../../../../../app/config";
import { UpdateCheckoutSessionInventory, UploadCheckoutSessionMedia } from "../../../../../app/services/request/gql";
import { fileToMedia, formatCurrency } from "../../../../../helpers";
import { DashboardCard, Loader, LoadingButton, Modal, RightExpandableSidebarModal, RightSidebarModalContext, useClassifications, useWindowDimensions } from "../../../../common";
import { CheckoutSessionProps, ItemCard, ItemPricingComparison } from "./common";
import { FormAdd, FormSubtract, IconProps } from "grommet-icons";
import { Circle } from "@mui/icons-material";

interface ExampleModalProps {
	onClose(): void;
	examples: string[];
}

export const ExampleModal: React.FC<ExampleModalProps> = (props) => {
	return (
		<Modal
			onCloseAll={props.onClose}
			style={{ maxWidth: "500px" }}
		>
			<Box pad="small" gap="small">
				<Box align="center">
					<Heading margin="none" level="2">Some Common Examples</Heading>
				</Box>
				<Text margin="medium">
					{props.examples.join(", ")}
				</Text>
			</Box>
		</Modal>
	);
};

interface DisassemblyModalProps {
	onClose(): void;
	increment(code: ItemClassificationCode): void;
	decrement(code: ItemClassificationCode): void;
	itemCount: Record<ItemClassificationCode, number>;
	disassemblyCount: Record<ItemClassificationCode, number>;
}

export const DisassemblyModal: React.FC<DisassemblyModalProps> = (props) => {
	const { size } = useWindowDimensions();
	const { classifications } = useClassifications();

	const selectedClassifications = useMemo(() => {
		return classifications.filter(c => props.itemCount[ c.code ] > 0 && ![ "MATTRESSES", "BAGS_BOXES", "CHRISTMAS_TREE" ].includes(c.code));
	}, [ classifications, props.itemCount ]);

	return (
		<RightExpandableSidebarModal onClose={props.onClose} blockExpand>
			<RightSidebarModalContext.Consumer>
				{(isExpanded) => (
					<Box margin="small" gap="small">
						<Text margin="none" weight="bold">Let us know how many items need disassembly?</Text>
						<Box gap="small">
							{selectedClassifications.map((classification) => (
								<Box gap="small">
									<Box flex border />
									<Box direction="row" justify="between">
										<Heading margin="none" level="3">{classification.name}</Heading>
										<Box align="end" justify="center">
											<ItemPricingComparison
												total={props.disassemblyCount[ classification.code ] * classification.pricing.disassemblyFee}
											/>
										</Box>
									</Box>
									<Box align="center">
										<Grid columns={[ "auto", "flex", "auto" ]} gap="small" align="center">
											<Button
												plain
												hoverIndicator
												onClick={() => props.decrement(classification.code as unknown as ItemClassificationCode)}
												icon={
													<Stack>
														<Circle sx={{ fontSize: 36, color: "#e16259" }} />
														<Box align="center" justify="center" fill>
															<FormSubtract color="white" size={"small"} />
														</Box>
													</Stack>
												}
											/>
											<Box align="center" justify="center" style={{ minWidth: "40px" }}>
												<Heading margin="none" level="2">{props.disassemblyCount[ classification.code ]}</Heading>
											</Box>
											<Button
												plain
												hoverIndicator
												onClick={() => props.increment(classification.code as unknown as ItemClassificationCode)}
												icon={
													<Stack>
														<Circle sx={{ fontSize: 36 }} color="success" />
														<Box align="center" justify="center" fill>
															<FormAdd color="white" size="small" />
														</Box>
													</Stack>
												}
											/>
										</Grid>
									</Box>
								</Box>
							))}
							{selectedClassifications.length === 0 && (
								<Box align="center" justify="center">
									<Text>Add the item(s) to be picked up first</Text>
								</Box>
							)}
						</Box>
					</Box>
				)}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};

interface ExamplesModalProps {
	onClose(): void;
	classifications: {
		name: string;
		examples: string[];
	}[];
}

export const ExamplesModal: React.FC<ExamplesModalProps> = (props) => {
	return (
		<Modal
			onCloseAll={props.onClose}
		>
			<Box margin="small" gap="small">
				<Box align="center">
					<Heading margin="none" level="2">Item Size Examples</Heading>
				</Box>
				{props.classifications.filter(c => c.examples.length > 0).map((classification) => (
					<Box gap="xsmall">
						<Heading margin="none" level="3">{classification.name}</Heading>
						<Box pad="small">
							<Text>{classification.examples.join(", ")}</Text>
						</Box>
					</Box>
				))}
			</Box>
		</Modal>
	);
};

interface SchedulePickupInventoryStepProps extends CheckoutSessionProps {
	itemCountSmall: number;
	disassemblyCountSmall: number;
	itemCountMedium: number;
	disassemblyCountMedium: number;
	itemCountLarge: number;
	disassemblyCountLarge: number;
	itemCountXLarge: number;
	disassemblyCountXLarge: number;
	itemCountBagsBoxes: number;
	itemCountMattresses: number;
	itemCountChristmasTree: number;
	media: {
		contentUrl: string;
		fileName: string;
	}[];
	customPricing: Array<{ code: string, pricing: ItemPricing; }>;
}

export const CheckoutSessionInventoryStep: React.FC<SchedulePickupInventoryStepProps> = (props) => {
	const { size } = useWindowDimensions();
	const snack = useSnackbar();
	const { classifications, loading } = useClassifications();

	const [ updateCheckoutSessionInventoryMutation, { loading: isUpdating, error: updatingError } ] = useMutation(UpdateCheckoutSessionInventory);

	const [ inventoryState, setInventoryState ] = useState<Record<ItemClassificationCode, number>>({
		"SMALL": props.itemCountSmall ?? 0,
		"MEDIUM": props.itemCountMedium ?? 0,
		"LARGE": props.itemCountLarge ?? 0,
		"XLARGE": props.itemCountXLarge ?? 0,
		"BAGS_BOXES": props.itemCountBagsBoxes ?? 0,
		"MATTRESSES": props.itemCountMattresses ?? 0,
		"CHRISTMAS_TREE": props.itemCountChristmasTree ?? 0
	});

	const [ disassemblyState, setDisassemblyState ] = useState<Record<ItemClassificationCode, number>>({
		"SMALL": props.disassemblyCountSmall ?? 0,
		"MEDIUM": props.disassemblyCountMedium ?? 0,
		"LARGE": props.disassemblyCountLarge ?? 0,
		"XLARGE": props.disassemblyCountXLarge ?? 0,
		"BAGS_BOXES": 0,
		"MATTRESSES": 0,
		"CHRISTMAS_TREE": 0
	});

	const offerDisassembly = useMemo(() => {
		return (Object.keys(inventoryState) as (keyof typeof inventoryState)[]).filter(k => ![ "MATTRESSES", "BAGS_BOXES", "CHRISTMAS_TREE" ].includes(k)).some(k => inventoryState[ k ] > 0);
	}, [ inventoryState ]);

	const [ showExamplesModal, setShowExamplesModal ] = useState(false);
	const [ showDisassemblyModal, setShowDisassemblyModal ] = useState(false);

	const needsDisassembly = useMemo(() => {
		return (Object.keys(disassemblyState) as (keyof typeof disassemblyState)[]).some(k => disassemblyState[ k ] > 0);
	}, [ disassemblyState ]);

	/**
	 * [ discountedPricing, standardPricing, comparisonPricing	]
	 */
	const [ pricing, setPricing ] = useState({
		"SMALL": [ 0, 0, 0 ],
		"MEDIUM": [ 0, 0, 0 ],
		"LARGE": [ 0, 0, 0 ],
		"XLARGE": [ 0, 0, 0 ],
		"BAGS_BOXES": [ 0, 0, 0 ],
		"MATTRESSES": [ 0, 0, 0 ],
		"CHRISTMAS_TREE": [ 0, 0, 0 ]
	});

	useEffect(() => {
		setPricing({
			"SMALL": [
				props.customPricing.find(customPricing => String(customPricing.code) === "SMALL")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "SMALL")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "SMALL")?.pricingComparison.pickupFee ?? 0
			],
			"MEDIUM": [
				props.customPricing.find(customPricing => String(customPricing.code) === "MEDIUM")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "MEDIUM")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "MEDIUM")?.pricingComparison.pickupFee ?? 0
			],
			"LARGE": [
				props.customPricing.find(customPricing => String(customPricing.code) === "LARGE")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "LARGE")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "LARGE")?.pricingComparison.pickupFee ?? 0
			],
			"XLARGE": [
				props.customPricing.find(customPricing => String(customPricing.code) === "XLARGE")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "XLARGE")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "XLARGE")?.pricingComparison.pickupFee ?? 0
			],
			"BAGS_BOXES": [
				props.customPricing.find(customPricing => String(customPricing.code) === "BAGS_BOXES")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "BAGS_BOXES")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "BAGS_BOXES")?.pricingComparison.pickupFee ?? 0
			],
			"MATTRESSES": [
				props.customPricing.find(customPricing => String(customPricing.code) === "MATTRESSES")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "MATTRESSES")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "MATTRESSES")?.pricingComparison.pickupFee ?? 0
			],
			"CHRISTMAS_TREE": [
				props.customPricing.find(customPricing => String(customPricing.code) === "CHRISTMAS_TREE")?.pricing?.pickupFee ?? 0,
				classifications.find(c => c.code === "CHRISTMAS_TREE")?.pricing.pickupFee ?? 0,
				classifications.find(c => c.code === "CHRISTMAS_TREE")?.pricingComparison.pickupFee ?? 0
			]
		});
	}, [ classifications, props.customPricing ]);

	const estimate = useMemo(() => {
		return classifications.reduce((p, c) => {
			const pickupFee = props.customPricing.find(customPricing => String(customPricing.code) === c.code)?.pricing?.pickupFee ?? c.pricing.pickupFee;
			return (inventoryState[ c.code ] * pickupFee) + disassemblyState[ c.code ] * c.pricing.disassemblyFee + p;
		}, 0);
	}, [ inventoryState, disassemblyState, classifications, props.customPricing ]);

	const comparison = useMemo(() => {
		return classifications.reduce((p, c) => {
			return (inventoryState[ c.code ] * c.pricing.pickupFee) + disassemblyState[ c.code ] * c.pricing.disassemblyFee + p;
		}, 0);
	}, [ inventoryState, disassemblyState, classifications ]);

	function handleUpdate(): void {
		updateCheckoutSessionInventoryMutation({
			variables: {
				sessionId: props.sessionId,
				phoneNumber: props.phoneNumber,
				itemCountSmall: inventoryState.SMALL,
				disassemblyCountSmall: disassemblyState.SMALL,
				itemCountMedium: inventoryState.MEDIUM,
				disassemblyCountMedium: disassemblyState.MEDIUM,
				itemCountLarge: inventoryState.LARGE,
				disassemblyCountLarge: disassemblyState.LARGE,
				itemCountXLarge: inventoryState.XLARGE,
				disassemblyCountXLarge: disassemblyState.XLARGE,
				itemCountBagsBoxes: inventoryState.BAGS_BOXES,
				itemCountMattresses: inventoryState.MATTRESSES,
				itemCountChristmasTree: inventoryState.CHRISTMAS_TREE
			}
		}).then(() => {
			props.onComplete();
		}).catch(err => {
			console.error("Failed to update checkout session inventory", err);
			snack.enqueueSnackbar("We ran into an issue saving your information", { variant: "error" });
		});
	}

	return (
		<Loader visible={loading}>
			{showExamplesModal && (
				<ExamplesModal
					onClose={() => setShowExamplesModal(false)}
					classifications={classifications}
				/>
			)}
			{showDisassemblyModal && (
				<DisassemblyModal
					onClose={() => setShowDisassemblyModal(false)}
					increment={(code) => {
						if(disassemblyState[ code ] < inventoryState[ code ]) {
							setDisassemblyState({
								...disassemblyState,
								[ code ]: disassemblyState[ code ] + 1
							});
						}
					}}
					decrement={(code) => {
						if(disassemblyState[ code ] > 0) {
							setDisassemblyState({
								...disassemblyState,
								[ code ]: disassemblyState[ code ] - 1
							});
						}
					}}
					itemCount={inventoryState}
					disassemblyCount={disassemblyState}
				/>
			)}
			<Grid gap="small" columns={{ count: [ "small", "medium", "large" ].includes(size) ? 1 : 2, size: "auto" }}>
				<DashboardCard>
					<Box gap="medium">
						<Box>
							<Heading margin="none" level="2">
								Choose the items you need out
							</Heading>
							<Text>
								Let us know how much of each type of item you have.
							</Text>
						</Box>
						<Box>
							{classifications.map(c => (
								<ItemCard
									key={c.id}
									title={c.name}
									examples={c.examples}
									description={c.description}
									decrement={() => {
										setInventoryState({
											...inventoryState,
											[ c.code ]: Math.max(0, inventoryState[ c.code ] - 1)
										});

										if(disassemblyState[ c.code ] >= inventoryState[ c.code ]) {
											setDisassemblyState({
												...disassemblyState,
												[ c.code ]: Math.max(0, disassemblyState[ c.code ] - 1)
											});
										}
									}}

									increment={() => {
										setInventoryState({
											...inventoryState,
											[ c.code ]: inventoryState[ c.code ] + 1
										});
									}}

									count={inventoryState[ c.code ]}
									total={(() => {
										const data = pricing[ c.code ];
										const price = pricing[ c.code ][ 0 ] ? data[ 0 ] : data[ 1 ];
										const pickupCost = price * inventoryState[ c.code ];
										return pickupCost + (disassemblyState[ c.code ] * c.pricing.disassemblyFee);
									})()}
									compareTotal={(() => {
										const price = pricing[ c.code ][ 2 ];
										return price * inventoryState[ c.code ] + (disassemblyState[ c.code ] * c.pricing.disassemblyFee);
									})()}
								//canDisassemble={c.code !== "BAGS_BOXES"}
								/>
							))}
						</Box>
						<Box>
							<Anchor
								label="Need help picking a size? Click here for some more examples of item sizes."
								onClick={() => setShowExamplesModal(true)}
							/>
						</Box>
						{offerDisassembly && (
							<Box gap="small">
								<Text weight="bold">Do any of these items need disassembly?</Text>
								<Box direction="row" gap="small" align="center">
									<CheckBox
										checked={needsDisassembly}
										onClick={() => setShowDisassemblyModal(true)}
									/>
									{needsDisassembly && (
										<Anchor
											label="Update"
											color="accent-1"
											onClick={() => setShowDisassemblyModal(true)}
											style={{ marginTop: "3px" }}
										/>
									)}
								</Box>
							</Box>
						)}
						<Box>
							<Text>
								<Text size="large" weight="bold">If you have more than 5 items we recommend calling or texting for a custom quote!</Text> <Anchor href={`tel:${config.contact.phone}`} size="large" color="accent-1" label="Call or text us." />
							</Text>
						</Box>
					</Box>
				</DashboardCard>
				<MediaUploadCard
					sessionId={props.sessionId}
					phoneNumber={props.phoneNumber}
					isUpdating={isUpdating}
					isDisabled={!(inventoryState.SMALL || inventoryState.MEDIUM || inventoryState.LARGE || inventoryState.XLARGE || inventoryState.BAGS_BOXES || inventoryState.MATTRESSES || inventoryState.CHRISTMAS_TREE)}
					onClick={handleUpdate}
					estimate={estimate}
					comparison={comparison}
					media={props.media}
				/>
			</Grid>
		</Loader>
	);
};

interface MediaUploadCardProps {
	sessionId: string;
	phoneNumber: string;
	isUpdating: boolean;
	isDisabled: boolean;
	onClick: () => void;
	estimate: number;
	comparison: number;
	media: {
		fileName: string;
		contentUrl: string;
	}[];
}

interface MediaUploadTracking {
	fileName: string;
	isCompleted: boolean;
	isUploading: boolean;
	contentUrl: string;
}

export const MediaUploadCard: React.FC<MediaUploadCardProps> = (props) => {
	const snack = useSnackbar();
	const { size } = useWindowDimensions();
	const [ uploadState, setUploadState ] = useState<MediaUploadTracking[]>([]);
	const [ uploadMediaMutation ] = useMutation(UploadCheckoutSessionMedia);

	const isAnyUploading = useMemo(() => uploadState.some(u => u.isUploading), [ uploadState ]);

	useEffect(() => {
		const media = props.media.map((m) => {
			return { fileName: m.fileName, isCompleted: true, isUploading: false, contentUrl: m.contentUrl };
		});

		setUploadState([ ...media ]);
	}, []);

	function handleBeginUpload(fileName: string): void {
		Bugsnag.leaveBreadcrumb(`Begin uploading file [${fileName}]`, { sessionId: props.sessionId });
		setUploadState([
			...uploadState,
			{ fileName, isCompleted: false, isUploading: true, contentUrl: "" }
		]);
		snack.enqueueSnackbar(`Uploading ${fileName}`, { variant: "success" });
	}

	function handleCompleteUpload(fileName: string, contentUrl: string): void {
		Bugsnag.leaveBreadcrumb(`Successfully uploaded file [${fileName}]`, { sessionId: props.sessionId });
		setUploadState([
			...uploadState.filter(n => n.fileName !== fileName),
			{ fileName, isCompleted: true, isUploading: false, contentUrl }
		]);
		snack.enqueueSnackbar(`Successfully uploaded ${fileName}`, { variant: "success" });
	}

	function handleFailedUpload(fileName: string, err: unknown): void {
		Bugsnag.leaveBreadcrumb(`Failed uploading file [${fileName}]`, { sessionId: props.sessionId });
		Bugsnag.notify(err instanceof Error ? err : new Error(JSON.stringify(err)));
		setUploadState([
			...uploadState.filter(n => n.fileName !== fileName),
			{ fileName, isCompleted: false, isUploading: false, contentUrl: "" }
		]);
		snack.enqueueSnackbar(`We weren't able to upload your file: ${fileName}`, { variant: "error" });
	}

	function handleMediaUpload(event?: React.ChangeEvent<HTMLInputElement>): void {
		Bugsnag.leaveBreadcrumb("Begin media upload");
		if(!event) return;

		const files: File[] = [];
		if(event.target.files) {
			for(const file of event.target.files) {
				Bugsnag.leaveBreadcrumb(`File with name [${file.name}] uploaded`);
				files.push(file);
			}
		}

		Promise.all(files.map(file => fileToMedia(file))).then(media => {
			return media.map(m => {
				handleBeginUpload(m.fileName);
				return uploadMediaMutation({
					variables: {
						sessionId: props.sessionId,
						phoneNumber: props.phoneNumber,
						content: m.content ?? "",
						contentType: m.contentType,
						extension: m.extension,
						fileName: m.fileName
					}
				}).then((data) => {
					handleCompleteUpload(m.fileName, data.data?.UploadCheckoutSessionMedia.contentUrl ?? "");
				}).catch(err => {
					console.error(`Failed to upload file ${m.fileName}`, err);
					handleFailedUpload(m.fileName, err);
				});
			});
		});
	}

	function openFilePreview(url: string): void {
		window.open(url, "_blank");
	}

	return (
		<DashboardCard>
			<Box gap="small" flex>
				<Box>
					<Heading margin="none" level="2">
						Upload image(s)
					</Heading>
					<Text>
						Please upload a photo of the front of each item.
					</Text>
				</Box>
				<Box gap="small">
					<FormField>
						<FileInput
							multiple
							maxSize={5000000}
							messages={{
								"dropPrompt": "Click to take or upload a photo",
								"dropPromptMultiple": "Click to take or upload a photo"
							}}
							onChange={handleMediaUpload}
						/>
					</FormField>
					{props.media.length > 0 && (
						<Box>
							<Text weight="bold">Already Added</Text>
							<Box margin="small" gap="small">
								{props.media.map(file => (
									<Box pad="small" direction="row" gap="medium" key={file.fileName} hoverIndicator onClick={() => file.contentUrl && openFilePreview(file.contentUrl)}>
										<Text weight="bold">
											{file.fileName}
										</Text>
									</Box>
								))}
							</Box>
						</Box>
					)}
				</Box>
				<Divider />
				<Grid columns={[ "flex", "auto" ]} gap="small">
					<Heading level="4" margin="none">
						Subtotal
					</Heading>
					<Box align="end">
						<Heading margin="none" level="4" color="red" style={{ textDecoration: "line-through" }}>
							{formatCurrency(props.comparison)}
						</Heading>
					</Box>
					<Heading level="4" margin="none">
						Resident Discount
					</Heading>
					<Box align="end">
						<Heading margin="none" level="4" color="red">
							{formatCurrency(props.comparison - props.estimate)}
						</Heading>
					</Box>
					<Heading level="3" margin="none">
						Your Rego Estimate
					</Heading>
					<Box align="end">
						<Heading level="3" margin="none" color="green">
							{formatCurrency(props.estimate)}
						</Heading>
					</Box>
				</Grid>
				<Box flex direction={size === "small" ? "column" : "row"} gap="small">
					<Box gap="small">
						<Box direction="row" gap="small">

						</Box>
						<Box direction="row" gap="small">

						</Box>

					</Box>
					<Box align="end" justify="end" flex>
						<LoadingButton
							isLoading={props.isUpdating || isAnyUploading}
							type="submit"
							name="submit"
							label="Continue"
							color="accent-1"
							primary
							disabled={props.isDisabled || isAnyUploading || (uploadState.length <= 0 && props.media.length <= 0)}
							onClick={props.onClick}
						/>
					</Box>
				</Box>
			</Box>
		</DashboardCard>
	);
};