export const UtilService = {
	formatPhoneToNumber(input: string): string {
		input = input.replace(/\D/g, "");
		if(input.length <= 9 || input.length >= 12) {
			throw new Error(`Invalid phone [${input}]`);
		}

		if(input.length === 10 && input.charAt(0) === "1") {
			throw new Error(`Invalid phone [${input}]`);
		}

		if(input.length === 10) {
			return "1" + input;
		}

		return input;
	},

	formatPhoneToDisplay(input: string): string {
		input = input.replace(/\D/g, "");
		if(input.length <= 9 || input.length >= 12) {
			throw new Error(`Invalid phone [${input}]`);
		}

		const regex = /(\d{3})(\d{3})(\d{4})/;
		input = (input.length === 10)
			? input
			: input.slice(1);

		const matches = input.match(regex);
		if(!matches) {
			throw new Error(`Could not validate phone [${input}]`);
		}

		return `1 (${matches[1]}) ${matches[2]}-${matches[3]}`;
	}
};