import { Box, DateInput, FormField, Heading, Page, PageContent, Select, Text, TextInput } from "grommet";
import { useState } from "react";
import { getPickupWindowLabel, pickupWindows } from "../../dashboard/helpers";
import { LoadingButton, RightExpandableSidebarModal, RightSidebarModalContext } from "../../common";

interface ScheduledPickupSidebarProps {
	onClose: () => void;
	pickupId: string;
	isScheduling: boolean;
	onScheduled: (date: string, from: number, to: number) => void;
	scheduledDate?: string;
	scheduledWindowFrom?: number;
	scheduledWindowTo?: number;
}

export const ScheduledPickupSidebar: React.FC<ScheduledPickupSidebarProps> = (props) => {
	const [date, setDate] = useState(props.scheduledDate);
	const [window, setWindow] = useState(
		getPickupWindowLabel(props.scheduledWindowFrom ?? 0, props.scheduledWindowTo ?? 0)
	);

	return (
		<RightExpandableSidebarModal
			onClose={props.onClose}
		>
			<RightSidebarModalContext.Consumer>
				{(isExpanded) => (
					<Page kind="narrow">
						<PageContent>
							<Box gap="small">
								<Box align="center">
									<Heading margin="none" level="2">{props.scheduledDate ? "Reschedule Pickup" : "Schedule Pickup"}</Heading>
								</Box>
								<FormField
									label="Pickup Id"
								>
									<TextInput
										readOnly
										disabled
										value={props.pickupId}
									/>
								</FormField>
								<FormField
									label="Scheduled Date"
									name="date"
								>
									<DateInput
										name="date"
										value={date}
										format={"mm/dd/yyyy"}
										calendarProps={{
											animate: true,
											firstDayOfWeek: 0,
											showAdjacentDays: "trim"
										}}
										dropProps={{

										}}
										onChange={(e) => {
											setDate(Array.isArray(e.value) ? e.value[0] : e.value);
										}}
									/>
								</FormField>
								<FormField
									label="Scheduled Window"
									name="window"
									onChange={(e) => setWindow(e.target.value)}
								>
									<Select
										name="window"
										value={window}
										options={pickupWindows.map(w => w.label)}
									/>
								</FormField>
								<Box margin={{ top: "medium" }} align="end">
									<LoadingButton
										primary
										color="accent-1"
										label={props.scheduledDate ? "Reschedule Pickup" : "Schedule Pickup"}
										isLoading={props.isScheduling}
									/>
								</Box>
								<Text margin="small">
									All scheduled customers will be notified of their new pickup date and time by email.
								</Text>
							</Box>
						</PageContent>
					</Page>
				)}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};