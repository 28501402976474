import { Chip } from "@mui/material";
import { Anchor, Box, Text } from "grommet";
import { Home } from "grommet-icons";

type PickupStatus =
	| "SCHEDULED"
	| "IN_PROGRESS"
	| "COMPLETED";

interface ResidentPickupCardProps {
	name: string;
	unit: string;
	items: number;
	status: PickupStatus;
	date: string;
	onClick: () => void;
	onClickResidence?(residenceId: string): void;
	residenceId?: string;
	residenceName?: string;
}

export const ResidentPickupCard: React.FC<ResidentPickupCardProps> = (props) => {
	return (
		<Box
			round
			elevation="medium"
			pad="medium"
			gap="small"
			hoverIndicator
			background="white"
			onClick={props.onClick}
		>
			<Box direction="row" gap="small">
				<Box align="start">
					<Chip color={props.status === "SCHEDULED" ? "error" : props.status === "IN_PROGRESS" ? "warning" : "success"} />
				</Box>
				<Box>
					<Text weight="bold">{props.name}</Text>
					<Text>{props.unit}</Text>
				</Box>
				<Box align="end" justify="start" flex>
					<Text weight="bold">{props.items} item(s)</Text>
					<Text weight="bold">{props.date}</Text>
				</Box>
			</Box>
			{!!props.residenceName && (
				<Box direction="row" align="center" gap="small">
					<Home />
					<Anchor
						style={{ position: "relative", top: ".2em" }}
						label={<Text weight="bold">{props.residenceName}</Text>}
						onClick={(event) => {
							if(props.onClickResidence && props.residenceId) {
								event.stopPropagation();
								props.onClickResidence(props.residenceId);
							}
						}}
					/>
					<Box flex align="end">
						<Text weight="bold" style={{ letterSpacing: "3px" }}>
							...
						</Text>
					</Box>
				</Box>
			)}
		</Box>
	);
};