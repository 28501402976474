import { Box, Button, FormField, FormFieldExtendedProps, FormFieldProps, TextInput, TextInputProps } from "grommet";
import { Copy } from "grommet-icons";
import { useSnackbar } from "notistack";
import { handleCopyToClipboard, parseDateFromUTC, parseTimestampFromUTC } from "../../../helpers";
import { useTimezone } from "../hooks";

interface LocalDateFieldProps extends Omit<FormFieldExtendedProps, "value"> {
	icon?: TextInputProps["icon"];
	value?: string | Date | null | undefined;
	plain?: boolean;
	omitTimestamp?: boolean;
}

export const LocalDateField: React.FC<LocalDateFieldProps> = (props) => {
	const timezone = useTimezone();

	return (
		<FormField
			{...props as FormFieldProps}
			label={props.label}
			help={props.help}
			info={props.info}
		>
			<TextInput
				disabled
				plain={props.plain}
				icon={props.icon ?? undefined}
				value={props.value ? (props.omitTimestamp) ? parseDateFromUTC(props.value, timezone) : parseTimestampFromUTC(props.value, timezone) : ""}
			/>
		</FormField>
	);
};

interface FieldWithActionButtonProps {
	plain?: boolean;
	name?: string;
	label?: string;
	value: string;
	icon: JSX.Element;
	onClick(): void;
	disabled?: boolean;
}

export const FieldWithActionButton: React.FC<FieldWithActionButtonProps> = (props) => {
	return (
		<FormField
			contentProps={props.plain ? { border: undefined } : undefined}
			name={props.name}
			label={props.label}
		>
			<Box direction="row" flex style={{ maxHeight: "44px" }}>
				<TextInput
					plain
					value={props.value}
					disabled
					style={{ height: "100%" }}
					name={props.name}
				/>
				<Button
					style={{ paddingLeft: "12px", paddingRight: "12px" }}
					icon={props.icon}
					hoverIndicator
					disabled={props.disabled}
					onClick={props.onClick}
				/>
			</Box>
		</FormField>
	);
};

export const CopyToClipboardField: React.FC<Omit<FieldWithActionButtonProps, "icon" | "onClick">> = (props) => {
	const snack = useSnackbar();

	function handleOnClick(): void {
		handleCopyToClipboard(props.value, snack);
	}

	return (
		<FieldWithActionButton
			plain={props.plain}
			name={props.name}
			label={props.label}
			value={props.value}
			icon={<Copy />}
			disabled={props.disabled}
			onClick={handleOnClick}
		/>
	);
};