import { Box, Form, FormField, Grid, Heading, Page, PageContent, Spinner, TextInput } from "grommet";
import { useEffect, useState } from "react";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../app/store";
import { GoogleContext, Loader, withGoogle } from "../../common";
import { useAddressAutocomplete, useWindowDimensions } from "../../common/hooks";
import { GeoLocation } from "@rego-app/schema";
import { QueryResidenceByAddress } from "../../../app/services/request/gql";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

const BuildingSearchPage: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const addressAutoComplete = useAddressAutocomplete();
	const [queryResidence, { data: residenceData, loading: isLoadingResidence, error: residenceError }] = useLazyQuery(QueryResidenceByAddress);

	const [state, setState] = useState({
		addressLineOne: "",
		city: "",
		state: "",
		zip: "",
		zipPlusFour: "",
		latitude: 0,
		longitude: 0
	});

	function handleAddressSelected(address: GeoLocation): void {
		setState({
			...state,
			addressLineOne: address.addressLineOne ?? state.addressLineOne,
			city: address.city ?? state.city,
			state: address.state ?? state.state,
			zip: address.zip ?? state.zip,
			latitude: address.latitude ?? state.latitude,
			longitude: address.longitude ?? state.longitude,
		});

		handleResidenceSearch(address);
	}

	function handleResidenceSearch(address: GeoLocation): void {
		queryResidence({
			variables: {
				addressLineOne: address.addressLineOne.toUpperCase(),
				zip: address.zip
			}
		});
	}

	useEffect(() => {
		if(residenceData?.QueryResidenceByAddress) {
			const residence = residenceData.QueryResidenceByAddress[0];
			if(!residence) {
				snack.enqueueSnackbar("We were unable to find a residence for the address you entered. Please try again.", { variant: "error" });
				return;
			}

			dispatch(push(`/landing/?code=${residence.code}`));
		}
	}, [residenceData]);

	useEffect(() => {
		if(addressAutoComplete.ready) {
			addressAutoComplete.initialize(
				document.querySelector("#address_line_one") as HTMLInputElement,
				(address) => {
					handleAddressSelected(address);
				}
			);
		}
	}, [addressAutoComplete.ready]);

	return (
		<Page kind="narrow">
			<PageContent>
				<Box gap="small">
					<Box align="center">
						<Heading level="2">Building Lookup</Heading>
					</Box>
					<GoogleContext.Consumer>
						{isLoaded => (
							<Loader visible={!isLoaded}>
								<Form
									value={state}
									onChange={nextValue => setState(nextValue)}
									onSubmit={() => { }}
									validate="submit"
								>
									<Box gap="medium">
										<Box gap="small">
											<FormField
												label="Street Address"
												name="addressLineOne"

											>
												<TextInput
													icon={isLoadingResidence ? <Spinner /> : undefined}
													name="addressLineOne"
													id="address_line_one"
												/>
											</FormField>
										</Box>
										<Grid columns={size === "small" ? "100%" : ["1/2", "1/4", "1/4"]} gap="medium">
											<FormField
												label="City"
												name="city"
											>
												<TextInput
													name="city"
												/>
											</FormField>
											<FormField
												label="State"
												name="state"
											>
												<TextInput
													name="state"
												/>
											</FormField>
											<FormField
												label="Zip"
												name="zip"
											>
												<TextInput
													name="zip"
												/>
											</FormField>
										</Grid>
									</Box>
								</Form>
							</Loader>
						)}
					</GoogleContext.Consumer>
				</Box>
			</PageContent>
		</Page>
	);
};

const WrappedBuildingSearchPage = withGoogle(BuildingSearchPage);
export { WrappedBuildingSearchPage as BuildingSearchPage };