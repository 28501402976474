import { RightExpandableSidebarModal, RightSidebarModalContext } from "../../../common";
import { PickupDetails } from "../../../partner";
import { PickupPreview } from "./PickupPreview";

interface PickupSidebarProps {
	serviceId: string;
	onClose: () => void;
}

export const PickupSidebar: React.FC<PickupSidebarProps> = (props) => {
	return (
		<RightExpandableSidebarModal
			onClose={props.onClose}
		>
			<RightSidebarModalContext.Consumer>
				{(isExpanded) => {
					return (isExpanded)
						? (
							<PickupDetails />
						)
						: (
							<PickupPreview />
						);
				}}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};