import { useLazyQuery } from "@apollo/client";
import { Box, Grid, Heading, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { GetHaulerAvailablePickups, GetHaulerAwardedPickups } from "../../../../app/services/request/partner";
import { VehicleType } from "../../../../__generated__/graphql";
import { DashboardCard, Loader, RightExpandableSidebarModal, RightSidebarModalContext } from "../../../common";
import { useWindowDimensions } from "../../../common/hooks";
import { AdminControls, HaulerUsersCard, HaulerVehiclesCard, RouteCard } from "../components";
import { HaulerDetailsCard } from "../components/HaulerDetailsCard";

interface HaulerDashboardProps {
	isLoading: boolean;
	haulerId: string;
	haulerName: string;
	vehicles: {
		id: string;
		type: VehicleType;
		name: string;
	}[];
	handleRefetch(): void;
}

export const HaulerDashboard: React.FC<HaulerDashboardProps> = (props) => {
	const { size } = useWindowDimensions();

	return (
		<Box gap="small">
			<AdminControls
				isLoading={props.isLoading}
				selectedHaulerName={props.haulerName}
			/>
			<Heading level="2" margin="none">Hauler Dashboard</Heading>
			<Grid columns={{ count: ["small", "medium"].includes(size) ? 1 : 2, size: "auto" }} gap="small">
				<HaulerDetailsCard
					isLoading={props.isLoading}
					id={props.haulerId}
					name={props.haulerName}
				/>
				<HaulerVehiclesCard
					haulerId={props.haulerId}
					isLoading={props.isLoading}
					name={props.haulerName}
					vehicles={props.vehicles}
					handleRefetch={props.handleRefetch}
				/>
			</Grid>
			<HaulerUsersCard
				isLoading={props.isLoading}
				users={[
					{
						firstName: "Brandon 1",
						lastName: "Castagna 1",
						emailAddress: "bcastagna@regoapp.io",
						roles: ["partner.admin"]
					},
					{
						firstName: "Brandon 2",
						lastName: "Castagna 2",
						emailAddress: "bcastagna@regoapp.io",
						roles: ["partner.writer"]
					},
					{
						firstName: "Brandon 3",
						lastName: "Castagna 3",
						emailAddress: "bcastagna@regoapp.io",
						roles: ["partner.reader"]
					}
				]}
			/>
			<RouteController
				haulerId={props.haulerId}
				isLoading={props.isLoading}
			/>
			<AwardedPickupsContainer
				haulerId={props.haulerId}
				isLoading={props.isLoading}
			/>
		</Box>
	);
};

interface RouteControllerProps {
	haulerId: string;
	isLoading: boolean;
}

export const RouteController: React.FC<RouteControllerProps> = (props) => {
	const [selectedPickup, setSelectedPickups] = useState("");
	const [execute, { data, loading }] = useLazyQuery(GetHaulerAvailablePickups, {
		variables: { haulerId: props.haulerId }
	});

	useEffect(() => {
		if(props.haulerId) {
			execute({ variables: { haulerId: props.haulerId } });
		}
	}, [props.haulerId]);

	const pickups = useMemo(() => data?.GetAvailablePickups ?? [], [data]);

	return (
		<DashboardCard>
			{selectedPickup && (
				<PickupDetailsModal
					onClose={() => setSelectedPickups("")}
				/>
			)}
			<Box gap="small">
				<Heading level="3" margin="none">Available Pickups</Heading>
				<Loader visible={loading || props.isLoading}>
					<Box gap="small">
						{pickups.map(pickup => (
							<RouteCard
								key={pickup.id}
								stopTo={3}
								stopsFrom={2}
								quoted={pickup.quotes.length > 0}
								invoiced={pickup.quotes.some(q => q.converted)}
								onClick={() => setSelectedPickups(pickup.id)}
							/>
						))}
					</Box>
					{pickups.length === 0 && (
						<Box margin="small" align="center" justify="center">
							<Text>no available pickups found</Text>
						</Box>
					)}
				</Loader>
			</Box>
		</DashboardCard>
	);
};

interface AwardedPickupsContainerProps {
	haulerId: string;
	isLoading: boolean;
}

export const AwardedPickupsContainer: React.FC<AwardedPickupsContainerProps> = (props) => {
	const [selectedPickup, setSelectedPickups] = useState("");

	const [execute, { data, loading }] = useLazyQuery(GetHaulerAwardedPickups, {
		variables: { haulerId: props.haulerId }
	});

	useEffect(() => {
		if(props.haulerId) {
			execute({ variables: { haulerId: props.haulerId } });
		}
	}, [props.haulerId]);

	const pickups = useMemo(() => data?.GetAwardedPickups ?? [], [data]);

	return (
		<DashboardCard>
			{selectedPickup && (
				<PickupDetailsModal
					onClose={() => setSelectedPickups("")}
				/>
			)}
			<Box gap="small">
				<Heading level="3" margin="none">Scheduled Routes</Heading>
				<Loader visible={loading || props.isLoading}>
					<Box gap="small">
						{pickups.map(pickup => (
							<RouteCard
								key={pickup.id}
								stopTo={3}
								stopsFrom={2}
								quoted={pickup.quotes.length > 0}
								invoiced={pickup.quotes.some(q => q.converted)}
								onClick={() => setSelectedPickups(pickup.id)}
							/>
						))}
					</Box>
					{pickups.length === 0 && (
						<Box margin="small" align="center" justify="center">
							<Text>no scheduled pickups found</Text>
						</Box>
					)}
				</Loader>
			</Box>
		</DashboardCard>
	);
};


export const PickupDetails: React.FC = (props) => {
	return (
		<Box>
			IM DETAILS
		</Box>
	);
};

export const PickupSummary: React.FC = (props) => {
	return (
		<Box>
			IM A SUMMARY
		</Box>
	);
};

interface PickupDetailsModalProps {
	onClose(): void;
}

export const PickupDetailsModal: React.FC<PickupDetailsModalProps> = (props) => {
	return (
		<RightExpandableSidebarModal
			onClose={props.onClose}
		>
			<RightSidebarModalContext.Consumer>
				{(isExpanded) => {
					return (isExpanded)
						? (
							<PickupDetails />
						)
						: (
							<PickupSummary />
						);
				}}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};