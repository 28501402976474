import { Anchor, Box, Spinner, Stack, Text } from "grommet";
import moment from "moment";
import { useState } from "react";
import { sortByScheduledWindow, windowToMoment } from "../../../../helpers";
import { DashboardCard, Loader, RightExpandableSidebarModal } from "../../../common";
import { SchedulePickupButton, useBeginScheduleFlow } from "../../resident/components";

interface ResidencePickupCardProps {
	isLoading: boolean;
	omitCallToAction?: boolean;
	nextPickup: { date: string, timezone: string, from: number, to: number; } | null;
	scheduledPickups: {
		id: string;
		scheduledWindow: {
			to: number;
			from: number;
			date: string;
			timezone: string;
		};
	}[];
}

interface PickupDetailsItemProps {
	id: string;
	scheduledWindow: { from: number, to: number, date: string, timezone: string; };
	omitCallToAction?: boolean;
}

export const PickupDetailsItem: React.FC<PickupDetailsItemProps> = (props) => {
	const { loading, begin } = useBeginScheduleFlow();

	return (
		<Box flex style={{ display: "inline-table" }}>
			<Stack>
				{loading && (
					<Box flex align="end" justify="center" fill pad="small">
						<Box align="center" justify="center" style={{ zIndex: 10 }}>
							<Spinner />
						</Box>
					</Box>
				)}
				<Box pad="small" background="light-2" hoverIndicator onClick={() => props.omitCallToAction ? undefined : begin(props.id)} align="center" justify="center">
					<Text weight="bold">
						{windowToMoment(props.scheduledWindow).format("MMMM Do")} ({moment().hours(props.scheduledWindow.from).format("hh A")} - {moment().hours(props.scheduledWindow.to).format("hh A")})
					</Text>
				</Box>
			</Stack>
		</Box>
	);
};

interface PickupDateSidebarProps {
	onClose(): void;
	omitCallToAction?: boolean;
	scheduledPickups: {
		id: string;
		scheduledWindow: {
			to: number;
			from: number;
			date: string;
			timezone: string;
		};
	}[];
}

export const PickupDateSidebar: React.FC<PickupDateSidebarProps> = (props) => {
	return (
		<RightExpandableSidebarModal
			onClose={props.onClose}
			blockExpand={true}
		>
			<Box gap="medium">
				<Box align="center">
					<Text weight="bold">Available Pickup Dates</Text>
				</Box>
				<Box gap="small" flex width="medium" style={{ overflow: "scroll" }}>

					{props.scheduledPickups.sort(sortByScheduledWindow).map(p => (
						<PickupDetailsItem
							key={p.id}
							id={p.id}
							scheduledWindow={p.scheduledWindow}
							omitCallToAction={props.omitCallToAction}
						/>
					))}
					{props.scheduledPickups.length === 0 && (
						<Box pad="medium" align="center">
							<Text>no scheduled pickups found</Text>
						</Box>
					)}
				</Box>
			</Box>
		</RightExpandableSidebarModal>
	);
};

export const ResidenceScheduledPickupCard: React.FC<ResidencePickupCardProps> = (props) => {
	const [showSidebar, setShowSidebar] = useState(false);
	return (
		<DashboardCard>
			{showSidebar && (
				<PickupDateSidebar
					onClose={() => setShowSidebar(false)}
					scheduledPickups={props.scheduledPickups}
					omitCallToAction={props.omitCallToAction}
				/>
			)}
			<Box align="center" gap="small" flex>
				<Text textAlign="center" size="large" weight="bold">Available Pickup Dates in Your Building</Text>
				<Box flex>
					<Loader visible={props.isLoading}>
						<Box gap="small" flex width="medium">
							{props.scheduledPickups.sort(sortByScheduledWindow).slice(0, 3).map(p => (
								<PickupDetailsItem
									key={p.id}
									id={p.id}
									scheduledWindow={p.scheduledWindow}
									omitCallToAction={props.omitCallToAction}
								/>
							))}
							{props.scheduledPickups.length === 0 && (
								<Box pad="medium" align="center">
									<Text>no scheduled pickups found</Text>
								</Box>
							)}
						</Box>
					</Loader>
				</Box>
				{props.scheduledPickups.length > 3 && (
					<Box align="center">
						<Anchor
							color="accent-1"
							label="View All Pickup Dates"
							onClick={() => setShowSidebar(true)}
						/>
					</Box>
				)}
				{!props.omitCallToAction && (
					<Box gap="small" align="center" justify="end">
						<Box align="end">
							<SchedulePickupButton />
						</Box>
					</Box>
				)}
			</Box>
		</DashboardCard>
	);
};