import { useMutation } from "@apollo/react-hoc";
import { Form, Heading, Box, Grid, FormField, TextInput, Spinner } from "grommet";
import { useSnackbar } from "notistack";
import { useState, useEffect, useMemo } from "react";
import { UpdateCheckoutSessionContactInformation } from "../../../../../app/services/request/gql";
import { UtilService } from "../../../../../app/services/util";
import { useAppSelector } from "../../../../../app/store";
import { selectUser } from "../../../../../app/store/application";
import { getStandardFormValidations } from "../../../../../helpers";
import { ContactType } from "../../../../../__generated__/graphql";
import { CreateContactModal } from "../../../../auth";
import { useWindowDimensions, DashboardCard, WrapSkeletonFormField, LoadingButton } from "../../../../common";
import { CheckoutSessionProps } from "./common";

interface SchedulePickupContactStepProps extends CheckoutSessionProps {
	firstName: string;
	lastName: string;
	emailAddress: string;
	isEmailVerified: boolean;
	handleRefetch(): void;
}

export const CheckoutSessionContactStep: React.FC<SchedulePickupContactStepProps> = (props) => {
	const snack = useSnackbar();
	const user = useAppSelector(selectUser);
	const { size } = useWindowDimensions();

	const [updateContactInfoMutation, { loading }] = useMutation(UpdateCheckoutSessionContactInformation);

	const [formState, setFormState] = useState({
		firstName: props.firstName,
		lastName: props.lastName,
		phoneNumber: props.phoneNumber,
		emailAddress: props.emailAddress,
		emailContactId: "",
		isEmailVerified: props.isEmailVerified,
		isCreatingContact: false,
		isVerifyingContact: false
	});

	useEffect(() => {
		const newState = { ...formState };
		if(props.firstName) {
			newState.firstName = props.firstName;
		}

		if(props.lastName) {
			newState.lastName = props.lastName;
		}

		if(props.emailAddress) {
			newState.emailAddress = props.emailAddress;
		}

		if(props.phoneNumber) {
			newState.phoneNumber = props.phoneNumber;
		}

		setFormState({ ...newState });
	}, [props]);

	const columns = useMemo(() => size === "small" ? 1 : 2, [size]);

	function contactVerifyInterrupt(): boolean {
		if(!props.isEmailVerified || !props.emailAddress) {
			const existing = user?.contacts.find((contact) => contact.type === "EMAIL");
			if(existing) {
				setFormState({
					...formState,
					isVerifyingContact: true,
					emailContactId: existing.id,
					emailAddress: existing.value
				});

				return false;
			}

			setFormState({
				...formState,
				isCreatingContact: true
			});

			return false;
		}

		return true;
	}

	function handleFormSubmit(): void {
		if(!contactVerifyInterrupt()) {
			return;
		}

		const { sessionId, phoneNumber } = props;
		const { firstName, lastName } = formState;

		updateContactInfoMutation({
			variables: { sessionId, phoneNumber, firstName, lastName }
		}).then(() => {
			props.onComplete();
		}).catch(err => {
			console.error("Failed to update contact info", err);
			snack.enqueueSnackbar("We ran into an issue updating your information", { variant: "error" });
		});
	}

	return (
		<DashboardCard>
			<Form
				value={formState}
				onSubmit={handleFormSubmit}
				onChange={(changes) => {
					setFormState({
						...formState,
						...changes
					});
				}}
			>
				{(formState.isVerifyingContact && user) && (
					<CreateContactModal
						userId={user.id}
						contactId={formState.emailContactId}
						value={formState.emailAddress}
						type={ContactType.Email}
						onClose={(_wasCreated, _wasVerified) => {
							setFormState({
								...formState,
								isVerifyingContact: false
							});

							props.handleRefetch();
						}}
					/>
				)}
				{(formState.isCreatingContact && user) && (
					<CreateContactModal
						userId={user.id}
						contactId={formState.emailContactId}
						value={formState.emailAddress}
						type={ContactType.Email}
						onClose={(_wasCreated, _wasVerified) => {
							setFormState({
								...formState,
								isCreatingContact: false
							});

							props.handleRefetch();
						}}
					/>
				)}
				<Heading level="3">
					Contact Information
				</Heading>
				<Box gap="medium">
					<Box gap="small">
						<Grid columns={{ count: columns, size: "auto" }} gap="small">
							<WrapSkeletonFormField
								isLoading={props.isLoading}
								name="firstName"
								label="First Name"
								validate={[
									...getStandardFormValidations()
								]}
							/>
							<WrapSkeletonFormField
								isLoading={props.isLoading}
								name="lastName"
								label="Last Name"
								validate={[
									...getStandardFormValidations()
								]}
							/>
						</Grid>
						<Grid columns={{ count: columns, size: "auto" }} gap="small">
							<FormField
								name="phoneNumber"
								label="Phone Number"
							>
								<TextInput
									readOnly
									disabled
									name="phoneNumber"
									icon={props.isLoading ? <Spinner /> : undefined}
									value={UtilService.formatPhoneToDisplay(props.phoneNumber)}
								/>
							</FormField>
							<Box>
								<FormField
									readOnly
									name="emailAddress"
									label="Email Address"
									validate={[
										...getStandardFormValidations()
									]}
								>
									<TextInput
										readOnly
										icon={props.isLoading ? <Spinner /> : undefined}
										name="emailAddress"
										value={props.emailAddress}
										onClick={() => {
											contactVerifyInterrupt();
										}}
									/>
								</FormField>
							</Box>
						</Grid>
					</Box>
					<Box flex align="end" justify="end">
						<LoadingButton
							primary
							color="accent-1"
							isLoading={loading || props.isLoading}
							label="Continue"
							type="submit"
						/>
					</Box>
				</Box>
			</Form>
		</DashboardCard>
	);
};