import { Chip } from "@mui/material";
import { PartnerUserRole } from "@rego-app/schema";
import { Box, ColumnConfig, DataTable, Heading } from "grommet";
import { useMemo } from "react";
import { useWindowDimensions, DashboardCard } from "../../../common";
import { isAdmin, isReader, isWriter } from "../../helpers";

interface HaulerUsersCardProps {
	isLoading: boolean;
	users: {
		firstName: string;
		lastName: string;
		emailAddress: string;
		roles: PartnerUserRole[];
	}[];
}

export const HaulerUsersCard: React.FC<HaulerUsersCardProps> = (props) => {
	return (
		<DashboardCard>
			<Box gap="small">
				<Heading margin="none" level="2">Hauler Users</Heading>
				<HaulerUserTable
					users={props.users}
				/>
			</Box>
		</DashboardCard>
	);
};

interface HaulerUserCardProps {
	users: {
		firstName: string;
		lastName: string;
		emailAddress: string;
		roles: PartnerUserRole[];
	}[];
}

const extenderUserTableColumns: ColumnConfig<HaulerUserCardProps["users"][0]>[] = [
	{
		property: "firstName",
		header: "First Name",
		primary: false,
		render: (user) => user.firstName
	},
	{
		property: "lastName",
		header: "Last Name",
		primary: false,
		render: (user) => user.lastName
	}
];

const userTableColumns: ColumnConfig<HaulerUserCardProps["users"][0]>[] = [
	{
		property: "emailAddress",
		header: "Email Address",
		primary: true,
		render: (user) => user.emailAddress
	},
	{
		property: "roles",
		header: "Role",
		primary: false,
		size: "xsmall",
		align: "center",
		render: (user) => (
			<Box align="end" justify="center">
				{isAdmin(user.roles) && (
					<Box width="xsmall">
						<Chip
							label="Admin"
							color="success"
						/>
					</Box>
				)}
				{(isWriter(user.roles) && !isAdmin(user.roles)) && (
					<Box width="xsmall">
						<Chip
							label="Writer"
							color="primary"
						/>
					</Box>
				)}
				{(isReader(user.roles) && !isAdmin(user.roles) && !isWriter(user.roles)) && (
					<Box width="xsmall">
						<Chip
							label="Reader"
							color="primary"
						/>
					</Box>
				)}
			</Box>
		)
	}
];

export const HaulerUserTable: React.FC<HaulerUserCardProps> = (props) => {
	const { size } = useWindowDimensions();

	const columns = useMemo(() => {
		if(size === "small") {
			return [
				...userTableColumns
			];
		}

		return [
			...extenderUserTableColumns,
			...userTableColumns
		];

	}, [size]);
	return (
		<DataTable
			data={props.users}
			columns={columns}
			onClickRow={(event) => {

			}}
		/>
	);
};