import React, { createContext, useContext, useEffect, useState } from "react";
const API_KEY = "AIzaSyD1sJq5iy4B_fqjCRoyprPWnpxdJu9hJtY";

export const GoogleContext = createContext(false);

export const GoogleProvider: React.FC = (props) => {
	const [isLoaded, setLoaded] = useState(false);

	useEffect(() => {
		console.debug("IN GOOGLE PROVIDER EFFECT");
		if(!window.google) {
			const script = document.createElement("script");
			const body = document.getElementsByTagName('body')[0];
			script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=googleCallbackFunction`;
			body.appendChild(script);
			script.addEventListener("load", () => {
				console.debug("Setting google loaded (from listener)!");
				setLoaded(true);
			});
		}

		if(window.google) {
			console.debug("Setting google loaded (from global google)!");
			setLoaded(true);
		}
	}, []);

	return (
		<GoogleContext.Provider value={isLoaded}>
			{props.children}
		</GoogleContext.Provider>
	);
};

interface GoogleHookProps {
	requestLocation?: boolean;
}

export const useGoogle = (props?: GoogleHookProps) => {
	const isGoogleLoaded = useContext(GoogleContext);
	const [currentLocation, setCurrentLocation] = useState<google.maps.LatLng | null>(null);

	useEffect(() => {
		if(navigator.geolocation && isGoogleLoaded && props?.requestLocation && !currentLocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				const pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				setCurrentLocation(new google.maps.LatLng(pos["lat"], pos["lng"]));
			});
		}
	}, [props, isGoogleLoaded]);

	return {
		isLoaded: isGoogleLoaded,
		currentLocation
	};
};

export const withGoogle = (Component: React.FC) => {
	const Google = (props: any) => {
		return (
			<GoogleProvider>
				<Component {...props} />
			</GoogleProvider>
		);

	};

	return Google;
};