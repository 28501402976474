import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItemClassificationModel } from "@rego-app/schema";
import { RootState } from "./index";

export interface DashboardState {
	isLoadingClassifications: boolean;
	itemClassifications: ItemClassificationModel[];
}

const initialState: DashboardState = {
	isLoadingClassifications: false,
	itemClassifications: []
};


interface UserRegistrationRequest {
	firstName: string;
	lastName: string;
	email: string;
	unit: string;
	buildingCode: string;
}

export const registerUser = createAsyncThunk<boolean, UserRegistrationRequest, { state: RootState; }>(
	"application/registerUser",
	async (request, thunk) => {
		return new Promise<boolean>((resolve, reject) => {
			setTimeout(() => {
				if(request.buildingCode === "1112") return reject(new Error());
				return resolve(true);
			}, 2000);
		});
	}
);

export const dashboardSlice = createSlice({
	name: "application",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setIsLoadingItemClassifications: (state, action: PayloadAction<boolean>) => {
			state.isLoadingClassifications = action.payload;
		},
		setItemClassifications: (state, action: PayloadAction<ItemClassificationModel[]>) => {
			state.itemClassifications = action.payload;
		}
	}
});

export const {
	setIsLoadingItemClassifications,
	setItemClassifications
} = dashboardSlice.actions;


export const selectIsLoadingItemClassifications = (state: RootState): boolean => state.dashboard.isLoadingClassifications;
export const selectItemClassifications = (state: RootState): ItemClassificationModel[] => state.dashboard.itemClassifications;

export default dashboardSlice.reducer;