import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

export interface AdminState {
	impersonateUserId: string;
	impersonatePartnerId: string;
}

const initialState: AdminState = {
	impersonateUserId: "",
	impersonatePartnerId: ""
};

export const adminSlice = createSlice({
	name: "admin",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setImpersonateUserId: (state, action: PayloadAction<string>) => {
			state.impersonateUserId = action.payload;
		},
		setImpersonatePartnerId: (state, action: PayloadAction<string>) => {
			state.impersonatePartnerId = action.payload;
		}
	}
});

export const {
	setImpersonateUserId,
	setImpersonatePartnerId
} = adminSlice.actions;

export const selectImpersonateUserId = (state: RootState): string => state.admin.impersonateUserId;;
export const selectImpersonatePartnerId = (state: RootState): string => state.admin.impersonatePartnerId;;

export default adminSlice.reducer;