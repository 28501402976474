import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { GetHauler } from "../../../../app/services/request/partner";
import { useAppSelector } from "../../../../app/store";
import { selectImpersonatePartnerId } from "../../../../app/store/admin";
import { selectUser } from "../../../../app/store/application";
import { isHauler } from "../helpers";
import { HaulerDashboard } from "../pages";

export const HaulerController: React.FC = (props) => {
	const user = useAppSelector(selectUser);
	const [execute, { data, loading, error, refetch }] = useLazyQuery(GetHauler);
	const impersonatePartnerId = useAppSelector(selectImpersonatePartnerId);

	useEffect(() => {

	}, []);

	const hauler = useMemo(() => data?.GetHauler, [data]);

	useEffect(() => {
		if(impersonatePartnerId) {
			refetch({ haulerId: impersonatePartnerId });
		}
	}, [impersonatePartnerId]);

	useEffect(() => {
		if(user?.config?.defaultPartner && isHauler(user?.config?.defaultPartner)) {
			execute({ variables: { haulerId: user.config.defaultPartner.id } });
		}
	}, [user]);

	return (
		<HaulerDashboard
			isLoading={loading}
			haulerId={hauler?.id ?? ""}
			haulerName={hauler?.name ?? ""}
			vehicles={hauler?.vehicles ?? []}
			handleRefetch={() => refetch({ haulerId: hauler?.id ?? "" })}
		/>
	);
};