import { Button, ButtonExtendedProps, Spinner } from "grommet";

interface LoadingButtonProps extends ButtonExtendedProps {
	isLoading: boolean;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
	return (
		<Button
			{...props}
			icon={props.isLoading ? <Spinner /> : props.icon}
			disabled={props.disabled || props.isLoading}
		/>
	);
};