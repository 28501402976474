import { Route, RouteProps, Routes } from "react-router";
import { CommonRoutes } from "../../common";
import { BuildingDashboardController } from "./controller";
import { SavingsCalculator } from "./pages";

export const ResidentManagerRoutes: React.ReactElement<RouteProps>[] = [
	...CommonRoutes,
	<Route key="dashboard" path="/dashboard/*" element={<BuildingDashboardController />} />,
	<Route key="calculator" path="/calculator" element={<SavingsCalculator />} />,
];

export const ResidentManagerRouter: React.FC = (props) => {
	return (
		<Routes>
			{ResidentManagerRoutes}
		</Routes>
	);
};