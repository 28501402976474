import { Layer } from "grommet";
import { Modal } from "../../common";
import { AddPaymentMethodForm } from "./AddPaymentMethodForm";

interface AddPaymentMethodModalProps {
	onClose(): void;
}

export const AddPaymentMethodModal: React.FC<AddPaymentMethodModalProps> = (props) => {
	return (
		<Modal
			onCloseAll={props.onClose}
		>
			<AddPaymentMethodForm

			/>
		</Modal>
	);
};