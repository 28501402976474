import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Intercept requests and setup auth
 */
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
	//console.debug(`REQUEST ${config.method?.toUpperCase()} ${config.url} [${JSON.stringify(config.data)}]`);
	const accessToken = localStorage.getItem("accessToken");
	if(!accessToken) {
		console.info("No accessToken in storage");
		return config;
	}

	config.headers = {
		...(config.headers ?? {}),
		"Authorization": `Bearer ${accessToken}`
	};

	return config;
};


const responseHandler = (response: AxiosResponse): AxiosResponse => {
	//console.debug(`RESPONSE [${response.status}] ${response.config.method?.toUpperCase()} ${response.config.url} [${JSON.stringify(response.data)}]`);
	return response;
};

/**
 * Log error
 * 
 * Throw error from response if present so we can render friendly error messages
 */
const responseErrorHandler = (response: AxiosError): Promise<AxiosError> => {
	console.error("REQUEST FAILED!", response.toJSON());
	return Promise.reject(response.response?.data ?? response.response ?? response);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
	axiosInstance.interceptors.request.use(onRequest);
	axiosInstance.interceptors.response.use(
		(response) => responseHandler(response),
		(error) => responseErrorHandler(error)
	);
	return axiosInstance;
}