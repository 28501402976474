import { Box, Grid, Heading, Select } from "grommet";
import { useWindowDimensions } from "../../common/hooks";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../app/store";
import { toProperCase } from "../../../helpers";
import { DashboardCard, RightExpandableSidebarModal } from "../../common";
import { withAuth } from "../../auth";

export const ScheduledPickupCard: React.FC = (props) => {
	return (
		<DashboardCard>
			<Heading margin="none" level={3}>Scheduled Pickups</Heading>
		</DashboardCard>
	);
};

export const AdminDashboardController: React.FC = withAuth((props) => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [selectedPage, setSelectedPage] = useState("");

	function handleNavigate(page: string): void {
		dispatch(push(`/${page.toLowerCase()}`));
	}

	useEffect(() => {
		const path = location.pathname.split("/")[1];
		if(path) {
			setSelectedPage(toProperCase(path));
		}
	}, [location]);

	return (
		<Box gap="small">
			{/* <Box direction="row" margin="small">
				<Select
					options={["Customers", "Pickups", "Services"]}
					value={selectedPage}
					onChange={({ option }) => { handleNavigate(option); }}
				/>
			</Box> */}
			{isOpen && (
				<RightExpandableSidebarModal
					onClose={() => { setIsOpen(false); }}
				>
					<Box margin="small">
						SIDEBAR
					</Box>
				</RightExpandableSidebarModal>
			)}
			<Outlet />
		</Box>
	);
}, true);

export const AdminDashboard: React.FC = (props) => {
	const { size } = useWindowDimensions();

	return (
		<Grid
			gap="small"
			columns={{ count: ["small", "medium"].includes(size) ? 1 : 2, size: "auto" }}
		>
			<ScheduledPickupCard

			/>
			<ScheduledPickupCard

			/>
			<ScheduledPickupCard

			/>
			<ScheduledPickupCard

			/>
		</Grid>
	);
};