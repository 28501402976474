import { Box, Heading, Text } from "grommet";
import moment from "moment";
import { useState } from "react";
import { DashboardCard, WrapSkeleton } from "../../../common";
import { PickupCard } from "./PickupCard";
import { PickupSidebar } from "./PickupSidebar";

interface PickupCardContainerProps {
	isLoading: boolean;
	cardTitle: string;
	overrideCardHeight?: string;
	noRecordsMessage: string;
	services: {
		id: string;
		paid?: boolean;
		paidAt?: Date | null;
		scheduled?: boolean;
		completed?: boolean;
		completedAt?: Date | null;
		pickup?: {
			scheduled: boolean;
			scheduledDate?: string | null;
			scheduledWindowFrom?: number | null;
			scheduledWindowTo?: number | null;
			scheduledWindowTimezone?: string | null;
		} | null;
		productCount?: number | null;
		selectedEstimate?: {
			taxAmount: number;
			totalAmount: number;
			subtotalAmount: number;
		} | null;
	}[];
}

export const PickupCardContainer: React.FC<PickupCardContainerProps> = (props) => {
	const [selectedServiceId, setSelectedServiceId] = useState("");

	function handleServiceSelected(serviceId: string): void {
		// setSelectedServiceId(serviceId);
	}

	return (
		<DashboardCard height={"medium"}>
			{!!selectedServiceId && (
				<PickupSidebar
					serviceId={selectedServiceId}
					onClose={() => setSelectedServiceId("")}
				/>
			)}
			<Box gap="small">
				<Heading level="3" margin="none">{props.cardTitle}</Heading>
				<WrapSkeleton isLoading={props.isLoading}>
					<Box align="center" gap="small">
						{props.services.length > 0
							? props.services.map(service => (
								<PickupCard
									key={service.id}
									isLoading={props.isLoading}
									itemCount={service.productCount ?? 0}
									totalFee={service.selectedEstimate?.totalAmount ?? 0}
									onSelected={() => handleServiceSelected(service.id)}
									scheduledDateFormatted={(service.pickup?.scheduledDate && service.pickup.scheduledWindowTimezone)
										? moment.tz(service.pickup?.scheduledDate, service.pickup.scheduledWindowTimezone).format("MM/DD/YYYY")
										: ""
									}
								/>
							))
							: (
								<Text>{props.noRecordsMessage}</Text>
							)
						}
					</Box>
				</WrapSkeleton>
			</Box>
		</DashboardCard>
	);
};