import { ChildDataProps, useQuery, useLazyQuery } from "@apollo/react-hoc";
import moment from "moment";
import { useState, useEffect } from "react";
import { GetBuildingDashboardData, ListUserManagedResidences } from "../../../../app/services/request/gql";
import { GetResidenceQuery, ListUserManagedResidencesQuery } from "../../../../__generated__/graphql";

type Flatten<T> = T extends any[] ? T[number] : T;
type LikeResidenceArray = ChildDataProps<{}, ListUserManagedResidencesQuery>["data"]["ListUserManagedResidences"];
type LikeResidence = NonNullable<Flatten<LikeResidenceArray>>;
type LikeExtendedResidence = ChildDataProps<{}, GetResidenceQuery>["data"]["GetResidence"];

export function useBuildingDashboard() {
	const { data, loading } = useQuery(ListUserManagedResidences);
	const [execute, { data: activeResidenceData, loading: loadingActiveResidence }] = useLazyQuery(GetBuildingDashboardData);

	const [activeResidence, setActiveResidence] = useState<LikeExtendedResidence | null>(null);
	const [residences, setResidences] = useState<LikeResidence[]>([]);

	function handleUpdateActiveResidence(residenceId: string) {
		execute({
			variables: {
				residenceId: residenceId,
				scheduledForAfter: moment().subtract(7, "days").startOf("day").toDate()
			}
		});
	}

	useEffect(() => {
		if(data?.ListUserManagedResidences) {
			setResidences([...data.ListUserManagedResidences]);

			if(data.ListUserManagedResidences.length > 0) {
				const active = data.ListUserManagedResidences[0];
				execute({ variables: { residenceId: active.id, scheduledForAfter: moment().subtract(7, "days").startOf("day").toDate() } });
			}
		}
	}, [data]);

	useEffect(() => {
		if(activeResidenceData?.GetResidence) {
			setActiveResidence({ ...activeResidenceData.GetResidence });
		}
	}, [activeResidenceData]);

	return {
		loading: loading || loadingActiveResidence,
		residences,
		activeResidence,
		setActiveResidence: handleUpdateActiveResidence
	};
}