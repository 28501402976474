import { PartnerUserRole } from "@rego-app/schema";

export function isAdmin(roles: PartnerUserRole[]): boolean {
	return roles.some((role) => role.split(".")[0] === "partner" && role.split(".")[1] === "admin");
}

export function isWriter(roles: PartnerUserRole[]): boolean {
	return isAdmin(roles) || roles.some((role) => role.split(".")[0] === "partner" && role.split(".")[1] === "writer");
}

export function isReader(roles: PartnerUserRole[]): boolean {
	return isAdmin(roles) || isWriter(roles) || roles.some((role) => role.split(".")[0] === "partner" && role.split(".")[1] === "reader");
}