import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import applicationReducer from "./application";
import configReducer from "./config";
import dashboardReducer from "./dashboard";
import adminReducer from "./admin";

const {
	createReduxHistory,
	routerMiddleware,
	routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
	reducer: {
		admin: adminReducer,
		application: applicationReducer,
		config: configReducer,
		dashboard: dashboardReducer,
		router: routerReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(routerMiddleware)
});

export const history = createReduxHistory(store);
export * from "./hooks";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;