import { UserModel } from "@rego-app/schema";

const USER_ID = "user_id" as const;
const USERNAME = "username" as const;
const REFRESH_TOKEN = "refreshToken" as const;
const ACCESS_TOKEN = "accessToken" as const;

export const AuthService = {
	async login(
		accessToken: string,
		refreshToken: string,
		fetchUser: () => Promise<UserModel | null>
	): Promise<UserModel | null> {
		this.setAccessTokenComponents(accessToken, refreshToken);

		const user = await fetchUser();
		if(user) {
			this.setUserId(user.id);
		}

		return user;
	},

	logout(): void {
		localStorage.removeItem(ACCESS_TOKEN);
		localStorage.removeItem(REFRESH_TOKEN);
	},

	setAccessTokenComponents(accessToken: string, refreshToken: string): void {
		this.setAccessToken(accessToken);
		this.setRefreshToken(refreshToken);
	},

	setUsername(username: string): void {
		localStorage.setItem(USERNAME, username);
	},

	getUsername(): string | null {
		return localStorage.getItem(USERNAME);
	},

	setUserId(userId: string): void {
		localStorage.setItem(USER_ID, userId);
	},

	getUserId(): string | null {
		return localStorage.getItem(USER_ID);
	},

	setAccessToken(token: string): void {
		localStorage.setItem(ACCESS_TOKEN, token);
	},

	getAccessToken(): string | null {
		return localStorage.getItem(ACCESS_TOKEN);
	},

	setRefreshToken(token: string): void {
		localStorage.setItem(REFRESH_TOKEN, token);
	},

	getRefreshToken(): string | null {
		return localStorage.getItem(REFRESH_TOKEN);
	}
};