import { Route, Routes } from "react-router-dom";
import { RegisterPage } from "./pages";

export const OnboardingRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="login" element={<RegisterPage />} />
			<Route path="register" element={<RegisterPage />} />
		</Routes>
	);
};