export interface PickupWindowWithLabel {
	label: string;
	from: number;
	to: number;
}

export const pickupWindows: PickupWindowWithLabel[] = [
	{
		label: "08:00 AM - 12:00 PM",
		from: 8,
		to: 12
	},
	{
		label: "12:00 PM - 04:00 PM",
		from: 12,
		to: 16
	},
	{
		label: "04:00 PM - 08:00 PM",
		from: 16,
		to: 20
	}
];

export function getPickupWindowLabel(from: number, to: number): string {
	const window = pickupWindows.find(w => w.from === from && w.to === to);
	return window?.label ?? "";
}