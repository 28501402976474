import { Box, Grid, Heading, Text } from "grommet";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import { DashboardCard, useWindowDimensions } from "../../../common";
import { ResidentPickupCard } from "./ResidentPickupCard";
import { ServiceDetailsSidebar } from "./ServiceDetailsSidebar";

interface ScheduledPickupsCardProps {
	pickups: {
		pickupId: string;
		serviceId: string;
		started: boolean;
		completed: boolean;
		scheduled: boolean;
		productCount: number;
		unitNumberFormatted: string;
		customerNameFormatted: string;
		scheduledDateFormatted: string;
	}[];
}

export const ScheduledPickupsCard: React.FC<ScheduledPickupsCardProps> = (props) => {
	const dimensions = useWindowDimensions();

	const columns = useMemo(() => {
		return ["small", "medium"].includes(dimensions.size)
			? 1
			: 3;
	}, [dimensions.size]);

	const scheduled = useMemo(() => {
		return props.pickups.filter(p => p.scheduled && !p.started && !p.completed);
	}, [props.pickups]);

	const started = useMemo(() => {
		return props.pickups.filter(p => p.started && !p.completed);
	}, [props.pickups]);

	const completed = useMemo(() => {
		return props.pickups.filter(p => p.completed);
	}, [props.pickups]);

	const [selectedServiceId, setSelectedServiceId] = useState("");

	return (
		<DashboardCard>
			{!!selectedServiceId && (
				<ServiceDetailsSidebar
					serviceId={selectedServiceId}
					onClose={() => {
						setSelectedServiceId("");
					}}
				/>
			)}
			<Box gap="small">
				<Heading margin="none" level="3">Pickup History</Heading>
				<Grid columns={{ count: columns, size: "auto" }} gap="small">
					<Box gap="small">
						<Box align="center">
							<Heading margin="none" level="2" style={{ textDecoration: "underline" }}>Scheduled</Heading>
						</Box>
						{scheduled.map(pickup => (
							<ResidentPickupCard
								key={pickup.serviceId}
								name={pickup.customerNameFormatted}
								unit={pickup.unitNumberFormatted}
								status="SCHEDULED"
								date={pickup.scheduledDateFormatted}
								items={pickup.productCount}
								onClick={() => {
									setSelectedServiceId(pickup.serviceId);
								}}
							/>
						))}
						{scheduled.length === 0 && (
							<Box align="center">
								<Text textAlign="center">nothing here yet</Text>
							</Box>
						)}
					</Box>
					<Box gap="small">
						<Box align="center">
							<Heading margin="none" level="2" style={{ textDecoration: "underline" }}>In Progress</Heading>
						</Box>
						{started.map(pickup => (
							<ResidentPickupCard
								key={pickup.serviceId}
								name={pickup.customerNameFormatted}
								unit={pickup.unitNumberFormatted}
								status="IN_PROGRESS"
								date={pickup.scheduledDateFormatted}
								items={pickup.productCount}
								onClick={() => {
									setSelectedServiceId(pickup.serviceId);
								}}
							/>
						))}
						{started.length === 0 && (
							<Box align="center">
								<Text textAlign="center">nothing here yet</Text>
							</Box>
						)}
					</Box>
					<Box gap="small">
						<Box align="center">
							<Heading margin="none" level="2" style={{ textDecoration: "underline" }}>Completed</Heading>
						</Box>
						{completed.map(pickup => (
							<ResidentPickupCard
								key={pickup.serviceId}
								name={pickup.customerNameFormatted}
								unit={pickup.unitNumberFormatted}
								status="COMPLETED"
								date={pickup.scheduledDateFormatted}
								items={pickup.productCount}
								onClick={() => {
									setSelectedServiceId(pickup.serviceId);
								}}
							/>
						))}
						{completed.length === 0 && (
							<Box align="center">
								<Text textAlign="center">nothing here yet</Text>
							</Box>
						)}
					</Box>
				</Grid>
			</Box>
		</DashboardCard>
	);
};