import { Header, Box, Image, ResponsiveContext, Menu, Anchor, Button, Text } from "grommet";
import { Login, Menu as MenuIcon } from "grommet-icons";
import React from "react";
import { push } from "redux-first-history";
import { useLoginNavigation } from "../../../app/navigation";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectUser, setRequiresLogin } from "../../../app/store/application";
import logo from "../../../img/rego_black_logo.png";
import { Avatar } from "./Avatar";

const HEADER_PAGES = [
	{
		label: "Dashboard",
		path: "/dashboard"
	}
];

export const RegoHeader: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const loginNavigation = useLoginNavigation();

	return (
		<Header
			flex
			sticky="scrollup"
			id="header"
			pad="small"
			gap="medium"
			background="light-2"
			elevation="medium"
			direction="row"
		>
			<Box width="small">
				<Image src={logo} onClick={() => dispatch(push("/dashboard"))} />
			</Box>
			<Box direction="row" gap="small" align="center">
				<ResponsiveContext.Consumer>
					{size =>
						size === 'small' ? (
							<Box justify="end">
								<Menu
									a11yTitle="Navigation Menu"
									dropProps={{ align: { top: 'bottom', right: 'right' } }}
									icon={<MenuIcon color="brand" />}
									items={HEADER_PAGES.map(page => {
										return {
											label: (
												<Box pad="small">
													<Anchor
														label={page.label}
														onClick={() => {
															dispatch(push(page.path));
														}}
													/>
												</Box>
											)
										};
									})}
								/>
							</Box>
						) : (
							<Box justify="end" direction="row" gap="medium">
								{
									HEADER_PAGES.map(page => {
										return (
											<Anchor
												key={page.label}
												label={page.label}
												onClick={(() => {
													dispatch(push(page.path));
												})}
											/>
										);
									})
								}
							</Box>
						)
					}
				</ResponsiveContext.Consumer>
				{user
					? <Avatar />
					: <Button
						plain
						reverse
						icon={<Login />} onClick={() => {
							loginNavigation.loginNavigate();
						}}
						label={
							<Text weight="bold">Login</Text>
						}
					/>
				}
			</Box>
		</Header>
	);
};