import { ResponsiveContext } from "grommet";
import { useContext } from "react";
import { ScreenSize } from "../../../types";

export * from "./useQuery";
export * from "./useWindowDimensions";
export * from "./useAddressAutocomplete";
export * from "./useGoogleTagManager";
export * from "./useVehicles";
export * from "./useClassifications";
export * from "./useMarkets";

export function useScreenSize(): ScreenSize {
	return useContext(ResponsiveContext) as ScreenSize;
}

export function useTimezone(): string {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
};