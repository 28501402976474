import { Circle, Forest, Help, QuestionAnswerOutlined, QuestionMark, QuestionMarkOutlined } from "@mui/icons-material";
import { ListItem, Chip, ListItemButton, Badge } from "@mui/material";
import { Grid, Box, Heading, Button, Stack, Text, Anchor } from "grommet";
import { IconProps, FormSubtract, FormAdd } from "grommet-icons";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { formatCurrency } from "../../../../../helpers";
import { useWindowDimensions } from "../../../../common";
import { CheckoutSessionEventData } from "../../types";
import { ExampleModal } from "./inventoryStep";

export interface CheckoutSessionProps {
	isLoading: boolean;
	sessionId: string;
	phoneNumber: string;
	onComplete: () => void;
	eventData: CheckoutSessionEventData;
}
interface ItemCardProps {
	title: string;
	description: string;
	increment: () => void;
	decrement: () => void;
	count: number;
	total: number;
	compareTotal: number;
	examples?: string[];
}

export const ItemCard: React.FC<ItemCardProps> = (props) => {
	const { size } = useWindowDimensions();
	const [ showExamples, setShowExamples ] = useState(false);


	function scaleDownSize(iconSize: IconProps[ "size" ]): IconProps[ "size" ] {
		if(size !== "small") return iconSize;

		switch(iconSize) {
			case "small":
			case "medium": {
				return "small";
			}
			case "large": {
				return "medium";
			}
			case "xlarge": {
				return "large";
			}
			default: {
				return iconSize;
			}
		}
	}

	const iconFontSize = useMemo(() => {
		switch(size) {
			case "small": {
				return 36;
			}
			case "medium": {
				return 48;
			}
			default: {
				return 60;
			}
		}
	}, [ size ]);

	return (
		<Fragment>
			{(showExamples && props.examples) && (
				<ExampleModal
					onClose={() => setShowExamples(false)}
					examples={props.examples}
				/>
			)}
			<Grid columns={[ "flex", "auto" ]} gap="medium">
				<Box justify="center" flex>
					<Heading
						margin="none"
						level={size === "small" ? 3 : 2}
						onClick={() => (props.examples?.length ?? 0) > 0 && setShowExamples(true)}
						style={{ textDecoration: "underline" }}
					>
						<Box direction="row" gap="small">
							{props.title}
							{props.title === "Christmas Trees" && (
								<Box justify="center">
									<Forest color="success" sx={{ fontSize: (iconFontSize / 2) }} />
								</Box>
							)}
						</Box>
					</Heading>
					<Text size="small">{props.description}</Text>
				</Box>
				<Box gap="small" justify="center">
					<Grid columns={size === "small" ? [ "auto", "flex", "auto" ] : [ "auto", "flex", "auto", "xxsmall" ]} gap="small" align="center">
						<Button
							plain
							hoverIndicator
							onClick={props.decrement}
							icon={
								<Stack>
									<Circle sx={{ fontSize: iconFontSize, color: "#e16259" }} />
									<Box align="center" justify="center" fill>
										<FormSubtract color="white" size={scaleDownSize("large")} />
									</Box>
								</Stack>
							}
						/>
						<Box align="center" justify="center" style={{ minWidth: "40px" }}>
							<Heading margin="none" level="2">{props.count}</Heading>
						</Box>
						<Button
							plain
							hoverIndicator
							onClick={props.increment}
							icon={
								<Stack>
									<Circle sx={{ fontSize: iconFontSize }} color="success" />
									<Box align="center" justify="center" fill>
										<FormAdd color="white" size={scaleDownSize("large")} />
									</Box>
								</Stack>
							}
						/>
						{(size !== "small" && !!props.total) && (
							<ItemPricingComparison
								total={props.total}
								compareTotal={props.compareTotal}
							/>
						)}
					</Grid>
					{(size === "small" && !!props.total) && (
						<Box align="center" justify="center">
							<ItemPricingComparison
								total={props.total}
								compareTotal={props.compareTotal}
							/>
						</Box>
					)}
				</Box>
			</Grid>
		</Fragment>
	);
};

export const ItemPricingComparison: React.FC<{ compareTotal?: number; total: number; }> = (props) => {
	return (
		<Box gap="xxsmall" justify="center" align="center">
			{props.compareTotal && (
				<Text weight="bold" color="red" style={{ textDecoration: "line-through" }}>{formatCurrency(props.compareTotal)}</Text>
			)}
			<Text weight="bold">{formatCurrency(props.total)}</Text>
		</Box>
	);
};


interface AvailablePickupCardProps {
	date: string;
	from: number;
	to: number;
	timezone: string;
	isFastest: boolean;
	isSelected: boolean;
	onSelect: () => void;
}

export const AvailablePickupCard: React.FC<AvailablePickupCardProps> = (props) => {
	const { size } = useWindowDimensions();
	const dateFormatted = useMemo(() => moment.tz(props.date, props.timezone).format("dddd, MMMM Do"), [ props.date ]);
	const windowFromFormatted = useMemo(() => moment().hours(props.from).format("hh A"), [ props.from ]);
	const windowToFormatted = useMemo(() => moment().hours(props.to).format("hh A"), [ props.to ]);

	return (
		<ListItem
			onClick={props.onSelect}
			disablePadding
			secondaryAction={
				(props.isFastest)
					? (
						<Chip
							size={size === "small" ? "small" : undefined}
							color="success"
							variant="filled"
							label="Fastest"
						/>
					)
					: undefined
			}
		>
			<ListItemButton selected={props.isSelected}>
				<Box>
					<Text weight="bold">{dateFormatted}</Text>
					<Text size="small">{windowFromFormatted} to {windowToFormatted}</Text>
				</Box>
			</ListItemButton>
		</ListItem>
	);
};
