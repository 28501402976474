import { Box, Grid, Select, Spinner, Text } from "grommet";
import { useEffect } from "react";
import { useBuildingDashboard } from "../hooks";
import { ScheduledPickupsCard } from "../components";
import { ResidenceDetailsCard, ResidenceScheduledPickupCard } from "../../common/components";
import { useWindowDimensions } from "../../../common";
import moment from "moment-timezone";

interface BuildingDashboardProps {
	residenceId?: string;
}

export const BuildingDashboard: React.FC<BuildingDashboardProps> = (props) => {
	const dimensions = useWindowDimensions();

	const { loading, activeResidence, residences, setActiveResidence } = useBuildingDashboard();

	useEffect(() => {
		if(props.residenceId) {
			setActiveResidence(props.residenceId);
		}
	}, [props.residenceId]);

	return (
		<Box gap="small">
			{residences.length > 1 && (
				<Box gap="small">
					<Box gap="small" margin={{ horizontal: "small" }}>
						<Text weight="bold" size="large">
							View Dashboard For
						</Text>
					</Box>
					<Box width="medium">
						<Select
							multiple={false}
							icon={loading ? <Spinner /> : undefined}
							value={activeResidence?.name}
							options={residences.map(r => r.name)}
							onChange={({ option }) => {
								setActiveResidence(residences.find(r => r.name === option)?.id ?? "");
							}}
						/>
					</Box>
				</Box>
			)}
			<Grid columns={{ count: ["small", "medium"].includes(dimensions.size) ? 1 : 2, size: "auto" }} gap="medium">
				<ResidenceDetailsCard
					isLoading={loading}
					residenceName={activeResidence?.name ?? ""}
					logoUrl={activeResidence?.customizations.logoUrl ?? ""}
				/>
				<ResidenceScheduledPickupCard
					isLoading={loading}
					omitCallToAction={true}
					nextPickup={(activeResidence?.nextScheduledPickup?.scheduledDate)
						? {
							date: activeResidence.nextScheduledPickup?.scheduledDate ?? "",
							timezone: activeResidence?.nextScheduledPickup?.scheduledWindowTimezone ?? "",
							from: activeResidence?.nextScheduledPickup?.scheduledWindowFrom ?? 0,
							to: activeResidence?.nextScheduledPickup?.scheduledWindowTo ?? 0
						}
						: null
					}
					scheduledPickups={(activeResidence?.upcomingPickups ?? []).map(pickup => {
						return {
							id: pickup.id,
							scheduledWindow: {
								to: pickup.scheduledWindowTo ?? 0,
								from: pickup.scheduledWindowFrom ?? 0,
								date: pickup.scheduledDate ?? "",
								timezone: pickup.scheduledWindowTimezone ?? ""
							}
						};
					})}
				/>
			</Grid>
			<ScheduledPickupsCard
				pickups={(activeResidence?.pickups ?? []).flatMap(pickup => {
					return (pickup.services ?? []).map(service => {
						const { firstName, lastName, currentResident } = service.user;
						return {
							serviceId: service.id,
							pickupId: pickup.id,
							started: pickup.started,
							scheduled: pickup.scheduled,
							completed: pickup.completed,
							productCount: service.productCount ?? 0,
							unitNumberFormatted: currentResident?.verification?.unit
								? `Unit ${currentResident.verification.unit}`
								: "",
							customerNameFormatted: `${firstName} ${String(lastName).charAt(0).toUpperCase()}.`,
							scheduledDateFormatted: moment.tz(pickup.scheduledDate ?? "", pickup.scheduledWindowTimezone ?? "").format("MM/DD/YYYY")
						};
					});
				})}
			/>
			{/* <BuildingSustainabilityInsights

			/> */}
		</Box>
	);
};