import { Route, RouteProps, Routes } from "react-router-dom";
import { CommonRoutes } from "../common";
import { AdminDashboard, AdminDashboardController, AdminPickupDetailsPage, CreatePickupPage, PickupsDashboard } from "./pages";
import { AdminServiceDetailsPage } from "./pages/services";

const AdminRoutes: React.ReactElement<RouteProps>[] = [
	...CommonRoutes,
	<Route path="/" element={< AdminDashboardController />}>
		<Route path="/dashboard" element={< AdminDashboard />} />
		<Route path="/pickups" element={< PickupsDashboard />} />
		<Route path="/pickups/:pickupId" element={< AdminPickupDetailsPage />} />
		<Route path="/pickups/create" element={< CreatePickupPage />} />
		<Route path="/services/:serviceId" element={< AdminServiceDetailsPage />} />
		<Route path="/users" element={< PickupsDashboard />} />
		<Route path="/users/:userId" element={< PickupsDashboard />} />
		<Route path="/residences" element={< PickupsDashboard />} />
		<Route path="/residences/:residenceId" element={< PickupsDashboard />} />
	</Route>
];

export const AdminRouter: React.FC = () => {
	return (
		<Routes>
			{AdminRoutes}
		</Routes>
	);
};