import React from "react";
import { LayoutProvider } from "../features/common";
import { ResidentManagerRouter } from "../features/dashboard";

export const ResidentManagerEntryPoint: React.FC = (props) => {
	return (
		<LayoutProvider>
			<ResidentManagerRouter />
		</LayoutProvider>
	);
};