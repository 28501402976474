import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box, Heading, Text } from "grommet";
import { useState } from "react";
import { useWindowDimensions } from "../../common/hooks";

interface FAQItem {
	question: string;
	answer: string | string[];
}

const questions: FAQItem[] = [
	{
		question: "Who is Rego?",
		answer: [
			"Rego is a Philadelphia-based circular economy company working to build cleaner, more sustainable cities worldwide to improve social and economic opportunities for the humans who live in those cities.",
			"We’re taking the first step by diverting useable bulky items (such as furniture) away from landfills and off our streets!"
		]
	},
	{
		question: "Why?",
		answer: [
			"If you’re reading this, you probably have furniture. (Almost) everyone has furniture, and it’s (almost) everywhere we go. Furniture is one of the most manufactured things in the world. Yet, we realized there was no sustainable waste management solution for furniture once people were done with it. This was crazy to think about at scale. Further, in almost any city you visit, you’re sure to see furniture dumped somewhere on the side of the road or left out on the curb.",
			"According to the EPA, over 12M tons of furniture is landfilled annually in the US alone, and we estimate this is equal to almost 80 miles worth of couches. Furniture can contain many toxic materials, so less than 1% of all household furniture can get recycled, which strains our landfills. After learning of the damages caused by traditional disposal methods that led to short dumping and poor environmental outcomes, we decided to use our logistics and technology skills to tackle the most overlooked problem in sustainability."
		]
	},
	{

		question: "Will I get a tax receipt?",
		answer: "If your items are accepted by a non-profit in our network, then yes! You benefit from making the choice to be sustainable and positively impact the community. The tax receipt will come directly from our partner(s) who accepted the item(s). Please know that sometimes a non-profit outside of our network will accept an item, and we may not be able to get your tax receipt. In these instances, we will make every effort to take photo proof of the donation, but the photo cannot be used in place of a tax receipt."
	},
	{

		question: "What can we take?",
		answer: [
			"Almost anything, especially items you’re comfortable giving to someone who needs it.",
			"Furniture, small appliances, clothing & accessories, home goods, electronics. Typically, we recommend larger items since it fits better with our pricing model than smaller items. We also accept fist bumps, high-fives, compliments 😉",
			"* Please be advised that we reserve the right to deny items at the discretion of our team members."
		]
	},
	{

		question: "Do we disassemble?",
		answer: "For an added fee, we can disassemble your items. We recommend disassembling your items in advance to avoid extra fees and time."
	},
	{

		question: "What don't you take?",
		answer: "Everyday trash, liquids, paint & other chemicals, yard waste, medical waste, food waste, car parts, items with bodily fluids, items with bedbugs and other creepy crawlers."
	},
	{

		question: "Can we sell with Rego?",
		answer: "Almost 😊 this is coming soon!"
	}
];

const FAQ: React.FC<FAQItem> = (props) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<Accordion>
			<AccordionSummary
				onClick={() => {
					setExpanded(!expanded);
				}}
				expandIcon={<ExpandMore />}
			>
				<Text weight="bold">
					{props.question}
				</Text>
			</AccordionSummary>
			<AccordionDetails
				onClick={() => {
					setExpanded(!expanded);
				}}
			>
				{Array.isArray(props.answer)
					? (
						<Box gap="small">
							{props.answer.map(text => (
								<Text key={text}>
									{text}
								</Text>
							))}
						</Box>
					)
					: (
						<Text>
							{props.answer}
						</Text>
					)}
			</AccordionDetails>
		</Accordion>
	);
};

export const FAQPage: React.FC = (props) => {
	const dimensions = useWindowDimensions();

	return (
		<Box margin="medium" gap="small" align="center">
			<Box align="center">
				<Heading color="brand">
					FAQ's
				</Heading>
			</Box>
			<Box style={dimensions.width > 800 ? { maxWidth: "700px" } : { width: "-webkit-fill-available" }}>
				{questions.map(question => (
					<FAQ
						key={question.question}
						{...question}
					/>
				))}
			</Box>
		</Box>
	);
};