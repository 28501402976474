import { useLazyQuery } from "@apollo/client";
import { Box, Select, Spinner, Text } from "grommet";
import { useEffect, useMemo } from "react";
import { ListPartners } from "../../../../app/services/request/admin";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { setImpersonatePartnerId } from "../../../../app/store/admin";
import { selectIsAdmin } from "../../../../app/store/application";
import { HaulerType, isHauler } from "../helpers";

interface AdminControlsProps {
	isLoading: boolean;
	selectedHaulerName: string;
}

export const AdminControls: React.FC<AdminControlsProps> = (props) => {
	const dispatch = useAppDispatch();
	const isAdmin = useAppSelector(selectIsAdmin);
	const [execute, { data, loading, error }] = useLazyQuery(ListPartners);

	useEffect(() => {
		if(isAdmin) {
			execute({ variables: { type: "Hauler" } });
		}
	}, []);

	const haulers = useMemo(() => {
		return (data?.ListPartners)?.filter<HaulerType>(isHauler) ?? [];
	}, [data]);

	if(!isAdmin) {
		return null;
	}

	return (
		<Box background="accent-1" align="start" pad="medium">
			<Box gap="small">
				<Box margin={{ horizontal: "small" }}>
					<Text weight="bold">Viewing As</Text>
				</Box>
				<Select
					icon={loading || props.isLoading ? <Spinner /> : undefined}
					value={props.selectedHaulerName}
					options={haulers.map(h => h.name)}
					onChange={({ option }) => {
						dispatch(setImpersonatePartnerId(haulers.find(h => h.name === option)?.id ?? ""));
					}}
				/>
			</Box>
		</Box>
	);
};