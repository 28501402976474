import { Anchor, Box, Button, CheckBox, ColumnConfig, DataTable, Grid, Heading, Select, Spinner, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { useMarkets, useTimezone, useWindowDimensions } from "../../common/hooks";
import { useLazyQuery, useQuery } from "@apollo/client";
import { AdminGetPickup, AdminGetPickups } from "../../../app/services/request/gql";
import moment from "moment-timezone";
import { formatCurrency, formatDateFromWindow, formatTimeFromWindow, sortByScheduledDate } from "../../../helpers";
import { DashboardCard, Loader, LoadingButton, RightExpandableSidebarModal, RightSidebarModalContext, WrapSkeletonFormField } from "../../common";
import { Chip, LinearProgress, Step, StepLabel, Stepper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../../../app/store";
import { push } from "redux-first-history";
import { useParams } from "react-router";
import { Calendar } from "grommet-icons";
import { ScheduledPickupSidebar } from "../components";
import { ListResidencesByMarket } from "../../../app/services/request/admin";

export const PickupsDashboard: React.FC = (props) => {
	const { size } = useWindowDimensions();

	const columns = useMemo(() => {
		return ["small", "medium"].includes(size) ? 1 : 2;
	}, [size]);

	return (
		<Grid columns={{ count: columns, size: "auto" }} gap="small">
			<PickupsCardContainer
				scheduled={true}
				completed={false}
				heading="Scheduled Pickups"
				noRecordsMessage="No scheduled pickups"
				forceSmallSize={columns > 1 && size === "large"}
			/>
			<PickupsCardContainer
				scheduled={true}
				completed={true}
				heading="Recent Pickups"
				noRecordsMessage="No recent pickups"
				forceSmallSize={columns > 1 && size === "large"}
			/>
		</Grid>
	);
};

interface PickupsCardContainerProps {
	heading: string;
	scheduled: boolean;
	completed: boolean;
	noRecordsMessage: string;
	forceSmallSize: boolean;
}

export const PickupsCardContainer: React.FC<PickupsCardContainerProps> = (props) => {
	const snack = useSnackbar();

	const { data, loading, error } = useQuery(AdminGetPickups, {
		variables: {
			scheduled: props.scheduled,
			completed: props.completed
		}
	});

	const [selectedPickupId, setSelectedPickupId] = useState("");

	useEffect(() => {
		if(error) {
			console.error("Failed to load pickups", error);
			snack.enqueueSnackbar("Failed to load pickups", { variant: "error" });
		}
	}, [error]);

	const pickups = useMemo(() => {
		if(data?.GetPickups) {
			return Array.from(data.GetPickups).sort(sortByScheduledDate);
		}

		return [];
	}, [data]);

	return (
		<DashboardCard>
			{selectedPickupId && (
				<AdminPickupSidebar
					onClose={() => setSelectedPickupId("")}
					pickupId={selectedPickupId}
				/>
			)}
			<Box margin="small" gap="small">
				<Heading level="3" margin="none">{props.heading}</Heading>
				<Loader visible={loading}>
					<Box gap="small">
						{pickups.length === 0 && (
							<Box align="center" justify="center">
								<Text>{props.noRecordsMessage}</Text>
							</Box>
						)}
						{pickups.map(pickup => (
							<AdminPickupCard
								key={pickup.id}
								pickupId={pickup.id}
								scheduled={pickup.scheduled}
								completed={pickup.completed}
								started={pickup.started}
								marketCode={pickup.market.code}
								marketId={pickup.market.id}
								residences={pickup.residences.map(r => {
									return {
										id: r.id,
										name: r.name,
										code: r.code
									};
								})}
								isCustomerScheduled={pickup.isCustomerScheduled}
								pickupScheduledDate={pickup.scheduledDate
									? moment.tz(pickup.scheduledDate, pickup.scheduledWindowTimezone ?? "").format("MM/DD/YYYY")
									: ""
								}
								pickupScheduledWindowFrom={pickup.scheduledWindowFrom ?? 0}
								pickupScheduledWindowFromFormatted={pickup.scheduledWindowFrom
									? moment().hours(pickup.scheduledWindowFrom).minutes(0).format("h:mm A")
									: ""
								}
								pickupScheduledWindowTo={pickup.scheduledWindowTo ?? 0}
								pickupScheduledWindowToFormatted={pickup.scheduledWindowTo
									? moment().hours(pickup.scheduledWindowTo).minutes(0).format("h:mm A")
									: ""
								}
								pickupScheduledWindowTimezone={pickup.scheduledWindowTimezone ?? ""}
								services={(pickup.services ?? []).map(service => {
									return {
										id: service.id,
										customerFirstName: service.user.firstName ?? "",
										customerLastName: service.user.lastName ?? "",
										customerUnit: service.user.currentResident?.verification?.unit ?? "",
										productCount: service.productCount ?? 0,
										products: (service.products ?? []).map(product => {
											return {
												media: (product.media ?? []).map(media => {
													return {
														fileName: media.fileName ?? "",
														contentUrl: media.contentUrl ?? ""
													};
												}),
												item: {
													id: product?.item?.id ?? "",
													name: product?.item?.name ?? "",
												},
												itemClassification: {
													id: product?.itemClassification?.id ?? "",
													name: product?.itemClassification?.name ?? "",
													code: product?.itemClassification?.code ?? "",
												}
											};
										}),
										selectedEstimate: service.selectedEstimate || null,
										residenceName: service.user.currentResident?.residence?.name ?? "",
									};
								})}
								onSelected={() => {
									setSelectedPickupId(pickup.id);
								}}
								forceSmallSize={props.forceSmallSize}
							/>
						))}
					</Box>
				</Loader>
			</Box>
		</DashboardCard>
	);
};

interface AdminPickupCardBaseProps {
	marketId: string;
	marketCode: string;
	pickupId: string;
	residences: {
		id: string;
		code: string;
		name: string;
	}[];
	pickupScheduledDate: string;
	pickupScheduledWindowFrom: number;
	pickupScheduledWindowFromFormatted: string;
	pickupScheduledWindowTo: number;
	pickupScheduledWindowToFormatted: string;
	pickupScheduledWindowTimezone: string;
	scheduled: boolean;
	started: boolean;
	completed: boolean;
	isCustomerScheduled: boolean;
	services: {
		id: string;
		residenceName: string;
		customerFirstName: string;
		customerLastName: string;
		customerUnit: string;
		productCount: number;
		products: {
			item: {
				id: string;
				name: string;
			} | null;
			itemClassification: {
				id: string;
				name: string;
				code: string;
			} | null;
			media: {
				fileName: string;
				contentUrl: string;
			}[];
		}[];
		selectedEstimate: {
			totalAmount: number;
		} | null;
	}[];
}

interface AdminPickupCardProps extends AdminPickupCardBaseProps {

	onSelected(): void;
	forceSmallSize: boolean;
}

export const AdminPickupCard: React.FC<AdminPickupCardProps> = (props) => {
	const { size } = useWindowDimensions();

	const isSmallSize = useMemo(() => props.forceSmallSize || size === "small", [props.forceSmallSize, size]);

	const residences = useMemo(() => [
		"The Chestnut",
		"2116 Chestnut Apartment",
		"The Other Building",
		"Make It Longer"
	], []);;

	const formattedResidences = useMemo(() => {
		if(residences.length <= 3) {
			return residences.join(", ");
		}

		return residences.slice(0, 3).join(", ") + ` + ${residences.length - 3} others`;
	}, [residences]);

	return (
		<Box
			round
			gap="small"
			pad="medium"
			border={{ color: "accent-1" }}
			hoverIndicator
			onClick={() => {
				props.onSelected();
			}}
		>
			<Grid columns={{ count: isSmallSize ? 1 : 2, size: "auto" }} gap="small">
				<Box>
					<Text weight="bold">{props.marketCode}</Text>
					<Text size="small" weight="bold">
						{formattedResidences}
					</Text>
				</Box>
				<Box>
					<Text>
						Date: <Text weight="bold">{props.pickupScheduledDate}</Text>
					</Text>
					<Text>
						Window: <Text weight="bold">{props.pickupScheduledWindowFromFormatted} - {props.pickupScheduledWindowToFormatted}</Text>
					</Text>
				</Box>
			</Grid>
			<Grid columns={{ count: isSmallSize ? 1 : 2, size: "auto" }} gap="small">
				<Box justify="center">
					<Anchor label={`${props.services.length} customer(s) scheduled`} />
				</Box>
				<Box gap="small" direction="row" align="center">
					{(props.scheduled && !props.started && !props.completed) && (
						<Chip
							color="primary"
							label="Scheduled"
						/>
					)}
					{(props.started && !props.completed) && (
						<Chip
							color="success"
							label="In Progress"
						/>
					)}
					{props.completed && (
						<Chip
							color="success"
							label="Completed"
						/>
					)}
					{props.isCustomerScheduled && (
						<Chip
							color="warning"
							label="Customer Scheduled"
						/>
					)}
				</Box>
			</Grid>
		</Box>
	);
};

interface PickupSummaryProps extends AdminPickupCardBaseProps {
	pickupId: string;
	isLoading: boolean;
	handleViewMore(): void;
}

export const AdminPickupSummary: React.FC<PickupSummaryProps> = (props) => {
	return (
		<Box gap="small">
			<WrapSkeletonFormField
				name="pickupId"
				label="Pickup Id"
				value={props.pickupId}
				isLoading={props.isLoading}
				specialFieldType="COPY"
			/>
			<WrapSkeletonFormField
				name="scheduledDate"
				label="Scheduled Date"
				value={props.pickupScheduledDate}
				isLoading={props.isLoading}
				icon={<Calendar />}
				specialFieldType="ACTION"
				onAction={() => {

				}}
			/>
			<WrapSkeletonFormField
				name="scheduledWindow"
				label="Scheduled Window"
				value={props.pickupScheduledWindowFrom + " - " + props.pickupScheduledWindowTo}
				isLoading={props.isLoading}
				inputProps={{
					readOnly: true,
					disabled: true
				}}
			/>
			<WrapSkeletonFormField
				name="market"
				label="Market"
				value={props.marketCode}
				isLoading={props.isLoading}
				inputProps={{
					readOnly: true,
					disabled: true
				}}
			/>
			<Box margin="small" gap="xsmall">
				<Text weight="bold">Tags</Text>
				<Box gap="small" direction="row" align="center">
					{(props.scheduled && !props.started) && (
						<Chip
							color="primary"
							label="Scheduled"
						/>
					)}
					{(props.started && !props.completed) && (
						<Chip
							color="success"
							label="In Progress"
						/>
					)}
					{props.completed && (
						<Chip
							color="success"
							label="Completed"
						/>
					)}
					{props.isCustomerScheduled && (
						<Chip
							color="warning"
							label="Customer Scheduled"
						/>
					)}
				</Box>
			</Box>
			<Box align="center">
				<Button
					primary
					color="accent-1"
					label="View More"
					onClick={props.handleViewMore}
				/>
			</Box>
		</Box>
	);
};

interface PickupDetailsProps extends PickupSummaryProps {
	scheduledAt: string | null;
	startedAt: string | null;
	completedAt: string | null;
}

export const AdminPickupDetails: React.FC<PickupDetailsProps> = (props) => {
	const { size } = useWindowDimensions();
	const [isSchedulingPickup, setIsSchedulingPickup] = useState(false);

	return (
		<Box pad="small" gap="small" overflow={{ vertical: "scroll" }}>
			{isSchedulingPickup && (
				<ScheduledPickupSidebar
					pickupId={props.pickupId}
					onClose={() => setIsSchedulingPickup(false)}
					isScheduling={false}
					onScheduled={() => { }}
					scheduledDate={props.pickupScheduledDate}
					scheduledWindowFrom={props.pickupScheduledWindowFrom}
					scheduledWindowTo={props.pickupScheduledWindowTo}
				/>
			)}
			<Grid columns={{ count: ["small", "medium"].includes(size) ? 1 : 2, size: "auto" }} gap="small">
				<PickupDetailsCard
					pickupId={props.pickupId}
					isLoading={props.isLoading}
				/>
				<PickupStatusDetailsCard
					isLoading={props.isLoading}
					scheduled={props.scheduled}
					scheduledAt={props.scheduledAt}
					scheduledWindowTo={props.pickupScheduledWindowTo}
					scheduledWindowToFormatted={props.pickupScheduledWindowToFormatted}
					scheduledWindowDate={props.pickupScheduledDate}
					scheduledWindowFrom={props.pickupScheduledWindowFrom}
					scheduledWindowFromFormatted={props.pickupScheduledWindowFromFormatted}
					scheduledWindowTimezone={props.pickupScheduledWindowTimezone}
					started={props.started}
					startedAt={props.startedAt}
					completed={props.completed}
					completedAt={props.completedAt}
					onStarted={(() => { })}
					onCompleted={() => { }}
					onSchedulePickup={() => setIsSchedulingPickup(true)}
					isUpdating={false}
				/>
				<PickupServiceDetailsCard
					services={props.services}
				/>
				<PickupSustainabilityImpact

				/>
			</Grid>
		</Box>
	);
};

export const PickupSustainabilityImpact: React.FC = (props) => {
	return (
		<DashboardCard>
			<Box margin="small" gap="medium">
				<Heading margin="none" level="2">Sustainability Impact</Heading>
				<Box gap="small">

				</Box>
			</Box>
		</DashboardCard>
	);
};

interface PickupServiceDetailsCardProps {
	services: PickupSummaryProps["services"];
}

export const PickupServiceDetailsCard: React.FC<PickupServiceDetailsCardProps> = (props) => {
	return (
		<DashboardCard>
			<Box margin="small" gap="medium">
				<Heading margin="none" level="2">Scheduled Customers</Heading>
				<Box gap="small">
					{props.services.map(service => (
						<ServiceSummaryCard
							serviceId={service.id}
							firstName={service.customerFirstName}
							lastName={service.customerLastName}
							unit={service.customerUnit}
							productCount={service.productCount}
							residenceName={service.residenceName}
							totalFee={service.selectedEstimate?.totalAmount ?? 0}
						/>
					))}
				</Box>
			</Box>
		</DashboardCard>
	);
};

interface ServiceSummaryCardProps {
	serviceId: string;
	firstName: string;
	lastName: string;
	unit: string;
	productCount: number;
	totalFee: number;
	residenceName: string;
}

export const ServiceSummaryCard: React.FC<ServiceSummaryCardProps> = (props) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	function handleSelectService(): void {
		dispatch(push(`/services/${props.serviceId}`));
	}

	const padSize = useMemo(() => size === "small" ? "medium" : "small", [size]);

	return (
		<Box
			fill="horizontal"
		>
			<Box
				background="black"
				round={{ corner: "top" }}
				pad={padSize}
			>
				<Text weight="bold">{props.residenceName} {!!props.unit && (<Text>(Unit {props.unit})</Text>)}</Text>
			</Box>
			<Box
				pad={padSize}
				background="light-2"
				round={{ corner: "bottom" }}
				hoverIndicator
				onClick={handleSelectService}
			>
				<Box direction="row" justify="between" fill="horizontal">
					<Box align="start">
						<Text weight="bold">{props.firstName} {props.lastName}</Text>
						<Text weight="bold">{props.productCount} item(s)</Text>
					</Box>
					<Box align="end">
						<Box align="center">
							<Text>Total Fee:</Text>
							<Text weight="bold">{formatCurrency(props.totalFee ?? 0)}</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

interface PickupDetailsCardProps {
	isLoading: boolean;
	pickupId: string;
}

export const PickupDetailsCard: React.FC<PickupDetailsCardProps> = (props) => {
	return (
		<DashboardCard>
			<Box margin="small" gap="medium">
				<Heading margin="none" level="2">Pickup Details</Heading>
				<Box gap="small">
					<WrapSkeletonFormField
						isLoading={props.isLoading}
						label="Pickup Id"
						value={props.pickupId}
						specialFieldType="COPY"
					/>
				</Box>
			</Box>
		</DashboardCard>
	);
};

interface PickupStatusTrackingProps {
	scheduled: boolean;
	scheduledAt: string | null;
	scheduledWindowDate: string;
	scheduledWindowTimezone: string;
	scheduledWindowFrom: number;
	scheduledWindowFromFormatted: string;
	scheduledWindowTo: number;
	scheduledWindowToFormatted: string;
	started: boolean;
	startedAt: string | null;
	completed: boolean;
	completedAt: string | null;
}

interface PickupStatusDetailsCardProps extends PickupStatusTrackingProps {
	isLoading: boolean;
	isUpdating: boolean;
	onStarted(): void;
	onCompleted(): void;
	onSchedulePickup(): void;
}

export const PickupStatusDetailsCard: React.FC<PickupStatusDetailsCardProps> = (props) => {
	const timezone = useTimezone();

	const scheduledDate = useMemo(() => {
		return moment.tz(props.scheduledWindowDate, props.scheduledWindowTimezone).format("MM/DD/YYYY");
	}, [props.scheduledWindowDate, props.scheduledWindowTimezone]);

	const scheduledWindow = useMemo(() => {
		return [
			props.scheduledWindowFromFormatted,
			props.scheduledWindowToFormatted
		].join(" - ");
	}, [props.scheduledWindowFromFormatted, props.scheduledWindowToFormatted]);

	const startedAt = useMemo(() => {
		if(props.startedAt && props.started) {
			return moment.tz(props.startedAt, "UTC").tz(timezone).format("MM/DD/YYYY hh:mm A");
		}

		return "";
	}, [props.startedAt, timezone]);

	const completedAt = useMemo(() => {
		if(props.completedAt && props.completed) {
			return moment.tz(props.completedAt, "UTC").tz(timezone).format("MM/DD/YYYY hh:mm A");
		}

		return "";
	}, [props.startedAt, timezone]);

	return (
		<DashboardCard>
			<Box margin="small" gap="medium" flex>
				<Heading margin="none" level="2">Pickup Status</Heading>
				<Box gap="small" pad="medium">
					<WrapSkeletonFormField
						label="Scheduled Date"
						value={formatDateFromWindow(props.scheduledWindowDate, props.scheduledWindowTimezone)}
						isLoading={props.isLoading}
						icon={<Calendar style={{ padding: undefined }} />}
						specialFieldType="ACTION"
						onAction={props.onSchedulePickup}
					/>
					<WrapSkeletonFormField
						label="Scheduled Window"
						value={formatTimeFromWindow(props.scheduledWindowFrom, props.scheduledWindowTo)}
						isLoading={props.isLoading}
						icon={<Calendar />}
						specialFieldType="ACTION"
						onAction={props.onSchedulePickup}
					/>
					<Box gap="small" direction="row" justify="between">
						<Box width="small">
							<LoadingButton
								primary
								color="accent-1"
								disabled={props.started}
								isLoading={props.isUpdating}
								label="Mark as Started"
								onClick={props.onStarted}
							/>
						</Box>
						<Box width="small">
							<LoadingButton
								primary
								color="accent-1"
								disabled={props.completed || !props.started}
								isLoading={props.isUpdating}
								label="Mark as Completed"
								onClick={props.onCompleted}
							/>
						</Box>
					</Box>
				</Box>
				<Box flex justify="end">
					<PickupStatusStepper
						{...props}
					/>
				</Box>
			</Box>
		</DashboardCard>
	);
};

interface PickupStatusStepperProps extends PickupStatusTrackingProps {

}

export const PickupStatusStepper: React.FC<PickupStatusStepperProps> = (props) => {
	const timezone = useTimezone();
	const [active, setActive] = useState<number | undefined>(undefined);
	useEffect(() => {
		if(props.completed) {
			setActive(3);
			return;
		}

		if(props.started) {
			setActive(2);
			return;
		}

		if(props.scheduled) {
			setActive(1);
			return;
		}

		setActive(undefined);
	}, [props]);

	function formatDate(date: string): string {
		return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YY HH:mm");
	}

	return (
		<Stepper activeStep={active} alternativeLabel>
			<Step>
				<StepLabel>
					<Box>
						<Text weight="bold">Scheduled</Text>
						{props.scheduledAt && (
							<Text size="small">{formatDate(props.scheduledAt)}</Text>
						)}
					</Box>
				</StepLabel>
			</Step>
			<Step>
				<StepLabel>
					<Box>
						<Text weight="bold">Started</Text>
						{props.startedAt && (
							<Text size="small">{formatDate(props.startedAt)}</Text>
						)}
					</Box>
				</StepLabel>
			</Step>
			<Step>
				<StepLabel>
					<Box>
						<Text weight="bold">Completed</Text>
						{props.completedAt && (
							<Text size="small">{formatDate(props.completedAt)}</Text>
						)}
					</Box>
				</StepLabel>
			</Step>
		</Stepper>
	);
};

interface AdminPickupSidebarProps {
	onClose(): void;
	pickupId: string;
}

export const AdminPickupSidebar: React.FC<AdminPickupSidebarProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const { data, loading, error } = useQuery(AdminGetPickup, {
		variables: { pickupId: props.pickupId }
	});

	const pickup = useMemo(() => {
		if(data) {
			return data.GetPickup;
		}

		return null;
	}, [data]);

	function handleViewMore(): void {
		dispatch(push(`/pickups/${props.pickupId}`));
	}

	const basePickupProps: PickupSummaryProps = useMemo(() => {
		return {
			isLoading: loading,
			pickupId: props.pickupId,
			marketId: pickup?.market?.id ?? "",
			marketCode: pickup?.market?.code ?? "",
			residences: pickup?.residences.map(r => {
				return {
					id: r.id,
					name: r.name,
					code: r.code
				};
			}) ?? [],
			scheduled: pickup?.scheduled ?? false,
			completed: pickup?.completed ?? false,
			started: pickup?.started ?? false,
			isCustomerScheduled: pickup?.isCustomerScheduled ?? false,
			pickupScheduledDate: pickup?.scheduledDate && pickup?.scheduledWindowTimezone
				? moment.tz(pickup.scheduledDate, pickup.scheduledWindowTimezone).format("MM/DD/YYYY")
				: "",
			pickupScheduledWindowFrom: pickup?.scheduledWindowFrom ?? 0,
			pickupScheduledWindowFromFormatted: pickup?.scheduledWindowFrom
				? moment().hours(pickup.scheduledWindowFrom).minutes(0).format("h:mm A")
				: "",
			pickupScheduledWindowTo: pickup?.scheduledWindowTo ?? 0,
			pickupScheduledWindowToFormatted: pickup?.scheduledWindowTo
				? moment().hours(pickup.scheduledWindowTo).minutes(0).format("h:mm A")
				: "",
			pickupScheduledWindowTimezone: pickup?.scheduledWindowTimezone ?? "",
			services: (pickup?.services ?? []).map(service => {
				return {
					id: service.id,
					customerFirstName: service.user.firstName ?? "",
					customerLastName: service.user.lastName ?? "",
					customerUnit: service.user.currentResident?.verification?.unit ?? "",
					productCount: service.productCount ?? 0,
					products: (service.products ?? []).map(product => {
						return {
							media: (product.media ?? []).map(media => {
								return {
									fileName: media.fileName ?? "",
									contentUrl: media.contentUrl ?? ""
								};
							}),
							item: {
								id: product?.item?.id ?? "",
								name: product?.item?.name ?? "",
							},
							itemClassification: {
								id: product?.itemClassification?.id ?? "",
								name: product?.itemClassification?.name ?? "",
								code: product?.itemClassification?.code ?? "",
							}
						};
					}),
					selectedEstimate: service.selectedEstimate || null,
					residenceName: service.user.currentResident?.residence?.name ?? ""
				};
			}),
			handleViewMore: () => {
				handleViewMore();
				props.onClose();
			}
		};
	}, [props.pickupId, pickup, loading]);

	useEffect(() => {
		if(error) {
			console.error("Failed to load pickups", error);
			snack.enqueueSnackbar(`Failed to load pickup ${props.pickupId}`, { variant: "error" });
		}
	}, [error]);

	return (
		<RightExpandableSidebarModal
			onClose={props.onClose}
		>
			<RightSidebarModalContext.Consumer>
				{(isExpanded) => {
					return (isExpanded && size !== "small")
						? (
							<AdminPickupDetails
								{...basePickupProps}
								scheduledAt={pickup?.scheduledAt ?? ""}
								startedAt={pickup?.startedAt ?? ""}
								completedAt={pickup?.completedAt ?? ""}
							/>
						)
						: (
							<AdminPickupSummary
								{...basePickupProps}
							/>
						);
				}}
			</RightSidebarModalContext.Consumer>
		</RightExpandableSidebarModal>
	);
};

export const AdminPickupDetailsPage: React.FC = (props) => {
	const snack = useSnackbar();
	const params = useParams();
	const dispatch = useAppDispatch();

	const pickupId = useMemo(() => params["pickupId"] ?? "", [params]);

	const { data, loading, error } = useQuery(AdminGetPickup, {
		variables: { pickupId }
	});

	useEffect(() => {
		if(!params["pickupId"]) {
			dispatch(push("/pickups"));
		}
	}, [params]);

	const pickup = useMemo(() => {
		if(data) {
			return data.GetPickup;
		}

		return null;
	}, [data]);

	useEffect(() => {
		if(error) {
			console.error("Failed to load pickups", error);
			snack.enqueueSnackbar(`Failed to load pickup ${pickupId}`, { variant: "error" });
		}
	}, [error]);

	const basePickupProps: PickupSummaryProps = useMemo(() => {
		return {
			pickupId,
			isLoading: loading,
			residenceName: "",
			residenceCity: "",
			residenceState: "",
			scheduled: pickup?.scheduled ?? false,
			completed: pickup?.completed ?? false,
			started: pickup?.started ?? false,
			marketId: pickup?.market?.id ?? "",
			marketCode: pickup?.market?.code ?? "",
			residences: pickup?.residences.map(r => {
				return {
					id: r.id,
					name: r.name,
					code: r.code
				};
			}) ?? [],
			isCustomerScheduled: pickup?.isCustomerScheduled ?? false,
			pickupScheduledDate: pickup?.scheduledDate && pickup?.scheduledWindowTimezone
				? moment.tz(pickup.scheduledDate, pickup.scheduledWindowTimezone).format("MM/DD/YYYY")
				: "",
			pickupScheduledWindowFrom: pickup?.scheduledWindowFrom ?? 0,
			pickupScheduledWindowFromFormatted: pickup?.scheduledWindowFrom
				? moment().hours(pickup.scheduledWindowFrom).minutes(0).format("h:mm A")
				: "",
			pickupScheduledWindowTo: pickup?.scheduledWindowTo ?? 0,
			pickupScheduledWindowToFormatted: pickup?.scheduledWindowTo
				? moment().hours(pickup.scheduledWindowTo).minutes(0).format("h:mm A")
				: "",
			pickupScheduledWindowTimezone: pickup?.scheduledWindowTimezone ?? "",
			services: (pickup?.services ?? []).map(service => {
				return {
					id: service.id,
					customerFirstName: service.user.firstName ?? "",
					customerLastName: service.user.lastName ?? "",
					customerUnit: service.user.currentResident?.verification?.unit ?? "",
					productCount: service.productCount ?? 0,
					products: (service.products ?? []).map(product => {
						return {
							media: (product.media ?? []).map(media => {
								return {
									fileName: media.fileName ?? "",
									contentUrl: media.contentUrl ?? ""
								};
							}),
							item: {
								id: product?.item?.id ?? "",
								name: product?.item?.name ?? "",
							},
							itemClassification: {
								id: product?.itemClassification?.id ?? "",
								name: product?.itemClassification?.name ?? "",
								code: product?.itemClassification?.code ?? "",
							}
						};
					}),
					selectedEstimate: service.selectedEstimate || null,
					residenceName: service.user.currentResident?.residence?.name ?? ""
				};
			}),
			handleViewMore: () => { }
		};
	}, [pickupId, pickup, loading]);

	return (
		<AdminPickupDetails
			{...basePickupProps}
			scheduledAt={pickup?.scheduledAt ?? ""}
			startedAt={pickup?.startedAt ?? ""}
			completedAt={pickup?.completedAt ?? ""}
		/>
	);
};

export const CreatePickupPage: React.FC = (props) => {
	const { size } = useWindowDimensions();
	const { markets, isLoading: marketsLoading } = useMarkets();

	const [market, setMarket] = useState("");


	return (
		<Box gap="small">
			<Box align="center">
				<Heading margin="none" level="2">Schedule Pickup</Heading>
			</Box>
			<Box align="start">
				<Text weight="bold">Market</Text>
				<Select
					value={market}
					options={markets.map(m => m.code)}
					icon={marketsLoading ? <Spinner /> : undefined}
					onChange={(event) => {
						setMarket(event.target.value);
					}}
				/>
			</Box>
			<Box margin="small" gap="small">
				<Heading margin="none" level="3">Residences</Heading>
				<SelectResidencesStep
					marketCode={market}
					selectedResidences={[]}
					onContinue={() => { }}
				/>
			</Box>
			<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap="small">

				<Box margin="small">
					<Heading margin="none" level="3">Destinations</Heading>
				</Box>
			</Grid>
		</Box>
	);
};

export const UpdatePickupPage: React.FC = (props) => {
	return (
		<Box>
			UPDATE PICKUP
		</Box>
	);
};

interface PickupResidencesProps {
	marketCode: string;
	selectedResidences: ResidenceType[];
	onContinue: (residences: ResidenceType[]) => void;
}

interface ResidenceType {
	id: string;
	name: string;
	address: {
		addressLineOne: string;
		addressLineTwo?: string | null;
		city: string;
		state: string;
		zip: string;
	};
}

export const SelectResidencesStep: React.FC<PickupResidencesProps> = (props) => {
	const [execute, { data, loading }] = useLazyQuery(ListResidencesByMarket);

	const [filters, setFilters] = useState({
		name: "",
		city: "",
		addressLineOne: "",
		zipCode: ""
	});

	const [selected, setSelected] = useState<(string | number)[]>([]);

	const residences = useMemo(() => {
		const results = data?.ListResidences ?? [];

		return results.filter(r => {
			if(filters.name && !r.name.toLowerCase().includes(filters.name.toLowerCase())) {
				return false;
			}

			if(filters.addressLineOne && !r.address.addressLineOne.toLowerCase().includes(filters.addressLineOne.toLowerCase())) {
				return false;
			}

			if(filters.zipCode && !r.address.zip.toLowerCase().includes(filters.zipCode.toLowerCase())) {
				return false;
			}

			if(filters.city && !r.address.city.toLowerCase().includes(filters.city.toLowerCase())) {
				return false;
			}

			return true;
		});
	}, [data, filters]);

	useEffect(() => {
		if(props.marketCode) {
			execute({ variables: { marketCode: props.marketCode } });
			setSelected([]);
		}
	}, [props.marketCode]);

	if(!props.marketCode) {
		return (
			<Box align="center" justify="center">
				<Text>no market selected</Text>
			</Box>
		);
	}

	const columnConfig: ColumnConfig<ResidenceType>[] = [
		{
			header: "Name",
			property: "name",
			primary: true,
			search: true
		},
		{
			header: "Street Address",
			property: "addressLineOne",
			render: (data: any) => data?.address?.addressLineOne,
			sortable: true,
			search: true
		},
		{
			header: "City",
			property: "city",
			render: (data: any) => data?.address?.city,
			sortable: true,
			search: true
		},
		{
			header: "Zip Code",
			property: "zipCode",
			render: (data: any) => data?.address?.zip,
			sortable: true,
			search: true
		}
	];

	function handleSelect(selection: (string | number)[]): void {
		setSelected([...selection]);
	}

	return (
		<Box gap="medium">
			<Box>
				{loading && <LinearProgress />}
				<DataTable
					sortable
					select={selected}
					onSelect={handleSelect}
					onClickRow={"select"}
					paginate={{ step: 10 }}
					columns={columnConfig}
					data={residences}
					onSearch={(search: object) => {

					}}
				// onSearch={(searchTerm: string) => {
				// 	const search = { ...searchTerm as unknown as { [key: string]: string; } };
				// 	/// @ts-expect-error
				// 	setFilters(search);
				// }}
				/>
			</Box>
			<Box align="end" gap="small">
				<Text weight="bold">{selected.length} residence(s) selected</Text>
				<Button
					primary
					color="accent-1"
					label="Continue"
				/>
			</Box>
		</Box>
	);
};

interface UpsertPickupControllerProps {
	pickupId?: string;
	isUpdate: boolean;
}

export const UpsertPickupController: React.FC<UpsertPickupControllerProps> = (props) => {
	if(props.isUpdate) {
		return (
			<UpdatePickupPage />
		);
	}

	return (
		<CreatePickupPage />
	);
};