import { useMemo } from "react";
import { useLocation } from "react-router";
import { push } from "redux-first-history";
import { useAppDispatch, useAppSelector } from "./store";
import { selectIsLoggedIn, selectUser } from "./store/application";

export function useLoginNavigation() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const user = useAppSelector(selectUser);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	const params = useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location.search]);

	function handleRedirect(path: string) {
		if(user) {
			return;
		}

		dispatch(push(`${path}?returnUrl=${params.get("returnUrl") ?? location.pathname + location.search}`));
	}

	return {
		user,
		isLoggedIn,
		loginNavigate: () => handleRedirect("/login"),
		registerNavigate: () => handleRedirect("/onboarding/register")
	};
}