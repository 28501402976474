import React, { useEffect, useState } from "react";
import { history, store, useAppDispatch, useAppSelector } from "../../../app/store";
import { Provider } from 'react-redux';
import { HistoryRouter, Loader, StepProvider } from "../components";
import { ApolloProvider, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GetSelf, gqlClient } from "../../../app/services/request";
import { selectUser, setInstance, setUserLogin } from "../../../app/store/application";
import { BeginOneTimeCodeLogin, GetFrontendConfig } from "../../../app/services/request/gql";
import { AlertProvider } from "./alert";
import { setHost, setStripePublicKey } from "../../../app/store/config";
import { ErrorBoundary } from "react-error-boundary";
import { Error500 } from "../pages/500";
import { useGoogleTagManager, useQueryParams } from "../hooks";
import { AuthService } from "../../../app/services/auth";
import { push } from "redux-first-history";
import { useLocation } from "react-router";
import { reportUser } from "../../../app/reporting";
import { isHauler } from "../../partner";
import { ContactType } from "../../../__generated__/graphql";
import { isPartnersSite } from "../../../helpers";

export const ApplicationProvider: React.FC<{ children: React.ReactNode; }> = (props) => {
	return (
		<ErrorBoundary
			fallback={<Error500 />}
			onError={(error, info) => {
				console.error("Caught an error in the error boundary", error);
			}}
		>
			<Provider store={store}>
				<AlertProvider>
					<ApolloProvider client={gqlClient}>
						<HistoryRouter history={history}>
							<AuthProvider>
								<ConfigProvider>
									<StepProvider>
										{props.children}
									</StepProvider>
								</ConfigProvider>
							</AuthProvider>
						</HistoryRouter>
					</ApolloProvider>
				</AlertProvider>
			</Provider>
		</ErrorBoundary>
	);
};

export const ConfigProvider: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const isGTMReady = useGoogleTagManager();
	const { data, loading, error } = useQuery(GetFrontendConfig);

	useEffect(() => {
		const { host, applications } = data?.GetFrontendConfig ?? {};

		if(host) {
			dispatch(setHost(host));
		}

		if(applications?.stripe.publicKey) {
			dispatch(setStripePublicKey(applications.stripe.publicKey));
		}
	}, [data]);

	return (
		<Loader visible={loading || !isGTMReady}>
			{props.children}
		</Loader>
	);
};

export const AuthProvider: React.FC = (props) => {
	const gtm = useGoogleTagManager();
	const dispatch = useAppDispatch();
	const query = useQueryParams();
	const [isLoading, setIsLoading] = useState(true);
	const user = useAppSelector(selectUser);
	const location = useLocation();
	const [GetSelfQuery, { data }] = useLazyQuery(GetSelf);
	const [beginOneTimeCodeLoginMutation] = useMutation(BeginOneTimeCodeLogin);
	const [loginMethod, setLoginMethod] = useState<"email" | "phone" | "refresh" | "token" | null>(null);

	useEffect(() => {
		if(user) {
			const email = user.contacts.find(c => c.type === ContactType.Email)?.value;
			const fullName = (user.firstName && user.lastName)
				? `${user.firstName} ${user.lastName}`
				: "";

			reportUser(user.id, email, fullName);
			gtm.push("login", { userId: user.id, method: loginMethod ?? "" });
		}

		if(user?.config) {
			if(user.config.defaultPartner) {
				if(isPartnersSite() && isHauler(user.config.defaultPartner)) {
					dispatch(setInstance("HAULER"));
				}
			}
		}
	}, [user]);

	function handleUserLoggedIn(): void {
		setIsLoading(false);
	}

	function handleOneTimeCodeLogin(tokenId: string): void {
		beginOneTimeCodeLoginMutation({ variables: { tokenId } }).then(res => {
			const accessToken = res.data?.BeginOneTimeCodeLogin.access_token;
			const refreshToken = res.data?.BeginOneTimeCodeLogin.refresh_token;

			if(!accessToken || !refreshToken) {
				throw new Error(`could not get access token from login`);
			}

			AuthService.setAccessToken(accessToken);
			AuthService.setRefreshToken(refreshToken);
			setLoginMethod("token");

			return GetSelfQuery();
		}).catch(err => {
			console.error("Failed during one time code login", err);
		}).finally(() => {
			setIsLoading(false);
			query.delete("tokenId");
			dispatch(push(`${location.pathname}?${query.toString()}`));
		});
	}

	useEffect(() => {
		if(data?.GetSelf) {
			dispatch(setUserLogin(data.GetSelf));
		}
	}, [data]);

	useEffect(() => {
		if(user) {
			handleUserLoggedIn();
			return;
		}

		if(query.get("tokenId")) {
			handleOneTimeCodeLogin(query.get("tokenId") ?? "");
			return;
		}

		GetSelfQuery().catch(err => {
			console.error(err);
		}).finally(() => {
			setIsLoading(false);
		});
	}, []);

	return (
		<Loader visible={isLoading}>
			{props.children}
		</Loader>
	);
};