import { useLazyQuery, useMutation } from "@apollo/client";
import { Anchor, Box, Button, CheckBox, Form, FormField, Grid, Heading, Image, MaskedInput, Page, PageContent, Stack, Text, TextInput } from "grommet";
import { CircleAlert } from "grommet-icons";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { push } from "redux-first-history";
import { BeginCheckoutSession, BeginOnboarding, ConfirmOnboarding, GetBuildingByCode } from "../../../app/services/request/gql";
import { UtilService } from "../../../app/services/util";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectUser } from "../../../app/store/application";
import { getNumberFormValidations, getStandardFormValidations } from "../../../helpers";
import { Bold, Loader, LoadingButton, Modal, useQueryParams } from "../../common";
import regoBlackLogo from "../../../img/rego_black_logo.png";

interface CapturePhoneModalProps {
	phoneNumber?: string;
	buildingCode: string;
	onClose: () => void;
}

export const CapturePhoneModal: React.FC<CapturePhoneModalProps> = (props) => {
	const [code, setCode] = useState("");
	const [unit, setUnit] = useState("");
	const [phone, setPhone] = useState(props.phoneNumber ? UtilService.formatPhoneToDisplay(props.phoneNumber) : "");
	const [errorMessage, setErrorMessage] = useState("");
	const [canSendAnotherCode, setCanSendAnotherCode] = useState(false);
	const [wasCodeSent, setWasCodeSent] = useState(false);
	const [noUnit, setNoUnit] = useState(false);

	const [beginOnboardingMutation, { loading: beginOnboardingLoading, error: beginOnboardingError }] = useMutation(BeginOnboarding);
	const [confirmOnboardingMutation, { loading: confirmOnboardingLoading }] = useMutation(ConfirmOnboarding);

	const isLoading = useMemo(() => {
		return beginOnboardingLoading || confirmOnboardingLoading;
	}, [beginOnboardingLoading, confirmOnboardingLoading]);

	function handleSendCode(): void {
		if(!phone) return;
		setCode("");
		beginOnboardingMutation({ variables: { phoneNumber: UtilService.formatPhoneToNumber(phone) } }).then((res) => {
			if(res?.data?.BeginOnboarding) {
				setWasCodeSent(true);
				setErrorMessage("");
				setCanSendAnotherCode(false);
			}
			else {
				setWasCodeSent(false);
				setErrorMessage("We ran into an issue sending your confirmation code.");
				setCanSendAnotherCode(true);
			}
		}).catch(err => {
			console.error("Failed to send code", err);
			setWasCodeSent(false);
			setErrorMessage("We ran into an issue sending your confirmation code.");
			setCanSendAnotherCode(true);

			throw err;
		});
	}

	function handleVerifyCode(): void {
		if(!code) return;

		confirmOnboardingMutation({
			variables: {
				code,
				unit,
				buildingCode: props.buildingCode,
				phoneNumber: UtilService.formatPhoneToNumber(phone)
			}
		}).then((res) => {
			const data = res.data?.ConfirmOnboarding;
			const url = `/dashboard/schedule?sid=${data?.sessionId}&phone=${data?.phoneNumber}&tokenId=${data?.tokenId}`;
			window.location.assign(url);
		}).catch(err => {
			console.error("Failed to confirm code", err);
			setCanSendAnotherCode(true);
			setErrorMessage("That code doesn't look right. Please try again.");
		});
	}

	function handleSubmit(): void {
		if(!phone) return;

		if(wasCodeSent) {
			if(!code) return;

			handleVerifyCode();
		} else {
			handleSendCode();
		}
	}

	return (
		<Modal onCloseAll={props.onClose}>
			<Form
				validate="submit"
				value={{ phone, code, unit }}
				onSubmit={handleSubmit}
				onChange={(changes) => {
					setCode(changes.code);
					setUnit(changes.unit);
					setPhone(changes.phone);
				}}
			>
				<Box gap="medium" pad="medium">
					<Text weight="bold">Enter your phone number to get started</Text>
					<Box gap="small">
						<FormField
							name="phone"
							label="Phone Number"
							validate={[...getStandardFormValidations()]}
						>
							<MaskedInput
								name="phone"
								inputMode={"tel"}
								disabled={wasCodeSent || !!props.phoneNumber}
								readOnly={wasCodeSent || !!props.phoneNumber}
								mask={[
									{ fixed: '1 (' },
									{
										length: 3,
										regexp: /^[0-9]{1,3}$/,
										placeholder: 'xxx',
									},
									{ fixed: ')' },
									{ fixed: ' ' },
									{
										length: 3,
										regexp: /^[0-9]{1,3}$/,
										placeholder: 'xxx',
									},
									{ fixed: '-' },
									{
										length: 4,
										regexp: /^[0-9]{1,4}$/,
										placeholder: 'xxxx',
									},
								]}
							/>
						</FormField>
						{!noUnit && (
							<FormField
								label="Apartment / Unit"
								name="unit"
								info="Just the number / letter (ex. 1F for Apt 1F)"
								validate={[...getStandardFormValidations()]}
							>
								<TextInput
									name="unit"
								/>
							</FormField>
						)}
						<FormField
							style={{ border: undefined }}
						>
							<CheckBox
								checked={noUnit}
								onChange={(e) => setNoUnit(e.target.checked)}
								label={<Text weight="bold">I don't have a unit / apartment number</Text>}
							/>
						</FormField>
						{wasCodeSent && (
							<Box gap="small">
								<FormField
									name="code"
									label="Code"
									validate={[
										...getNumberFormValidations(),
										(value: any) => {
											if(!/^[0-9]{6}$/.test(value)) {
												return {
													status: "error",
													message: "Code must be 6 digits"
												};
											}
										}
									]}
								>
									<MaskedInput
										name="code"
										inputMode={"tel"}
										style={{ letterSpacing: "6px" }}
										mask={[
											{
												length: 1,
												regexp: /^[0-9]{1}$/,
												placeholder: '_',
											},
											{
												length: 1,
												regexp: /^[0-9]{1}$/,
												placeholder: '_',
											},
											{
												length: 1,
												regexp: /^[0-9]{1}$/,
												placeholder: '_',
											},
											{
												length: 1,
												regexp: /^[0-9]{1}$/,
												placeholder: '_',
											},
											{
												length: 1,
												regexp: /^[0-9]{1}$/,
												placeholder: '_',
											},
											{
												length: 1,
												regexp: /^[0-9]{1}$/,
												placeholder: '_',
											}
										]}
									/>
								</FormField>
								<Heading margin="none" level="5">
									We just sent a 6 digit code to {phone}. Please enter it above.
								</Heading>
							</Box>
						)}
						{errorMessage && (
							<Text color="status-error">
								{errorMessage}
							</Text>
						)}
						{canSendAnotherCode && (
							<Anchor
								label="Need another code?"
								onClick={handleSendCode}
							/>
						)}
					</Box>
					<Box flex align="end" justify="end">
						<LoadingButton
							isLoading={isLoading}
							primary
							color="accent-1"
							label="Submit"
							type="submit"
						/>
					</Box>
				</Box>
			</Form>
		</Modal>
	);
};

export const BuildingLandingPage: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const query = useQueryParams();
	const user = useAppSelector(selectUser);
	const [buildingCode, setBuildingCode] = useState("");
	const [isCapturingPhone, setIsCapturingPhone] = useState(false);
	const [fetchResidenceQuery, { data, loading }] = useLazyQuery(GetBuildingByCode);
	const [beginCheckoutSessionMutation, { loading: isLoadingSession }] = useMutation(BeginCheckoutSession);

	function handleContinueToSchedule() {
		if(user) {
			beginCheckoutSessionMutation().then((res) => {
				dispatch(push(`/dashboard/schedule?sid=${res.data?.BeginCheckoutSession?.id}&phone=${res.data?.BeginCheckoutSession?.phoneNumber}`));
			}).catch(err => {
				snack.enqueueSnackbar("We ran into an issue getting your pickup started", { variant: "error" });
			});

			return;
		}

		setIsCapturingPhone(true);
	}

	useEffect(() => {
		const code = query.get("code");
		if(!code) {
			dispatch(push("/help/building-search"));
			return;
		}
		else {
			setBuildingCode(code);
		}
	}, []);

	useEffect(() => {
		if(buildingCode) {
			fetchResidenceQuery({
				variables: {
					code: buildingCode
				}
			}).catch(err => {
				console.log(`Could not find building with code [${buildingCode}]`, err);
			});
		}
	}, [buildingCode]);

	return (
		<Page kind="narrow">
			<PageContent>
				<Loader visible={loading}>
					{isCapturingPhone && (
						<CapturePhoneModal
							buildingCode={buildingCode}
							phoneNumber={query.get("phone") || ""}
							onClose={() => setIsCapturingPhone(false)}
						/>
					)}
					<Box gap="large">
						<BuildingDetailsCard
							buildingName={data?.GetBuildingByCode?.name || ""}
							buildingLogoUrl={data?.GetBuildingByCode?.customizations?.logoUrl || ""}
							hideBuildingLogo={data?.GetBuildingByCode?.customizations?.hideLogo || false}
							customLandingPageMessage={data?.GetBuildingByCode?.customizations?.customLandingPageMessage || ""}
						/>
						<HowItWorksCard
							isLoading={isLoadingSession}
							handleContinue={handleContinueToSchedule}
							buildingName={data?.GetBuildingByCode?.name || ""}
						/>
					</Box>
				</Loader>
			</PageContent>
		</Page>
	);
};

interface BuildingDetailsCardProps {
	buildingName: string;
	buildingLogoUrl: string;
	hideBuildingLogo?: boolean;
	customLandingPageMessage?: string;
}

export const BuildingDetailsCard: React.FC<BuildingDetailsCardProps> = (props) => {

	return (
		<Box gap="medium">
			<Box flex>
				<Box align="center">
					<Box justify="center" align="center" width="small" flex>
						{props.hideBuildingLogo
							? (
								<Image
									height="100%"
									width="100%"
									src={regoBlackLogo}
								/>
							)
							: (
								<Image
									height="100%"
									width="100%"
									src={props.buildingLogoUrl}
								/>
							)}

					</Box>
				</Box>
				<Box margin="medium" gap="small" flex justify="end">
					{!!props.customLandingPageMessage
						? (
							<Text size="large">
								{props.customLandingPageMessage}
							</Text>
						)
						: (
							<Text size="large">
								{props.buildingName} has partnered with Rego to help residents {(<Bold size="large">donate</Bold>)} or <Bold size="large">dispose</Bold> of used furniture more <Bold size="large">easily</Bold> and <Bold size="large">affordably</Bold>.
							</Text>
						)
					}
				</Box>
			</Box>
			{/* <Box align="center">
				<LoadingButton
					isLoading={props.isLoading}
					primary
					color="accent-1"
					label="Get Started"
					onClick={props.handleContinue}
				/>
			</Box> */}
		</Box>
	);
};

interface HowItWorksCardProps {
	buildingName: string;
	isLoading: boolean;
	handleContinue(): void;
}

export const HowItWorksCard: React.FC<HowItWorksCardProps> = (props) => {
	return (
		<Fragment>
			<Box margin="small" gap="medium">
				<Box align="center">
					<Heading margin="none" level="2">How it works?</Heading>
				</Box>
				<HowItWorksItem
					index={1}
					description="Upload your unwanted items."
				/>
				<HowItWorksItem
					index={2}
					description="Receive a pickup quote."
				/>
				<HowItWorksItem
					index={3}
					description="Schedule your pickup date."
				/>
				<HowItWorksItem
					index={4}
					description="Let Rego do the rest."
				/>
			</Box>
			<Box align="center" gap="medium" margin={{ top: "medium" }}>
				<LoadingButton
					isLoading={props.isLoading}
					primary
					color="accent-1"
					label="Get Started"
					size="large"
					onClick={props.handleContinue}
				/>
				<Button
					primary
					color="grey"
					label="Learn More"
					size="small"
					onClick={() => {
						window.open(`https://liverego.com/residents`, "_blank");
					}}
				/>
			</Box>
		</Fragment>
	);
};

interface HowItWorksItemProps {
	index: number;
	description: string;
}

export const HowItWorksItem: React.FC<HowItWorksItemProps> = (props) => {
	return (
		<Box gap="small">
			<Grid columns={["auto", "flex"]} gap="small">
				<Box align="center" justify="center">
					<Stack>
						<CircleAlert color="plain" size="large" />
						<Box align="center" justify="center" fill gap="small">
							<Heading color="white" margin="none" level="2">{props.index}</Heading>
						</Box>
					</Stack>
				</Box>
				<Box justify="center">
					<Text>
						{props.description}
					</Text>
				</Box>
			</Grid>
		</Box>
	);
};