import { Route, RouteProps, Routes } from "react-router";
import { CommonRoutes } from "../../common";
import { HaulerController } from "./controller";

export const HaulerRoutes: React.ReactElement<RouteProps>[] = [
	//...CommonRoutes,
	<Route path="dashboard" element={<HaulerController />} />
];

export const HaulerRouter: React.FC = (props) => {
	return (
		<Routes>
			{HaulerRoutes}
		</Routes>
	);
};